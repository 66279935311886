import { useEffect, useState } from 'react';
import { Grid } from "semantic-ui-react";
import DatePickerForm from "../common/DatePickerForm";
import InputForm from "../common/InputForm";
import SwitchComponent from "../common/SwitchComponent";
import "./Document.css";
import DiagnosticModal from "./modals/DiagnosticModal";

export default function DocumentMedicalOrder({
	control,
	errors,
	dateMedicalOrder,
	register,
	loadingInput,
	idSocialWork,
	infoSocialWork,
	setDataToast,
	setDateMedicalOrder,
	onBlurInputSearch,
	isView,
	numSiniester,
	numAutorization,
	coinsuranceAmout,
	setStateOfCheckbox,
	stateOfCheckbox,
	isEdit,
	diagnosticModal,
	setDiagnosticModal,
}) {
	const [openDiagnosticModal, setOpenDiagnosticModal] = useState(false);
	const [hasIntegration, setHasIntegration] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 680);

	useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 680);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

	useEffect(() => {
		setHasIntegration(infoSocialWork.hasIntegration);
	}, [infoSocialWork.hasIntegration])

	return (
		<Grid.Row>
			<Grid.Column id='column-medical-order-one'>
				<InputForm
					disabled={isView}
					register={register({ required: true })}
					isRequired={true}
					type={'text'}
					label={'Matr. Prescriptor'}
					name={'enrollment'}
					control={control}
					error={errors}
					loading={loadingInput.enrollment}
					onBlur={(event) => onBlurInputSearch(event)}
					isMobile={isMobile}
				/>
				<InputForm
					disabled={isView}
					register={register({ required: true })}
					isRequired={true}
					type={'text'}
					label={'Nombre'}
					name={'nameMedicalOrder'}
					control={control}
					error={errors}
					isMobile={isMobile}
				/>
				<InputForm
					disabled={isView}
					register={register({ required: true })}
					isRequired={true}
					type={'text'}
					label={'Apellido'}
					name={'surnameMedicalOrder'}
					control={control}
					error={errors}
					isMobile={isMobile}
				/>
			</Grid.Column>
			<Grid.Column id='column-medical-order-one'>
				<DatePickerForm
					disabled={isView}
					label='Fecha'
					name='date'
					dateValue={dateMedicalOrder}
					handleDateChange={(date) => {
						if (!isNaN(date)) {
							setDateMedicalOrder(date)
						}
					}}
					minDateMessage='Fecha ingresada es menor a la requerida'
					isMobile={isMobile}
				/>
				<InputForm
					disabled={isView || (isEdit && hasIntegration)}
					type='text'
					label='Diagnóstico'
					name='diagnosis'
					value={diagnosticModal.data?.descripcion}
					placeholder="Diagnóstico"
					error={errors}
					control={control}
					isMobile={isMobile}
				/>
				{numSiniester &&
					(<InputForm
						disabled={isView}
						isRequired={false}
						type={'number'}
						label={'Nro. Siniestro'}
						name={'siniester'}
						control={control}
						error={errors}
						isMobile={isMobile}
					/>)}
				<InputForm
					disabled={(isView || !numAutorization)}
					register={register({ required: true })}
					isRequired={true}
					type={'number'}
					label={'Nro. Autorización'}
					name={'autorNum'}
					control={control}
					error={errors}
					isMobile={isMobile}
				>
					{(!isView && !isEdit) && stateOfCheckbox.hasIntegration &&
						<div className={`${isMobile ? "iconsIntegrationMobile" : "iconsIntegration"} cm-charge`}>
							<SwitchComponent
								label="Carga Manual"
								abbr="CM"
								name="manualCharge"
								onChange={(e) => setStateOfCheckbox(e)}
								stateOfChecked={stateOfCheckbox.manualCharge} />
						</div>}
				</InputForm>
				<InputForm
					disabled={(isView || !coinsuranceAmout)}
					isRequired={false}
					type={'number'}
					label={'Coseguro'}
					name={'coseguro'}
					control={control}
					error={errors}
					isMobile={isMobile}
				/>
				{diagnosticModal.show && openDiagnosticModal &&
					<DiagnosticModal
						idSocialWork={idSocialWork}
						setDiagnosticModal={setDiagnosticModal}
						diagnosticModal={diagnosticModal}
						openDiagnosticModal={openDiagnosticModal}
						setOpenDiagnosticModal={setOpenDiagnosticModal}
						setDataToast={setDataToast} />}
			</Grid.Column>
		</Grid.Row>
	);
}
