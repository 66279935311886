import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Dropdown, Menu } from "semantic-ui-react";
import { setFilters } from "../../../redux/dashboard";
import "./AuthenticatedHeader.css";

import { Badge, Typography } from "@material-ui/core";
import { StatusCode, TypeRole } from "../../../enums";
import {
	setFilter,
	setFiltroData,
	setFiltroFecha,
} from '../../../redux/socialWork';
import { GetNewsForUsers } from "../../../services/services/News";
import MenuButton from "../../sideBar/menuButton/MenuButton";
import NotificationMenu from './NotificationMenu';
import api from '../../../api';

export default function AuthenticatedHeader({setViewSideBar, stateOfSideBar, setDataToast}) {
	const history = useHistory();
	const disparador = useDispatch();
	const [openMenuNews, setOpenMenuNews] = React.useState(false);
	const [anchorToModalNotification, setAnchorToModalNotification] = React.useState(null);
	const [newsList, setNewsList] = React.useState([]);
	const [newsQuantity, setNewsQuantity] = React.useState(0);
	const [roleLogged, setRoleLogged] = React.useState(()=>{
		return JSON.parse(localStorage.getItem("TypeRole"));
	});
	const [dataJWT, setDataJWT] = React.useState({});
	const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 912);

	useEffect(() => {
		HandleGetDataJWT();
		(roleLogged === TypeRole.Professional && LoadDataForTable());
	}, []);

	useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 912) {
        setViewSideBar(false);
      }
    };
    window.addEventListener("resize", handleResize);

    // Ejecuta la función una vez al montar el componente
    handleResize();

    // Limpia el listener al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);
	
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 912);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

	async function HandleGetDataJWT(){
		try{
		  const {data}  = await api.getJwtData();
		  setDataJWT(data);
		}catch(err){
		  console.log(err);
		}
	  }

	//#region NOVEDADES
	async function LoadDataForTable(){
        try{
            const {data, status} = await GetNewsForUsers(false);
            if (status === StatusCode.Ok){
				setNewsList(data.results);
				setNewsQuantity(data.results.length);
            }
        }catch(err){
			MessageNotification(err.message ?? err.response.data.errores, "error");
        }
    }
	const HandleOpenMenuNews = (event) => {
		setAnchorToModalNotification(event.currentTarget);
		setOpenMenuNews(true);
	};
	//#endregion

	function handleClickLogin() {
		window.localStorage.removeItem('expirationTokenLog');
		window.localStorage.removeItem('tokenLog');
		//setear todos los filtros en null
		disparador(
			setFilters({
				socialWork: undefined,
				auth: undefined,
				patient: undefined,
				dateFrom: null,
				dateTo: null,
				page: 1,
				quantity: 20,
			})
		);
		disparador(setFiltroFecha(null));
		disparador(setFiltroData(null));
		disparador(setFilter(null));

		history.push('/login');
	};
	function MessageNotification(message, type){
		setDataToast({
			active: true,
			message,
			type
		});
	};
	function handleClickMyAccount() {
		history.push('/perfil');
	};
	const DropdownUser = () => {
		return (
			<>
			<Dropdown style={{ marginTop: 3 }} trigger={triggerDropdownUser}>
				<Dropdown.Menu>
					<Dropdown.Menu scrolling style={{ marginLeft: 50 }}>
						<Dropdown.Item
							onClick={handleClickMyAccount}
							key={optionsMyAccount.text}
							{...optionsMyAccount}
						/>
						<Dropdown.Item
							onClick={handleClickLogin}
							key={optionsDropdownUser.text}
							{...optionsDropdownUser}
						/>
					</Dropdown.Menu>
				</Dropdown.Menu>
			</Dropdown>
			</>
		);
	};
	const optionsDropdownUser = {
		key: 'log-out',
		text: 'Salir',
		icon: 'sign out',
	};
	const optionsMyAccount = {
		key: 'my-account',
		text: 'Perfil',
		icon: 'user circle',
	};
	const triggerDropdownUser = (
		<div style={{ color: 'black', display: "inline-flex", alignItems: "center"}}>
			<AccountCircleOutlinedIcon style={{fontSize: "1.5em", marginLeft: 5, marginRight: 5}}/>
			<Typography style={{fontSize: ".85em"}}>{dataJWT?.nombreApellido}</Typography>
			<KeyboardArrowDownOutlinedIcon />
		</div>
	);

	return (
		<Container>
			<Menu id="menuHeader"  fixed='top' inverted className='background-header-authenticated'>
				<MenuButton openMenu={() => { setViewSideBar(true); }} stateOfSideBar={stateOfSideBar} label=""/>
				<Menu.Item
					position='right'
					style={{
						display: 'flex',
						flexDirection: isMobile ? 'row-reverse' : 'unset'
					}}
				>
					{roleLogged === TypeRole.Professional &&
					<Badge badgeContent={newsQuantity} color="secondary" onClick={HandleOpenMenuNews} className='btn-notification'>
						<NotificationsActiveOutlinedIcon style={{fontSize: "2em"}} className={`newsNotificationIcon ${newsQuantity > 0 ? "newsNotificationIcon--animationShake" : ""}`}/>
					</Badge>}
					{ !isMobile && <span style={{ marginRight: 40, color: 'black' }}></span> }
					<DropdownUser />
				</Menu.Item>
			</Menu>
			{openMenuNews &&
			<NotificationMenu
			newsList={newsList}
			anchorToModalNotification={anchorToModalNotification}
			setAnchorToModalNotification={setAnchorToModalNotification}
			setNewsQuantity={setNewsQuantity}
			ReloadData={LoadDataForTable}
			MessageNotification={MessageNotification}
			/>}
		</Container>
	);
}
