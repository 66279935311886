export const Module = {
  Patient: 1,
  Periods: 2,
  Documents: 3,
  Configurations: 4,
  Config_Module: 5,
  Config_Rol: 6,
  Config_User: 7,
  Config_Account: 8,
  Holidays: 9,
};
export const StatusOfDocument = {
  Verified: 1,
  NotVerified: 2,
  Envoiced: 3,
  NotEnvoiced: 4,
  Pending: 5
}
export const Options = {
	isView: 1,
	isCreate: 2,
	isEdit: 3,
	isDelete: 4,
	isExport: 5,
};
export const TypeRole = {
	Admin: 'Admin',
	Professional: 'Profesional',
	Colegio: 'Colegio',
	SuperAdmin: 'SuperAdmin',
	ObraSocial: 'Obra Social',
};
export const TypeRoleNum = {
	Admin: 2,
	Professional: 3,
	Colegio: 4,
	SuperAdmin: 1,
	ObraSocial: 5,
  ProfesionalGestion: 6
};
export const Genders = [
	{value: 1, label: "M"},
	{value: 2, label: "F"}
];
export const ReceiptForSelect = [
	{value: 1, label: "Profesional"},
	{value: 2, label: "Obra Social"},
	{value: 3, label: "Libre"}
];
export const SHOW_BUTTON_BOX = {
    edit: 'edit',
    delete: 'delete',
    view: 'view',
    amount: 'amount',
    practice: 'practice',
    addSession: 'addSession',
    presentDocument: 'presentDocument',
    showCommentDevolution: 'showCommentDevolution',
    debitManually: 'debitManually',
    showModalDiagnostic: 'showModalDiagnostic',
    viewPhotos: 'viewPhotos',
    rebill: "rebill",
    reactivate: 'reactivate',
    instructive: 'instructive',
    massive: 'massive',
    viewAmountHistorial: 'viewAmountHistorial',
    viewPresentedSocialWorks: 'viewPresentedSocialWorks',
    viewStateOfDocuments: 'viewStateOfDocuments',
    print: "print",
    printExcel: "printExcel",
    manualDebit: "manualDebit",
    generateTxtSW: "generateTxtSW",
    socialWorkAccountPlan: "socialWorkAccountPlan"
};
export const ApiCallStatus = {
	REJECTED: 'rejected',
	FULFILLED: 'fulfilled'
};
export const SocialWorkEnum = {
  swissMedical: 73,
  ospe: 42,
  apross: 3,
  sancorSalud: 68,
  avalian: 9,
  amicos: 10,
  prevencionSalud: 66,
  omint: 48,
  nobis: 38
}
export const StatusCode = {
  Ok: 200,
  NotContent: 204
}
export const ValidationObjRegex = {
  emailValidation: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
}
export const ConfigAccountParamEnum = {
    MontoCuotaSocietaria: 1,
    MontoMantenimientoMatricula: 2,
    PorcentajeDebitoColegio: 3,
    PorcentajeDebitoRegional: 4,
    MotivoDebitoAutomatico: 5,
    MontoGastoNoSocio: 6,
    MontoSistemaDocumental: 7,
    Edad0a18: 8,
    Edad19a35: 9,
    Edad36a45: 10,
    Edad46a55: 11,
    Edad56a65: 12,
    EdadMas65: 13,
    RecAntecEnfermedadesCatastrof: 14,
    EdadMaximaCoberturaHijoAdulto: 15,
    RetencionGananciaMinima: 16,
    RetencionMontoExcluido: 17
}
export const GestionUrl = [
    "/descuentos/lista",
    "/acreditaciones/lista",
    "/cuotas/lista",
    "/facturas/lista",
    "/recibos/lista",
    "/configuraciones/regional",
    "/configuraciones/retenciones",
    "/configuraciones/bancos",
    "/configuraciones/usuarios/:id/resumen-de-cuenta"
];
export const MonthOfYear = [
  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];
export const StatusDocuments = [
	{value: 1, label: "Presentado"},
	{value: 2, label: "Pendiente de Presentar"}
];
export const StateOfLiquidation = {
  generada: 2,
  borrador: 1
}
export const StateBills = [
  {value: 1, label: "Liquidadas"},
  {value: 2, label: "Pendientes"}
]
export const StateRebilled = [
  {value: 1, label: "Facturas"},
  {value: 2, label: "Refacturas"},
]

export const RadioDataSourceEnum = {
  TodasLasObrasSociales: 1,
  ConConvenio: 2,
  SinConvenioDirecto: 3,
  SoloUnaObraSocial: 4,
}
export const RadioDataSource = {
  [RadioDataSourceEnum.TodasLasObrasSociales]: "Todas las Obras Sociales",
  [RadioDataSourceEnum.ConConvenio]: "Con convenio",
  [RadioDataSourceEnum.SinConvenioDirecto]: "Sin convenio directo",
  [RadioDataSourceEnum.SoloUnaObraSocial]: "Sólo una Obra Social",
}

export const Entities = [
  {value: 1, label: "Profesionales"},
  {value: 2, label: "Obra Social"},
  {value: 3, label: "Libre"},
]

export const TiposIntegracionEnum = {
  Propio: 1,
  Traditum: 2,
}
export const TiposIntegracion = [
  {value: 1, label: "Propio"},
  {value: 2, label: "Traditum"},
]

export const auditoriaSocialWork = [
  { value: 73, label: "Swiss Medical" },
  { value: 42, label: "Ospe" },
  { value: 3, label: "Apross" },
  { value: 68, label: "Sancor Salud" },
  { value: 9, label: "Avalian" },
  { value: 66, label: "Prevencion Salud" },
];
export const statusTransaccionExitosa = [
  { value: 0, label: "Fallidas" },
  { value: 1, label: "Exitosas" },
];

export const StatusTipoTransaccion = [
  { value: 1, label: "Login" },
  { value: 2, label: "Elegibilidad" },
  { value: 3, label: "Autorización" },
  { value: 4, label: "Cancelación" },
  { value: 5, label: "Preautorización" },
];