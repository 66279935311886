import { Card, CardContent, Grid, Typography, makeStyles, IconButton, Tooltip } from '@material-ui/core';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import React from 'react';
import { Button, Dimmer, Icon, Loader } from 'semantic-ui-react';
import { IConfigInsurance, IConfigParams, IConfigParamsToSend } from '../../api/Interfaces/managment/IConfigurationParamsRegional';
import { ConfigAccountParamEnum, StatusCode } from '../../enums';
import { IConfigurationParamsRegional } from '../../services/interfaces/Managment/IConfigurationParamsRegional';
import { AddConfigParams, EditConfigParams, GetConfigParams } from '../../services/services/ConfigurationRegional';
import { _DEFAULT_CONFIG_PARAMS } from './defaultValues';
import ConfigParamRegional from './panelSection/ConfigParamRegional';
import { useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import api from '../../api';
import GeneralModalDelete from '../utils/GeneralModalDelete';
import ModalInsurance from './modals/Insurance/ModalInsurance';
import { FormatNumber } from '../utils/FormatInputs';

const styles = makeStyles(()=>({
    textStyles: {
        fontSize: 27,
        textAlign: "left", 
        lineHeight: "1.03"
    }
}));

const defaulValuesInsurance = {
    id: 0,
    nombre: "",
    importe: 0
}
const defaulModalInsurance = {
    isOpen: false,
    typeModal: ""
}

const ConfigurationParamsRegional: React.FC<IConfigurationParamsRegional> = ({setDataToast})=>{
    const hasManagment = useSelector<any>(({managment}) => managment.hasManagment);
    const customStyles = styles();
    const [configParams, setConfigParams] = React.useState<IConfigParams>(_DEFAULT_CONFIG_PARAMS);
    const [applyDefaultConfig, setApplyDefaultConfig] = React.useState(false);
    const [loaderActive, setLoaderActive] = React.useState(false);
    const [objErrorNotification, setObjErrorNotification] = React.useState({});
    //Seguro
    const [insuranceList, setInsuranceList] = React.useState<IConfigInsurance[]>([]);
    const [openModalInsurance, setOpenModalInsurance] = React.useState(defaulModalInsurance);
    const [valuesInsurance, setValuesInsurance] = React.useState<IConfigInsurance>(defaulValuesInsurance);

    React.useEffect(() => {
        if (hasManagment) {
            GetConfigParamList();
            GetInsurance();
        };
    }, []);

    async function GetInsurance() {
        try {
            setLoaderActive(true);
            const {data, status} = await api.GetSeguros();

            if (status === StatusCode.Ok){
                setInsuranceList(data);
            }
        } catch (err: any) {
            MessageNotification(err?.response?.data?.errores ?? "Error al obtener los seguros", "error");
        } finally {
            setLoaderActive(false);
        }
    }
 
    async function GetConfigParamList(){
        try{
            setLoaderActive(true);
            const {data, status} = await GetConfigParams();
            if (status === StatusCode.Ok){
                if (Object.values(data.results).some(param => param === null)){
                    setApplyDefaultConfig(true);
                }else{
                    setConfigParams(data.results);
                }
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores,"error")
        }finally{
            setLoaderActive(false);
        }
    }

    //#region CREAR / EDITAR Parametros
    function HandleSubmit(){
        if (ValidateInputs()){
            if (applyDefaultConfig){
                AddConfigParamsToRegional();
            }else{
                EditConfigParamsToRegional();
            }
        }
    }
    async function AddConfigParamsToRegional(){
        try{
            setLoaderActive(true);
            const configParamToSend: IConfigParamsToSend = {
                montoCuotaSocietaria: {
                    Key: ConfigAccountParamEnum.MontoCuotaSocietaria,
                    Value: configParams.montoCuotaSocietaria
                },
                montoMantenimientoMatricula:{
                    Key: ConfigAccountParamEnum.MontoMantenimientoMatricula,
                    Value: configParams.montoMantenimientoMatricula
                },
                montoSistemaDocumental:{
                    Key: ConfigAccountParamEnum.MontoSistemaDocumental,
                    Value: configParams.montoSistemaDocumental
                },
                porcentajeDebitoColegio:{
                    Key: ConfigAccountParamEnum.PorcentajeDebitoColegio,
                    Value: configParams.porcentajeDebitoColegio
                },
                porcentajeDebitoRegional:{
                    Key: ConfigAccountParamEnum.PorcentajeDebitoRegional,
                    Value: configParams.porcentajeDebitoRegional
                },
                montoGastoNoSocio:{
                    Key: ConfigAccountParamEnum.MontoGastoNoSocio,
                    Value: configParams.montoGastoNoSocio
                },
                motivoDebitoAutomatico:{
                    Key: ConfigAccountParamEnum.MotivoDebitoAutomatico,
                    Value: configParams.motivoDebitoAutomatico
                },
                edad0a18:{
                    Key: ConfigAccountParamEnum.Edad0a18,
                    Value: configParams.edad0a18
                },
                edad19a35:{
                    Key: ConfigAccountParamEnum.Edad19a35,
                    Value: configParams.edad19a35
                },
                edad36a45:{
                    Key: ConfigAccountParamEnum.Edad36a45,
                    Value: configParams.edad36a45
                },
                edad46a55:{
                    Key: ConfigAccountParamEnum.Edad46a55,
                    Value: configParams.edad46a55
                },
                edad56a65:{
                    Key: ConfigAccountParamEnum.Edad56a65,
                    Value: configParams.edad56a65
                },
                edadMas65:{
                    Key: ConfigAccountParamEnum.EdadMas65,
                    Value: configParams.edadMas65
                },
                recAntecEnfermedadesCatastrof:{
                    Key: ConfigAccountParamEnum.RecAntecEnfermedadesCatastrof,
                    Value: configParams.recAntecEnfermedadesCatastrof
                },
                edadMaximaCoberturaHijoAdulto:{
                    Key: ConfigAccountParamEnum.EdadMaximaCoberturaHijoAdulto,
                    Value: configParams.edadMaximaCoberturaHijoAdulto
                },
                retencionGananciaMinima:{
                    Key: ConfigAccountParamEnum.RetencionGananciaMinima,
                    Value: configParams.retencionGananciaMinima
                },
                retencionMontoExcluido:{
                    Key: ConfigAccountParamEnum.RetencionMontoExcluido,
                    Value: configParams.retencionMontoExcluido
                }
            }
            const {status} = await AddConfigParams(configParamToSend);
            if (status === StatusCode.Ok){
                MessageNotification("configuración de parámetros establecida correctamente.", "success");
                setApplyDefaultConfig(false);
                GetConfigParamList();
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores,"error")
        }finally{
            setLoaderActive(false);
        }
    }
    async function EditConfigParamsToRegional(){
        try{
            setLoaderActive(true);
            const configParamToSend: IConfigParamsToSend = {
                montoCuotaSocietaria: {
                    Key: ConfigAccountParamEnum.MontoCuotaSocietaria,
                    Value: configParams.montoCuotaSocietaria
                },
                montoMantenimientoMatricula:{
                    Key: ConfigAccountParamEnum.MontoMantenimientoMatricula,
                    Value: configParams.montoMantenimientoMatricula
                },
                montoSistemaDocumental:{
                    Key: ConfigAccountParamEnum.MontoSistemaDocumental,
                    Value: configParams.montoSistemaDocumental
                },
                porcentajeDebitoColegio:{
                    Key: ConfigAccountParamEnum.PorcentajeDebitoColegio,
                    Value: configParams.porcentajeDebitoColegio
                },
                porcentajeDebitoRegional:{
                    Key: ConfigAccountParamEnum.PorcentajeDebitoRegional,
                    Value: configParams.porcentajeDebitoRegional
                },
                montoGastoNoSocio:{
                    Key: ConfigAccountParamEnum.MontoGastoNoSocio,
                    Value: configParams.montoGastoNoSocio
                },
                motivoDebitoAutomatico:{
                    Key: ConfigAccountParamEnum.MotivoDebitoAutomatico,
                    Value: configParams.motivoDebitoAutomatico
                },
                edad0a18:{
                    Key: ConfigAccountParamEnum.Edad0a18,
                    Value: configParams.edad0a18
                },
                edad19a35:{
                    Key: ConfigAccountParamEnum.Edad19a35,
                    Value: configParams.edad19a35
                },
                edad36a45:{
                    Key: ConfigAccountParamEnum.Edad36a45,
                    Value: configParams.edad36a45
                },
                edad46a55:{
                    Key: ConfigAccountParamEnum.Edad46a55,
                    Value: configParams.edad46a55
                },
                edad56a65:{
                    Key: ConfigAccountParamEnum.Edad56a65,
                    Value: configParams.edad56a65
                },
                edadMas65:{
                    Key: ConfigAccountParamEnum.EdadMas65,
                    Value: configParams.edadMas65
                },
                recAntecEnfermedadesCatastrof:{
                    Key: ConfigAccountParamEnum.RecAntecEnfermedadesCatastrof,
                    Value: configParams.recAntecEnfermedadesCatastrof
                },
                edadMaximaCoberturaHijoAdulto:{
                    Key: ConfigAccountParamEnum.EdadMaximaCoberturaHijoAdulto,
                    Value: configParams.edadMaximaCoberturaHijoAdulto
                },
                retencionGananciaMinima:{
                    Key: ConfigAccountParamEnum.RetencionGananciaMinima,
                    Value: configParams.retencionGananciaMinima
                },
                retencionMontoExcluido:{
                    Key: ConfigAccountParamEnum.RetencionMontoExcluido,
                    Value: configParams.retencionMontoExcluido
                }
            }
            const {status} = await EditConfigParams(configParamToSend);
            if (status === StatusCode.Ok){
                MessageNotification("configuración de parámetros editada correctamente.", "success");
                GetConfigParamList();
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores,"error")
        }finally{
            setLoaderActive(false);
        }
    }
    //#endregion
    
    //#region CREAR / EDITAR / BORRAR / MODAL - Seguros
    async function PostNewInsurance() {
        try {
            if (ValidateInputsInsurance()) {
                setLoaderActive(true);
                const bodyRequest = {
                    nombre: valuesInsurance.nombre,
                    importe: valuesInsurance.importe
                }
                await api.PostSeguro(bodyRequest);
    
                MessageNotification("Seguro creado correctamente.", "success");
                GetInsurance();
                setOpenModalInsurance(defaulModalInsurance);
                setValuesInsurance(defaulValuesInsurance);
            }
        } catch (err: any) {
            MessageNotification(err?.response?.data?.errores ?? "Error al Crear el Seguro", "error");
        } finally {
            setLoaderActive(false);
        }
    }
    async function UpdateInsurance() {
        try {
            if (ValidateInputsInsurance()) {
                setLoaderActive(true);
                const bodyRequest = {
                    nombre: valuesInsurance.nombre,
                    importe: valuesInsurance.importe
                }
                await api.UpdateSeguro(valuesInsurance.id, bodyRequest);
    
                MessageNotification("Seguro editado correctamente.", "success");
                GetInsurance();
                setOpenModalInsurance(defaulModalInsurance);
                setValuesInsurance(defaulValuesInsurance);
            }
        } catch (err: any) {
            MessageNotification(err?.response?.data?.errores ?? "Error al Editar el Seguro", "error");
        } finally {
            setLoaderActive(false);
        }
    }
    async function DeleteInsurance() {
        try {
            if (ValidateInputsInsurance()) {
                setLoaderActive(true);
                await api.DeleteSeguro(valuesInsurance.id);
    
                MessageNotification("Seguro elmiminado correctamente.", "success");
                GetInsurance();
                setOpenModalInsurance(defaulModalInsurance);
                setValuesInsurance(defaulValuesInsurance);
            }
        } catch (err: any) {
            MessageNotification(err?.response?.data?.errores ?? "Error al Editar el Seguro", "error");
        } finally {
            setLoaderActive(false);
        }
    }

    const HandleCloseModal = () => {
		setOpenModalInsurance({ isOpen: false, typeModal: "" });
        setValuesInsurance(defaulValuesInsurance);
	}
    const HandleOpenModal = (item, typeModal) => {
		setOpenModalInsurance({ isOpen: true, typeModal });
        setValuesInsurance({ id: item.id, nombre: item.nombre, importe: item.importe });
	}
    //#endregion

    //#region UTILS
    function ValidateInputs(){
        let isValidated = true;
        let objError = {};

        Object.entries(configParams).filter((input) => !isNaN(input[1]) && +input[1] < 0).reduce((acc, obj)=>{
            acc[obj[0]] = true;
            return acc
        },objError);

        if (Object.keys(objError).length > 0){
            MessageNotification("Los valores deben ser numéricos y mayores o iguales a cero.","error");
            isValidated = false;
            setObjErrorNotification(objError);
        }

        return isValidated;
    }
    function ValidateInputsInsurance() {
        if (valuesInsurance.nombre === null || valuesInsurance.nombre === "") {
            MessageNotification("El Nombre no puede estar vacío.", "error");
            return false;
        }
        if (valuesInsurance.importe === null || valuesInsurance.importe <= 0) {
            MessageNotification("El Importe debe ser numérico y mayor que cero.", "error");
            return false;
        }
        return true;
    }
    function HandleChangeParams(e){
        setConfigParams({...configParams, [e.target.name]: +e.target.value});
    }
    function MessageNotification(message: string, type: string){
        setDataToast({
            active: true,
            message,
            type
        });
    }
    //#endregion
    return (
        <>
            {loaderActive && 
                <Dimmer className="loader-fixed" active inverted>
                    <Loader size="big">Cargando...</Loader>
                </Dimmer>
            }
            <>
                <Grid item style={{marginBottom: 30}}>
                    <Card className='color-border-dash'>
                        <CardContent className='center-responsive-filters'>
                            <Grid className='header-section'>
                                <div className="titleAndVideo">
                                    <span className={customStyles.textStyles}>Parámetros Regional</span>
                                </div>
                                <div className="contBtnHeaderSection">
                                    <Button color='green' onClick={HandleSubmit}>Aceptar</Button>
                                </div>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid>
                    {applyDefaultConfig && 
                    <Button
                        style={{margin: 10}}
                        primary
                        icon
                        onClick={HandleSubmit}
                        type='submit'
                        >
                        Aplicar valores por defecto
                    </Button>}
                    <Grid className='generalContainer-params'>
                        <Grid style={{margin: 10}}>
                            <Typography className={customStyles.textStyles}>Descuentos Comunes</Typography>
                            <Grid className='container-configParams'>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig} 
                                    titleOfParam="Monto Cuota Societaria" 
                                    values={configParams} 
                                    setValues={HandleChangeParams} 
                                    name="montoCuotaSocietaria"/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig} 
                                    titleOfParam="Monto Mantenimiento Matrícula" 
                                    values={configParams} 
                                    setValues={HandleChangeParams} 
                                    name="montoMantenimientoMatricula"/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig} 
                                    titleOfParam="Monto Sistema Documental" 
                                    values={configParams} 
                                    setValues={HandleChangeParams} 
                                    name="montoSistemaDocumental"/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig} 
                                    titleOfParam="Monto Gasto No Socios" 
                                    values={configParams} 
                                    setValues={HandleChangeParams} 
                                    name="montoGastoNoSocio"/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<Icon name="percent"/>}
                                    isDisabled={applyDefaultConfig} 
                                    titleOfParam="Porcentaje Débito Colegio" 
                                    values={configParams} 
                                    setValues={HandleChangeParams} 
                                    name="porcentajeDebitoColegio"/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<Icon name="percent"/>}
                                    isDisabled={applyDefaultConfig} 
                                    titleOfParam="Porcentaje Debito Regional" 
                                    values={configParams} 
                                    setValues={HandleChangeParams} 
                                    name="porcentajeDebitoRegional"/>
                                <ConfigParamRegional
                                    type="text"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AccountBalanceOutlinedIcon />}
                                    isDisabled={true} 
                                    titleOfParam="Motivo para Débito Automático" 
                                    values={configParams} 
                                    setValues={()=>{}} 
                                    name="motivoDebitoAutomatico"/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig} 
                                    titleOfParam="Retención de Ganancia Mínima" 
                                    values={configParams} 
                                    setValues={HandleChangeParams} 
                                    name="retencionGananciaMinima"/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig} 
                                    titleOfParam="Retención Monto Excluido" 
                                    values={configParams} 
                                    setValues={HandleChangeParams} 
                                    name="retencionMontoExcluido"/>
                            </Grid>
                        </Grid>
                        <Grid style={{margin: 10}}>
                            <Typography className={customStyles.textStyles}>Parámetros Obra social</Typography>
                            <Grid className='container-configParams'>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig}  
                                    titleOfParam="Edad: 00 a 18"
                                    name="edad0a18"
                                    values={configParams}
                                    setValues={HandleChangeParams}/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig}  
                                    titleOfParam="Edad: 19 a 35"
                                    name="edad19a35"
                                    values={configParams}
                                    setValues={HandleChangeParams}/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig}  
                                    titleOfParam="Edad: 36 a 45"
                                    name="edad36a45"
                                    values={configParams}
                                    setValues={HandleChangeParams}/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig}  
                                    titleOfParam="Edad: 46 a 55"
                                    name="edad46a55"
                                    values={configParams}
                                    setValues={HandleChangeParams}/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig}  
                                    titleOfParam="Edad: 56 a 65"
                                    name="edad56a65"
                                    values={configParams}
                                    setValues={HandleChangeParams}/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig}  
                                    titleOfParam="Edad: + 65"
                                    name="edadMas65"
                                    values={configParams}
                                    setValues={HandleChangeParams}/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<AttachMoneyOutlinedIcon />}
                                    isDisabled={applyDefaultConfig}  
                                    titleOfParam="Rec. por antec. de enfermedades catástrof."
                                    name="recAntecEnfermedadesCatastrof"
                                    values={configParams}
                                    setValues={HandleChangeParams}/>
                                <ConfigParamRegional
                                    type="number"
                                    objErrorNotification={objErrorNotification}
                                    icon={<GroupAddOutlinedIcon/>}
                                    isDisabled={applyDefaultConfig}  
                                    titleOfParam="Edad maxima de cobertura de hijo adulto"
                                    name="edadMaximaCoberturaHijoAdulto"
                                    values={configParams}
                                    setValues={HandleChangeParams}/>
                            </Grid>
                        </Grid>
                        <Grid style={{margin: 10}}>
                            <Typography className={customStyles.textStyles} style={{maxWidth: 554}}>
                                Seguros
                                <Button
                                    className="btn-new-filter"
                                    onClick={() => setOpenModalInsurance({isOpen: true, typeModal: "nuevo"})}
                                    icon
                                    labelPosition="left"
                                >
                                    <Icon name="plus" />
                                    Nuevo
                                </Button>
                            </Typography>
                            <Grid className='container-configParams'>
                                <div className="table-container">
                                    <table className="table">
                                        <thead className="table-header">
                                            <tr>
                                                <th className="table-cell-head c-text-left">Nombre</th>
                                                <th className="table-cell-head c-text-right">Importe</th>
                                                <th className="table-cell-head text-center">Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {insuranceList.map((item: any) => (
                                                <tr key={item.id} className="table-row">
                                                    <td className="table-cell c-text-left">{item.nombre}</td>
                                                    <td className="table-cell c-text-right">{FormatNumber({style: 'currency', currency: '$', value: item.importe})}</td>
                                                    
                                                    {insuranceList.length > 0 && (
                                                        <td className="table-cell text-center">
                                                            <Button.Group>
                                                                <IconButton
                                                                    className='btn-circle-orange'
                                                                    onClick={() => HandleOpenModal(item, "editar")}
                                                                >
                                                                    <Tooltip placement='top' title="Editar">
                                                                        <EditIcon />
                                                                    </Tooltip>
                                                                </IconButton>
                                                                <IconButton
                                                                    className='btn-circle-red'
                                                                    onClick={() =>HandleOpenModal(item, "borrar")}
                                                                >
                                                                    <Tooltip placement="top" title="Eliminar">
                                                                        <DeleteIcon />
                                                                    </Tooltip>
                                                                </IconButton>
                                                            </Button.Group>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>

            <GeneralModalDelete
                contentText={`¿Esta seguro de eliminar el siguiente seguro: ${valuesInsurance.nombre}?`}
                openModal={openModalInsurance.isOpen && openModalInsurance.typeModal === "borrar"}
                HandleClose={HandleCloseModal}
                HandleSubmit={DeleteInsurance}
                warningText={`Al dar de baja este seguro se quitará en todos los profesionales que lo tengan asignado.`}
            />

            {openModalInsurance.isOpen && openModalInsurance.typeModal === "nuevo" &&
                <ModalInsurance
                    titleOfModal={"Nuevo"}
                    openModalInsurance={openModalInsurance}
                    valuesInsurance={valuesInsurance}
                    setValuesInsurance={setValuesInsurance}
                    HandleActionInsurance={PostNewInsurance}
                    HandleCloseModal={HandleCloseModal}
                />
            }
            {openModalInsurance.isOpen && openModalInsurance.typeModal === "editar" &&
                <ModalInsurance
                    titleOfModal={"Editar"}
                    openModalInsurance={openModalInsurance}
                    valuesInsurance={valuesInsurance}
                    setValuesInsurance={setValuesInsurance}
                    HandleActionInsurance={UpdateInsurance}
                    HandleCloseModal={HandleCloseModal}
                />
            }
        </>
    )
}

export default ConfigurationParamsRegional;