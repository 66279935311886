import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';
import { persistor } from './redux';
import { PersistGate } from 'redux-persist/integration/react';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

window.addEventListener('load', function () {
  serviceWorker.unregister();
});

defineCustomElements(window);

ReactDOM.render(
<PersistGate persistor={persistor}>
  <App />
</PersistGate>,document.getElementById('root'));