import DateFnsUtils from "@date-io/date-fns";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid
} from "@material-ui/core";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { es } from "date-fns/locale";
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../../api";
import { Genders, SocialWorkEnum, TypeRole, TypeRoleNum } from "../../../../enums";
import { IInfoForSelect, IInformationProfessional, ILoadingForSelect, ISelectedInfo } from "../../../../services/interfaces/Configurations/IInformationProfessional";
import { GetBankSelectList } from "../../../../services/services/Bank";
import { GetSocialWorkSelect } from "../../../../services/services/ConfigurationSocialWork";
import { GetConditionIVA } from "../../../../services/services/Users";
import InputFormLabel from '../../../common/InputFormLabel';
import CustomReactSelect from "../../../utils/CustomReactSelect";
import { _DEFAULT_VALUES } from "../defaultValues";
import ModalNotificationDescountOs from "../modals/ModalNotificationDescountOS";

const InformationProfessional: React.FC<IInformationProfessional> = ({
    dataProfesional,
    openModalInfo,
    errorInput,
    amountDetail,
    setOpenModalInfo,
    setDataProfesional,
    MessageNotification,
    GetDescountAmountByOs,
}) => {
    const { idProf } = useParams<any>();
    const { register } = useForm({});
    const [rolLogged, setRolLogged] = React.useState<any>(JSON.parse(localStorage.getItem("TypeRole") ?? ""));
    const [loadingForSelect, setLoadingForSelect] = React.useState<ILoadingForSelect>(_DEFAULT_VALUES._LOADING_FOR_SELECT);
    const [selectInfoSelected, setSelectInfoSelected] = React.useState<ISelectedInfo>(_DEFAULT_VALUES._SELECT_INFO_SELECTED);
    const [infoForSelect, setInfoForSelect] = React.useState<IInfoForSelect>(_DEFAULT_VALUES._INFO_FOR_SELECT);
    const hasManagment = useSelector<any>(({ managment }) => managment.hasManagment);

    React.useEffect(() => {
        GetAllAditionalData();
    }, []);

    React.useEffect(() => {
        if (dataProfesional.obraSocialId === SocialWorkEnum.apross && idProf !== undefined) {
            GetDescountAmountByOs(dataProfesional);
        }
    }, [dataProfesional.obraSocialId]);

    async function GetAllAditionalData() {
        try {
            setLoadingForSelect({
                accountList: true,
                associationList: true,
                rolList: true,
                bankList: true,
                socialWorkList: true,
                conditionIVA: true,
                seguros: true
            });
            const params = {
                socialWork: {
                    asignadas: true
                }
            }
            const [socialWorks, rols, banks, conditionIVA, seguros] = await Promise.all([GetSocialWorkSelect(params.socialWork), api.getTypeRoles(), GetBankSelectList(), GetConditionIVA(), api.GetSegurosSelect()]);

            const seguroList = seguros.data.map(s => {
                return { value: s.id, label: s.nombre }
            })

            const rolForList = rols.data.tiposRole.map(rol => {
                return { value: rol.Id, label: rol.Nombre }
            });

            const osForList = socialWorks.data.results.map((os, index) => {
                return { value: os.id, label: os.nombre }
            });

            const bankForList = banks.data.results.map((os, index) => {
                return { value: os.id, label: os.nombre }
            });

            const conditionIVAList = conditionIVA.data.results.map((os, index) => {
                return { value: os.id, label: os.nombre }
            });

            if (rolLogged === TypeRole.SuperAdmin) {
                const [accounts, associations] = await Promise.all([api.getAccountsUsersList(), api.GetAllAssociations()]);

                const accForList = accounts.data.map(acc => {
                    return { value: acc.id, label: acc.nombre }
                });
                const assForList = associations.data.map(ass => {
                    return { value: ass.id, label: ass.nombre }
                });

                setInfoForSelect({
                    socialWorkList: [...infoForSelect.socialWorkList, ...osForList],
                    accountList: accForList,
                    associationList: assForList,
                    bankList: [...infoForSelect.bankList, ...bankForList],
                    rolList: rolForList,
                    conditionIVAList: conditionIVAList,
                    seguroList: [...infoForSelect.seguroList, ...seguroList]
                });
            } else {
                setInfoForSelect({
                    ...infoForSelect,
                    socialWorkList: [...infoForSelect.socialWorkList, ...osForList],
                    rolList: rolForList,
                    bankList: [...infoForSelect.bankList, ...bankForList],
                    conditionIVAList: conditionIVAList,
                    seguroList: [...infoForSelect.seguroList, ...seguroList]
                });
            }
        } catch (err: any) {
            MessageNotification(err.response.data.errores, "error");
        } finally {
            setLoadingForSelect(_DEFAULT_VALUES._LOADING_FOR_SELECT)
        }
    }

    //#region Controladores de inputs
    function HandleChangeValues(e) {
        setDataProfesional({
            ...dataProfesional,
            [e.target.name]: e.target.value
        });
    }
    function HandleChangeCheckboxValues(e) {
        setDataProfesional({
            ...dataProfesional,
            [e.target.name]: e.target.checked
        });
    }
    function HandleConditionIVA(objValue) {
        setDataProfesional({
            ...dataProfesional,
            condicionIVAId: objValue !== null ? objValue.value : null,
        });
    }
    function HandleSocialWork(objValue) {
        const updateInfo = {
            ...dataProfesional,
            monto: 0
        }
        if (objValue.value === SocialWorkEnum.apross && idProf !== undefined) {
            setOpenModalInfo({
                modal: false,
                viewInfo: true
            });
        }
        else {
            setOpenModalInfo({
                modal: false,
                viewInfo: false
            });
        }
        setDataProfesional({
            ...updateInfo,
            cuentaId: selectInfoSelected.rol.value === TypeRoleNum.Professional
                || selectInfoSelected.rol.value === TypeRoleNum.Admin ? updateInfo.cuentaId : null,
            colegioId: null,
            obraSocialId: objValue !== null ? objValue.value : null,
        });
        setSelectInfoSelected({
            ...selectInfoSelected,
            cuenta: selectInfoSelected.rol.value === TypeRoleNum.Professional
                || selectInfoSelected.rol.value === TypeRoleNum.Admin ? selectInfoSelected.cuenta : _DEFAULT_VALUES._SELECT_INFO_SELECTED.cuenta,
            colegio: _DEFAULT_VALUES._SELECT_INFO_SELECTED.colegio,
            obraSocial: objValue !== null ? objValue : _DEFAULT_VALUES._SELECT_INFO_SELECTED.obraSocial
        });
    }
    function HandleAccount(objValue) {
        setDataProfesional({
            ...dataProfesional,
            cuentaId: objValue !== null ? objValue.value : null,
            colegioId: null,
            obraSocialId: selectInfoSelected.rol.value === TypeRoleNum.Professional
                || selectInfoSelected.rol.value === TypeRoleNum.Admin ? dataProfesional.obraSocialId : null,
        });
        setSelectInfoSelected({
            ...selectInfoSelected,
            cuenta: objValue !== null ? objValue : _DEFAULT_VALUES._SELECT_INFO_SELECTED.cuenta,
            colegio: _DEFAULT_VALUES._SELECT_INFO_SELECTED.colegio,
            obraSocial: selectInfoSelected.rol.value === TypeRoleNum.Professional
                || selectInfoSelected.rol.value === TypeRoleNum.Admin ? selectInfoSelected.obraSocial : _DEFAULT_VALUES._SELECT_INFO_SELECTED.obraSocial
        });
    }
    function HandleAssociation(objValue) {
        setDataProfesional({
            ...dataProfesional,
            cuentaId: null,
            colegioId: objValue !== null ? objValue.value : null,
            obraSocialId: null,
        });
        setSelectInfoSelected({
            ...selectInfoSelected,
            cuenta: _DEFAULT_VALUES._SELECT_INFO_SELECTED.cuenta,
            colegio: objValue !== null ? objValue : _DEFAULT_VALUES._SELECT_INFO_SELECTED.colegio,
            obraSocial: _DEFAULT_VALUES._SELECT_INFO_SELECTED.obraSocial
        });
    }
    function HandleBank(objValue) {
        setDataProfesional({
            ...dataProfesional,
            bancoId: objValue !== null ? objValue.value : null,
        });
        setSelectInfoSelected({
            ...selectInfoSelected,
            banco: objValue !== null ? objValue : _DEFAULT_VALUES._SELECT_INFO_SELECTED.banco,
        });
    }
    function HandleSex(objValue, aditionalInfo) {
        setSelectInfoSelected({
            ...selectInfoSelected,
            [aditionalInfo.name]: objValue !== null ? objValue : _DEFAULT_VALUES._SELECT_INFO_SELECTED[aditionalInfo.name]
        });

        setDataProfesional({
            ...dataProfesional,
            [aditionalInfo.name]: objValue !== null ? objValue.label : _DEFAULT_VALUES._SELECT_INFO_SELECTED[aditionalInfo.name]
        });
    }
    function HandleRol(objValue) {
        const newEnrollment = objValue !== null ? (objValue.value === TypeRoleNum.ObraSocial || objValue.value === TypeRole.TypeRoleNum ? 0 : dataProfesional.matricula) : 0;
        const updateInfo = {
            ...dataProfesional,
            matricula: newEnrollment
        }
        setDataProfesional({
            ...updateInfo,
            rolId: objValue !== null ? objValue.value : null
        });
        setSelectInfoSelected({
            ...selectInfoSelected,
            rol: objValue !== null ? objValue : _DEFAULT_VALUES._SELECT_INFO_SELECTED.rol
        });
    }
    function HandleSeguro(objValue) {
        setDataProfesional({
            ...dataProfesional,
            seguroId: objValue !== null ? objValue.value : null
        });
        setSelectInfoSelected({
            ...selectInfoSelected,
            seguro: objValue !== null ? objValue : _DEFAULT_VALUES._SELECT_INFO_SELECTED.seguro
        });
    }
    function HandleChangeDateValue(date, name) {
        setDataProfesional({
            ...dataProfesional,
            [name]: date
        });
    }
    //#endregion

    return (
        <>
            <Grid className="container-profesional-info">
                <Grid className="container-profesional-info__columns">
                    <InputFormLabel
                        stylesCustom={{ zIndex: 5 }}
                        placeholder=""
                        defaultValue={idProf !== undefined ? `${dataProfesional.nombre}` : null}
                        label='Nombre del Usuario'
                        name='nombre'
                        control={null}
                        error={errorInput.nombre}
                        type='text'
                        isRequired={register({ required: true })}
                        classInput={errorInput.nombre && "errorNotificationInput"}
                        value={dataProfesional.nombre}
                        textChange={(e) => HandleChangeValues(e)}
                        disabled={false}
                        isCreate={false}
                    />
                    <InputFormLabel
                        stylesCustom={{ zIndex: 5 }}
                        placeholder=""
                        defaultValue={idProf !== undefined ? `${dataProfesional.apellido}` : null}
                        type='text'
                        label='Apellido del Usuario'
                        name='apellido'
                        control={null}
                        error={errorInput.apellido}
                        isRequired={register({ required: true })}
                        classInput={errorInput.apellido && "errorNotificationInput"}
                        value={dataProfesional.apellido}
                        textChange={(e) => HandleChangeValues(e)}
                        disabled={false}
                        isCreate={false}
                    />
                    <InputFormLabel
                        stylesCustom={{ zIndex: 5 }}
                        placeholder=""
                        defaultValue={idProf !== undefined ? `${+dataProfesional.matricula}` : null}
                        type='number'
                        label='N° Matrícula'
                        classInput={`${selectInfoSelected.rol.value === TypeRoleNum.ObraSocial
                            || selectInfoSelected.rol.value === TypeRoleNum.Colegio
                            || dataProfesional.colegioId !== null ? "disabled-input" : `${errorInput.matricula && "errorNotificationInput"}`}`}
                        name='matricula'
                        control={null}
                        disabled={(
                            selectInfoSelected.rol.value === TypeRoleNum.ObraSocial ||
                            selectInfoSelected.rol.value === TypeRoleNum.Colegio
                            || dataProfesional.colegioId !== null)}
                        error={errorInput.matricula}
                        isRequired={register({ required: true })}
                        value={dataProfesional.matricula}
                        textChange={(e) => HandleChangeValues(e)}
                        isCreate={false}
                    />
                    {idProf === undefined &&
                        <InputFormLabel
                            stylesCustom={{ zIndex: 5 }}
                            placeholder=""
                            defaultValue={idProf !== undefined ? `${dataProfesional.clave}` : null}
                            type='text'
                            label='Clave Cuenta'
                            name='clave'
                            control={null}
                            error={errorInput.clave}
                            isRequired={register({ required: true })}
                            classInput={errorInput.clave && "errorNotificationInput"}
                            value={dataProfesional.clave}

                            textChange={(e) => HandleChangeValues(e)}
                            disabled={false}
                            isCreate={false}
                        />}
                    <InputFormLabel
                        stylesCustom={{ zIndex: 5 }}
                        placeholder=""
                        defaultValue={idProf !== undefined ? `${+dataProfesional.cuit}` : null}
                        type='number'
                        label='CUIT'
                        name='cuit'
                        control={null}
                        error={errorInput.cuit}
                        isRequired={register({ required: true })}
                        classInput={errorInput.cuit && "errorNotificationInput"}
                        value={dataProfesional.cuit}
                        textChange={(e) => HandleChangeValues(e)}
                        disabled={false}
                        isCreate={false}
                    />
                    <InputFormLabel
                        stylesCustom={{ zIndex: 5 }}
                        placeholder=""
                        defaultValue={idProf !== undefined ? `${dataProfesional.cbu}` : null}
                        type='text'
                        label='CBU / ALIAS'
                        name='cbu'
                        control={null}
                        error={errorInput.cbu}
                        isRequired={register({ required: true })}
                        classInput={errorInput.cbu && "errorNotificationInput"}
                        value={dataProfesional.cbu}
                        textChange={(e) => HandleChangeValues(e)}
                        disabled={false}
                        isCreate={false}
                    />
                    <InputFormLabel
                        stylesCustom={{ zIndex: 5 }}
                        placeholder=""
                        defaultValue={idProf !== undefined ? `${dataProfesional.email}` : null}
                        type='text'
                        label='Correo'
                        name='email'
                        control={null}
                        error={errorInput.correo}
                        classInput={errorInput.email && "errorNotificationInput"}
                        isRequired={register({ required: true })}
                        value={dataProfesional.email}
                        textChange={(e) => HandleChangeValues(e)}
                        disabled={false}
                        isCreate={false}
                    />
                    <div className="cont-infoProfessional-select">
                        <CustomReactSelect
                            placeholder="Seguros..."
                            label="Mala Praxis"
                            name="malaPraxis"
                            isLoading={loadingForSelect.seguros}
                            customStyles={errorInput.seguroId && "errorNotificationInput"}
                            options={infoForSelect.seguroList}
                            value={infoForSelect.seguroList.find((s: any) => s.value === dataProfesional.seguroId) ?? null}
                            setValue={(e) => HandleSeguro(e)}
                        />
                    </div>
                </Grid>

                <Grid className="container-profesional-info__columns">
                    <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ margin: 5 }}
                            autoOk
                            clearable
                            variant='inline'
                            name="fechaNacimiento"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label={dataProfesional.fechaNacimiento === null ? "" : "Fecha Nacimiento"}
                            className={errorInput.fechaNacimiento && "errorNotificationInputDatePicker"}
                            id="date-picker-inline"
                            placeholder="Fecha de Nacimiento"
                            value={dataProfesional.fechaNacimiento}
                            onChange={(date) => HandleChangeDateValue(date, "fechaNacimiento")}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <div className="cont-infoProfessional-select">
                        <CustomReactSelect
                            placeholder="Sexo"
                            label="Sexo"
                            name="sexo"
                            customStyles={errorInput.sexo && "errorNotificationInput"}
                            isLoading={false}
                            options={Genders}
                            value={Genders.find((os: any) => os.label === dataProfesional.sexo) ?? null}
                            setValue={(e, aditionalInfo) => HandleSex(e, aditionalInfo)}
                        />
                    </div>
                    <div className="cont-infoProfessional-select">
                        <CustomReactSelect
                            placeholder="Roles..."
                            label="Roles"
                            name="rol"
                            isLoading={loadingForSelect.rolList}
                            customStyles={errorInput.rolId && "errorNotificationInput"}
                            options={infoForSelect.rolList}
                            value={infoForSelect.rolList.find((os: any) => os.value === dataProfesional.rolId) ?? null}
                            setValue={(e) => HandleRol(e)}
                        />
                    </div>
                    <div className="cont-infoProfessional-select">
                        <CustomReactSelect
                            placeholder="Condicion de IVA..."
                            label="Condicion IVA"
                            name="condicionIVAId"
                            isLoading={loadingForSelect.conditionIVA}
                            customStyles={errorInput.rolId && "errorNotificationInput"}
                            options={infoForSelect.conditionIVAList}
                            value={infoForSelect.conditionIVAList.find((os: any) => os.value === dataProfesional.condicionIVAId) ?? null}
                            setValue={(e) => HandleConditionIVA(e)}
                        />
                    </div>
                    {selectInfoSelected.rol.value !== TypeRoleNum.Colegio &&
                        <div className="cont-infoProfessional-select">
                            <CustomReactSelect
                                placeholder="Obras Sociales..."
                                label="Obra Social"
                                name="obraSocial"
                                isLoading={loadingForSelect.socialWorkList}
                                customStyles={errorInput.obraSocialId && "errorNotificationInput"}
                                options={infoForSelect.socialWorkList}
                                value={infoForSelect.socialWorkList.find((os: any) => os.value === dataProfesional.obraSocialId) ?? null}
                                setValue={(e) => HandleSocialWork(e)}
                            />

                            {openModalInfo.viewInfo && !loadingForSelect.socialWorkList &&
                                <div className="signalInfoDescount">
                                    <Button onClick={() => {
                                        setOpenModalInfo({ ...openModalInfo, modal: true });
                                    }}><HelpOutlineOutlinedIcon /></Button>
                                </div>}
                        </div>}
                    <InputFormLabel
                        stylesCustom={{ zIndex: 5 }}
                        placeholder=""
                        defaultValue={idProf !== undefined ? `${+dataProfesional.monto}` : null}
                        type='number'
                        label='Monto Obra Social'
                        name='monto'
                        control={null}
                        error={errorInput.monto}
                        classInput={errorInput.monto && "errorNotificationInput"}
                        isRequired={register({ required: true })}
                        value={loadingForSelect.socialWorkList ? 0 : dataProfesional.monto}
                        textChange={(e) => HandleChangeValues(e)}
                        disabled={hasManagment && (dataProfesional.obraSocialId === SocialWorkEnum.apross || dataProfesional.obraSocialId === null || selectInfoSelected.colegio.value !== null)}
                        isCreate={false}
                    />
                    <div className="cont-infoProfessional-select">
                        <CustomReactSelect
                            placeholder="Bancos..."
                            label="Bancos"
                            name="banco"
                            isLoading={loadingForSelect.bankList}
                            customStyles={errorInput.cuentaId && "errorNotificationInput"}
                            options={infoForSelect.bankList}
                            value={infoForSelect.bankList.find((bk: any) => bk.value === dataProfesional.bancoId) ?? null}
                            setValue={(e) => HandleBank(e)}
                        />
                    </div>
                    {rolLogged === TypeRole.SuperAdmin &&
                        selectInfoSelected.rol.value === TypeRoleNum.Colegio &&
                        <div className="cont-infoProfessional-select">
                            <CustomReactSelect
                                placeholder="Colegios..."
                                label="Colegios"
                                name="colegio"
                                isLoading={loadingForSelect.associationList}
                                customStyles={errorInput.colegioId && "errorNotificationInput"}
                                options={infoForSelect.associationList}
                                value={infoForSelect.associationList.find((os: any) => os.value === dataProfesional.colegioId) ?? null}
                                setValue={(e) => HandleAssociation(e)}
                            />
                        </div>}
                    {rolLogged === TypeRole.SuperAdmin &&
                        (selectInfoSelected.rol.value === TypeRoleNum.Admin ||
                            selectInfoSelected.rol.value === TypeRoleNum.Professional) &&
                        <div className="cont-infoProfessional-select">
                            <CustomReactSelect
                                placeholder="Regionales..."
                                label="Regionales"
                                name="cuenta"
                                isLoading={loadingForSelect.accountList}
                                customStyles={errorInput.cuentaId && "errorNotificationInput"}
                                options={infoForSelect.accountList}
                                value={infoForSelect.accountList.find((os: any) => os.value === dataProfesional.cuentaId) ?? null}
                                setValue={(e) => HandleAccount(e)}
                            />
                        </div>}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dataProfesional.administradoPorRegional}
                                name="administradoPorRegional"
                                onChange={(e) => {
                                    HandleChangeCheckboxValues(e);
                                }}
                            />
                        }
                        label='Administrado por Regional'
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dataProfesional.trabajaConObraSocial}
                                name="trabajaConObraSocial"
                                onChange={(e) => {
                                    HandleChangeCheckboxValues(e);
                                }}
                            />
                        }
                        label='Trabaja con Obra Social'
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dataProfesional.noSocio}
                                name="noSocio"
                                onChange={(e) => {
                                    HandleChangeCheckboxValues(e);
                                }}
                            />
                        }
                        label='No Socio'
                    />
                </Grid>
            </Grid>

            {openModalInfo.modal &&
                <ModalNotificationDescountOs
                    amountDetail={amountDetail}
                    openModal={openModalInfo.modal}
                    HandleClose={() => setOpenModalInfo({ ...openModalInfo, modal: false })}
                />
            }
        </>
    )
}

export default InformationProfessional;