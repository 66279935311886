import React, { useEffect } from 'react';
import api from '../../../api';
import PhotoView from './PhotoView';

export default function PopUpImagesMobile({
  title,
  data,
  dataToast,
  document,
  dontDelete,
  MessageNotification,
  reloadData,
  handleClose,
  isMobile,
  setOpen
}) {
  let tipoUsuario = JSON.parse(localStorage.getItem('TypeRole'));
  const [abrirModal, setAbrirModal] = React.useState(false);
  const [photo, setPhoto] = React.useState({ imagenes: [] });

  useEffect(() => {
    PreviewImg(data, document);
    setAbrirModal(true);
  }, [])

  const PreviewImg = (imagesId, document) => {
    let arrayFunctions = [];
    arrayFunctions = downloadImagesByDocument(imagesId, document);
    downloadImageFromDB(arrayFunctions);
  };

  const downloadImageFromDB = async (arrayFunctions) => {
    try {
      const res = await Promise.all(arrayFunctions);
      const urls = res.map((item) => ({
        src: URL.createObjectURL(item.data),
        title: title,
      }));
      setPhoto({ imagenes: urls });
    } catch (err) {
      MessageNotification(err.response?.data?.errores || "Error desconocido", "error");
    }
  };

  const downloadImagesByDocument = (imagesId, document) => {
    let returnInfo = [];
    if (imagesId.length > 1) {
      imagesId.forEach((item) => {
        let image = {
          documentoId: document,
          imagenId: item.id,
        };
        returnInfo.push(api.imagesDownload(image));
      });
    } else {
      let item = imagesId[0];
      let image = {
        documentoId: document,
        imagenId: item.id,
      };
      returnInfo.push(api.imagesDownload(image));
    }
    return returnInfo;
  }

  return (
    <PhotoView
      tipoUsuario={tipoUsuario}
      imagenes={photo.imagenes}
      data={data}
      document={document}
      dontDelete={dontDelete}
      dataToast={dataToast}
      MessageNotification={MessageNotification}
      reloadData={reloadData}
      abrirModal={abrirModal}
      setAbrirModal={setAbrirModal}
      handleClose={handleClose}
      isMobile={isMobile}
      setOpen={setOpen}
    />
  );
}
