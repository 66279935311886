import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Menu, Icon } from "semantic-ui-react";
import { TypeRole } from '../../../enums/index';

const useStyles = makeStyles(() => ({
    dashboardList: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
        gap: 15
    },
    menuContent: {
        backgroundColor: "#ef4f5b",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
        borderRadius: 4,
        height: 60,
        transition: "transform .3s ease",
        cursor: "pointer",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        "&:hover": {
            transform: "scale(.94)",
            transition: "transform .3s ease"
        }
    },
    titleGralContent: {
        marginLeft: 15,
        fontSize: "27px",
        fontWeight: "bold"
    },
    sectionIndicators: {
        display: "flex", flexDirection: "column", borderLeft: "4px solid #ef4f5b", borderRadius: 6, padding: 20,
    }
}));

export default function ListMenu() {
    const history = useHistory();
    const classes = useStyles();

    const buttonSelected = (textUrl) => {
        let url = window.location.href;
        if (url.includes(textUrl)) {
            return true;
        } else {
            return false;
        }
    };

    const [patient, setPatient] = useState(false);
    const [returns, setReturns] = useState(false);
    const [document, setDocument] = useState(false);
    const [periods, setPeriods] = useState(false);
    const [holiday, setHoliday] = useState(false);
    const [configUser, setConfigUser] = useState(false);
    const [typeRolUser, setTypeRolUser] = useState();
    const [liquidation, setLiquidation] = useState(false);
    const [instructiveList, setInstructiveList] = useState(false);
    const [amountHistorial, setAmountHistorial] = useState(false);

    useEffect(() => {
        setPatient(JSON.parse(localStorage.getItem('patient')));
        setReturns(JSON.parse(localStorage.getItem('returns')));
        setPeriods(JSON.parse(localStorage.getItem('periods')));
        setDocument(JSON.parse(localStorage.getItem('document')));
        setHoliday(JSON.parse(localStorage.getItem('holiday')));
        setConfigUser(JSON.parse(localStorage.getItem('configUser')));
        setTypeRolUser(JSON.parse(localStorage.getItem('TypeRole')));
        setLiquidation(JSON.parse(localStorage.getItem('liquidation')));
        setAmountHistorial(JSON.parse(localStorage.getItem('amountHistorial')));
        setInstructiveList(JSON.parse(localStorage.getItem('instructive')));
    });

    return (
        <>
            <section className={classes.sectionIndicators} style={{ marginBottom: 15 }}>
                <section className={classes.dashboardList} >

                    {patient && (
                        <section className={classes.menuContent} onClick={() => history.push('/pacientes')}>
                            <section style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                                <Menu.Item as='a'>
                                    <Icon
                                        style={{ fontSize: "1.8em", margin: 6, height: "50%", marginLeft: 25, color: "#ffff" }}
                                        name='list alternate'
                                        className={
                                            buttonSelected('paciente')
                                                ? 'icon-sidebar-selected'
                                                : 'icon-menu'
                                        }
                                    />
                                </Menu.Item>
                            </section>
                            <section>
                                <p style={{ fontSize: "1.7em", color: "#ffff", width: "160px" }}>Pacientes</p>
                            </section>
                        </section>
                    )}

                    {periods && (
                        <section className={classes.menuContent} onClick={() => history.push('/periodos/lista')}>
                            <section style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                                <Menu.Item as='a'>
                                    <Icon
                                        style={{ fontSize: "1.7em", margin: 6, height: "50%", marginLeft: 20, color: "#ffff" }}
                                        name='calendar alternate'
                                        className={
                                            buttonSelected('periodos/lista')
                                                ? 'icon-sidebar-selected'
                                                : 'icon-menu'
                                        }
                                    />
                                </Menu.Item>
                            </section>
                            <p style={{ fontSize: "1.7em", color: "#ffff", width: "160px" }}>Periodos</p>
                        </section>
                    )}

                    {returns && (
                        <section className={classes.menuContent} onClick={() => history.push('/devoluciones/lista')}>
                            <section style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                                <Menu.Item as='a'>
                                    <Icon
                                        style={{ fontSize: "1.7em", margin: 6, height: "50%", marginLeft: 20, color: "#ffff" }}
                                        name='redo alternate'
                                        className={
                                            buttonSelected('devoluciones/lista')
                                                ? 'icon-sidebar-selected'
                                                : 'icon-menu'
                                        }
                                    />
                                </Menu.Item>
                            </section>
                            <p style={{ fontSize: "1.7em", color: "#ffff", width: "160px" }}>Devoluciones</p>
                        </section>
                    )}

                    {liquidation && (
                        <section className={classes.menuContent} onClick={() => history.push('/liquidaciones/lista')}>
                            <section style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                                <Menu.Item as='a'>
                                    <Icon
                                        style={{ fontSize: "1.7em", margin: 6, height: "50%", marginLeft: 20, color: "#ffff" }}
                                        name='file alternate'
                                        className={
                                            buttonSelected('liquidaciones')
                                                ? 'icon-sidebar-selected'
                                                : 'icon-menu'
                                        }
                                    />
                                </Menu.Item>
                            </section>
                            <p style={{ fontSize: "1.7em", color: "#ffff", width: "160px" }}>Liquidaciones</p>
                        </section>
                    )}

                    {instructiveList && (
                        <section className={classes.menuContent} onClick={() => history.push('/instructivos/lista')}>
                            <section style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                                <Menu.Item as='a'>
                                    <Icon
                                        style={{ fontSize: "1.7em", margin: 6, height: "50%", marginLeft: 20, color: "#ffff" }}
                                        name='book alternate'
                                        className={
                                            buttonSelected('instructivos')
                                                ? 'icon-sidebar-selected'
                                                : 'icon-menu'
                                        }
                                    />
                                </Menu.Item>
                            </section>
                            <p style={{ fontSize: "1.7em", color: "#ffff", width: "160px" }}>Instructivos</p>
                        </section>
                    )}

                    {amountHistorial && (
                        <section className={classes.menuContent} onClick={() => history.push('/montos/lista')}>
                            <section style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                                <Menu.Item as='a'>
                                    <Icon
                                        style={{ fontSize: "1.7em", margin: 6, height: "50%", marginLeft: 20, color: "#ffff" }}
                                        name='dollar alternate'
                                        className={
                                            buttonSelected('montos')
                                                ? 'icon-sidebar-selected'
                                                : 'icon-menu'
                                        }
                                    />
                                </Menu.Item>
                            </section>
                            <p style={{ fontSize: "1.7em", color: "#ffff", width: "160px" }}>Montos</p>
                        </section>
                    )}

                    {document && (
                        <section className={classes.menuContent} onClick={() => history.push('/documentos/lista')}>
                            <section style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                                <Menu.Item as='a'>
                                    <Icon
                                        style={{ fontSize: "1.7em", margin: 6, height: "50%", marginLeft: 20, color: "#ffff" }}
                                        name='address card'
                                        className={
                                            buttonSelected('documentos/lista')
                                                ? 'icon-sidebar-selected'
                                                : 'icon-menu'
                                        }
                                    />
                                </Menu.Item>
                            </section>
                            <p style={{ fontSize: "1.7em", color: "#ffff", width: "160px" }}>Documentos</p>
                        </section>
                    )}

                    {holiday && (
                        <section className={classes.menuContent} onClick={() => history.push('/feriados/lista')}>
                            <section style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                                <Menu.Item as='a'>
                                    <Icon
                                        style={{ fontSize: "1.7em", margin: 6, height: "50%", marginLeft: 20, color: "#ffff" }}
                                        name='calendar'
                                        className={
                                            buttonSelected('feriados')
                                                ? 'icon-sidebar-selected'
                                                : 'icon-menu'
                                        }
                                    />
                                </Menu.Item>
                            </section>
                            <p style={{ fontSize: "1.7em", color: "#ffff", width: "160px" }}>Feriados</p>
                        </section>

                    )}

                    {typeRolUser == TypeRole.Colegio && (
                        <section className={classes.menuContent} onClick={() => history.push('/documentos-por-periodo/listazip')}>
                            <section style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                                <Menu.Item as='a'>
                                    <Icon
                                        style={{ fontSize: "1.7em", margin: 6, height: "50%", marginLeft: 20, color: "#ffff" }}
                                        name='file archive'
                                        className={
                                            buttonSelected('documentos-por-periodo/listazip')
                                                ? 'icon-sidebar-selected'
                                                : 'icon-menu'
                                        }
                                    />
                                </Menu.Item>
                            </section>
                            <p style={{ fontSize: "1.7em", color: "#ffff", width: "160px" }}>Doc. Por Periodo</p>
                        </section>
                    )}

                    {typeRolUser == TypeRole.Admin && configUser && (
                        <section className={classes.menuContent} onClick={() => history.push('/configuraciones/usuarios')}>
                            <section style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                                <Menu.Item as='a'>
                                    <Icon
                                        style={{ fontSize: "1.7em", margin: 6, height: "50%", marginLeft: 20, color: "#ffff" }}
                                        name='users'
                                        className={
                                            buttonSelected('/configuraciones/usuarios')
                                                ? 'icon-sidebar-selected'
                                                : 'icon-menu'
                                        }
                                    />
                                </Menu.Item>
                            </section>
                            <p style={{ fontSize: "1.7em", color: "#ffff", width: "160px" }}>Usuarios</p>
                        </section>
                    )}

                    {typeRolUser == TypeRole.Admin && (
                        <section className={classes.menuContent} onClick={() => history.push('/configuraciones/exportar-excel')}>
                            <section style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                                <Menu.Item as='a' >
                                    <Icon
                                        style={{ fontSize: "1.7em", margin: 6, height: "50%", marginLeft: 20, color: "#ffff" }}
                                        name='file archive'
                                        className={
                                            buttonSelected('/configuraciones/exportar-excel')
                                                ? 'icon-sidebar-selected'
                                                : 'icon-menu'
                                        }
                                    />
                                </Menu.Item>
                            </section>
                            <p style={{ fontSize: "1.7em", color: "#ffff", width: "180px" }}>Exportar Excel</p>
                        </section>
                    )}

                    {typeRolUser == TypeRole.Admin && (
                        <section className={classes.menuContent} onClick={() => history.push('/configuraciones/obras-sociales/asignar')}>
                            <section style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                            }}>
                                <Menu.Item as='a'>
                                    <Icon
                                        style={{ fontSize: "1.7em", margin: 6, height: "50%", marginLeft: 20, color: "#ffff" }}
                                        name='user md'
                                        className={
                                            buttonSelected('/configuraciones/obras-sociales/asignar')
                                                ? 'icon-sidebar-selected'
                                                : 'icon-menu'
                                        }
                                    />
                                </Menu.Item>
                            </section>
                            <p style={{ fontSize: "1.7em", color: "#ffff", width: "180px" }}>Obras Sociales</p>
                        </section>
                    )}

                </section>
            </section>
        </>)
}