import GridMui from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckIcon from "@material-ui/icons/CheckSharp";
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import CloseIcon from "@material-ui/icons/CloseSharp";
import Pagination from "@material-ui/lab/Pagination";
import { saveAs } from "file-saver";
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dimmer, Grid, Loader } from "semantic-ui-react";
import "../../App.scss";
import api from "../../api";
import "./Period.css";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  TableCell
} from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import Checkbox from '@material-ui/core/Checkbox';
import { StatusOfDocument } from "../../enums";
import ButtonBox from "../utils/ButtonBox";
import { useSelector } from 'react-redux';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { encode } from 'base64-arraybuffer';
import { Share } from '@capacitor/share';
import DownloadPeriodModal from "./modals/DownloadPeriodModal ";

export default function PeriodNewEditList({
  loadData,
  dataTable,
  totalCount,
  loadForPage,
  setDataToast,
  isEdit,
  isAdmin,
  isProfessional,
  setEdit,
  page,
  setPage,
  handleClickSearch,
}) {
  const history = useHistory();
  const handleChange = (event, value) => {
    setPage(value);
    loadData(value);
    window.scrollTo(0, 0);
  };
  const [socialWorkByPeriod, setSocialWorkByPeriod] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});
  const hasManagment = useSelector(({managment}) => managment.hasManagment);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 680);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [dataToDownloadModal, setDataToDownloadModal] = useState({
    profesionalId: null,
    mes: null,
    anio: null,
    id: null,
    profesionalNombre: null
  });

	React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 680);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const saveChecks = (id, proffesionalName) => {
    setIsLoading(true);
    const socialWorks = Object.entries(state).map(([key,value]) => {
      return {
        obraSocialId: +key,
        marcada: value
      }
    });
    api
      .updateVerifiedDocumentsBySW(id, proffesionalName, socialWorks)
      .then(() => {
        setDataToast({
          active: true,
          message: "Actualizado correctamente",
          type: "success",
        });
        handleClickSearch();
      })
      .catch((err) => {
        setDataToast({
          active: true,
          message: err.response.data.errores || "Acaba de ocurrir un error",
          type: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setOpen(false);
      });
  };

  const viewListSocialWorks = (id, professionalId) => {
    setIsLoading(true);
    api
      .getDocumentByPeriodId(id, professionalId)
      .then((res) => {
        let socialWorkState = {};
        let dataApi = res.data;
        let arraySocialWorks = [];
        dataApi.forEach((item) => {
          let model = {
            socialWork: item.socialWork,
            count: item.count,
            socialWorkId: item.documentDataResponses[0].obraSocialId,
            proffesional: professionalId,
            periodId: id,
          };
          arraySocialWorks.push(model);
          socialWorkState = {
            ...socialWorkState,
            [item.documentDataResponses[0].obraSocialId]: item.documentDataResponses.every(doc => doc.estadoId === StatusOfDocument.Verified || doc.estadoId === StatusOfDocument.Envoiced),
          }
        });
        setState(socialWorkState);
        setSocialWorkByPeriod(arraySocialWorks);
        setOpen(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setDataToast({
          active: true,
          message: err.response.data.errores || "Acaba de ocurrir un error",
          type: "error",
        });
        setOpen(false);
        setIsLoading(false);
      });
  };

  const printPeriods = async (documentId, professionalId) => {
    setIsLoading(true);

    try {
      const resp = await api.printSocialWorksPeriods(documentId, professionalId);

      let fileName = `Obras_Sociales_por_Periodo.pdf`;
      const blob = new Blob([resp.data], { type: "application/pdf" });

      if (isMobile) {
        const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
				fileName = `Obras_Sociales_por_Periodo_${timestamp}.pdf`;

        //Descargo el PDF
        const arrayBuffer = await blob.arrayBuffer();
				const base64Data = encode(arrayBuffer);
				const savedFile = await Filesystem.writeFile({
					path: fileName,
					data: base64Data,
					directory: Directory.Documents,
				});

        setIsLoading(false);
        setDataToast({
          active: true,
          message: "PDF descargado en Documents",
          type: "success",
        });

        //Abro el archivo con un visor de PDF nativo.
				const fileUri = savedFile.uri;
				await Share.share({
					title: 'Abrir PDF',
					text: 'Abrir el archivo PDF descargado.',
					url: fileUri,
					dialogTitle: 'Abrir con...',
				});

      } else {
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      if (err.message === "Share canceled") {
				//no hacer nada.
			} else {
        setDataToast({
          active: true,
          message: err.response.data.errores || "Acaba de ocurrir un error",
          type: "error",
        });
      }
    } finally {
      setIsLoading(false)
    }
  };

  const changeCheck = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.checked,
    });
  };

  const ViewRowData = ({ item }) => {
    return (
      <GridMui container justifyContent="center" alignItems="center">
        <GridMui item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                key={item.socialWorkId}
                checked={state[item.socialWorkId]}
                onChange={(e)=>changeCheck(e)}
                
                id={item.socialWorkId}
              />
            }
          />
        </GridMui>

        <GridMui item xs={11}>
          <DialogContentText>
            {item.socialWork + " (" + item.count + ")"}
          </DialogContentText>
        </GridMui>
      </GridMui>
    );
  };

  const ViewPopUpRowData = ({ item }) => {
    return (
      <GridMui container justifyContent="center" alignItems="center">
        <GridMui item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                key={item.socialWorkId}
                checked={state[item.socialWorkId]}
                onChange={(e)=>changeCheck(e)}
                id={item.socialWorkId}
              />
            }
          />
        </GridMui>

        <GridMui item xs={11}>
          <DialogContentText>
            {item.socialWork + " (" + item.count + ")"}
          </DialogContentText>
        </GridMui>
      </GridMui>
    );
  };

  const DropDownOptionsToProf = ({ item, editPeriod }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <TableCell className='p-cell'>
        <IconButton onClick={handleClick}>
          <ListIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {editPeriod && isEdit && (
            <MenuItem
              className='btn-circle-yellow'
              aria-label='edit'
              onClick={() => { handleClose();
                history.push(
                '/periodo/editar/' + item.id,
                localStorage.setItem('professionalId', item.profesionalId)
              )}}
            >
              <Tooltip title="Editar">
                <EditIcon />
              </Tooltip>
            </MenuItem>
          )}
          <MenuItem
            className='btn-circle-blue'
            aria-label="print"
            onClick={() => printPeriods(item.id, item.profesionalId)}
          >
            <Tooltip title="Imprimir">
              <PrintIcon />
            </Tooltip>
          </MenuItem>

          {isAdmin && 
            <MenuItem
              className='btn-circle-blue'
              aria-label='printExcel'
              onClick={() => {
                setDataToDownloadModal({
                  profesionalId: item.profesionalId,
                  mes: item.numeroMes,
                  anio: +item.anio,
                  id: item.id,
                  profesionalNombre: item.profesionalNombre
                });
                setOpenDownloadModal(true);
                handleClose();
              }}
            >
              <Tooltip title="Descargar">
                <DescriptionOutlinedIcon />
            </Tooltip>
            </MenuItem>
          }
        </Menu>
      </TableCell>
    );
  }

  const TableRowData = ({ item }) => {
    let editPeriod = false;
		//let printPeriod = false;

		let today = new Date();

		let fechaCierre = new Date(item.fechaCierre);
    // let fechaFin = new Date(item.fechaFin);
    let fechaRevision = new Date(item.fechaFinRevision)

		today.setHours(0);
		today.setMinutes(0);
		today.setSeconds(0);
		today.setMilliseconds(0);
    
    
		if (isProfessional) {
			/*if (fechaCierre < today) {
				editPeriod = false;
				printPeriod = false;
			} else */
      if (fechaCierre >= today) {
				editPeriod = true;
			}
			/*if (fechaCierre > today) {
				printPeriod = true;
			}*/
		} 
    if(isAdmin){
      editPeriod = true;
    }
		setEdit(isEdit);

		return (
			<TableRow colSpan={5} key={item.id}>
        {isMobile && 
          <DropDownOptionsToProf item={item} editPeriod={editPeriod} />
        }
				<TableCell className='p-cell c-text-center'>{item.anio}</TableCell>
				<TableCell className='p-cell c-text-center'>{item.mes}</TableCell>
				<TableCell className='p-cell c-text-center'>
					{item.cantidadDocumentos}
				</TableCell>
				<TableCell className='p-cell c-text-center'>
					{item.profesionalNombre}
				</TableCell>
				{!isAdmin && (
					<TableCell className='p-cell c-text-center'>
						{fechaCierre.toLocaleDateString()}
					</TableCell>
				)}
				{isAdmin && !isMobile && (
					<>
						<TableCell className='p-cell c-text-center'>
							{item.cantidadObrasSociales}
							{isAdmin && (
                <ButtonBox name="viewPresentedSocialWorks" buttonToShow="viewPresentedSocialWorks" HandleOnClik={() =>
                  viewListSocialWorks(
                    item.id,
                    item.profesionalId,
                    item.profesionalNombre
                  )}/>
                // <ButtonBox
                //   name="viewPresentedSocialWorks"
                //   buttonToShow="viewPresentedSocialWorks"
                //   HandleOnClik={() => history.push(`/auditorias/periodos/${item.profesionalId}/${item.numeroMes}-${item.anio}`)}
                // />
							)}
						</TableCell>
						<TableCell className='p-cell c-text-center'>
              <Grid.Row style={{display: "flex", justifyContent: "center"}}>
                <Tooltip title="No Verificados" placement='top' arrow>
                  <Typography className="cont-status-info status-icon-notVerified">
                    <b>{item.noVerificados} </b><CloseIcon className='c-icon-cancel status-icons'/>
                  </Typography>
                </Tooltip>
                <Tooltip title="Verificados" placement='top' arrow>
                  <Typography className="cont-status-info status-icon-verified">
                    <b>{item.verificados} </b><CheckIcon className='c-icon-success status-icons'/>
                  </Typography>
                </Tooltip>
                {hasManagment && 
                <Tooltip title="Facturados" placement='top' arrow>
                  <Typography className="cont-status-info status-icon-pending">
                    <b>{item.facturados} </b><AttachMoneyOutlinedIcon className="c-icon-edit status-icons"/>
                  </Typography>
                </Tooltip>}
              </Grid.Row>
						</TableCell>
					</>
				)}
				{isAdmin && !isMobile && (
					<TableCell className='p-cell c-text-center'>{fechaCierre.toLocaleDateString()}</TableCell>
				)}
        {!isMobile && 
          <TableCell className='p-cell c-text-center'>
            <Button.Group>
              {editPeriod && isEdit && 
                <ButtonBox name="edit" buttonToShow="edit" HandleOnClik={() =>
                  history.push(
                    '/periodo/editar/' + item.id,
                    localStorage.setItem('professionalId', item.profesionalId)
                  )}/>}

            <ButtonBox name="print" buttonToShow="print" HandleOnClik={() => printPeriods(item.id, item.profesionalId)}/>
              
						{isAdmin &&
              <ButtonBox name="printExcel" buttonToShow="printExcel" HandleOnClik={() => {
                setDataToDownloadModal({
                  profesionalId: item.profesionalId,
                  mes: item.numeroMes,
                  anio: +item.anio,
                  id: item.id,
                  profesionalNombre: item.profesionalNombre
                });
                setOpenDownloadModal(true);
              }}/>}
					</Button.Group>
				</TableCell>
      }
			</TableRow>
		);
  };

  return (
    <>
      {isLoading && (
        <Dimmer className="loader-fixed" active inverted>
          <Loader size="big">Cargando..</Loader>
        </Dimmer>
      )}
      <Grid.Row>
        <TableContainer component={Paper} id="color-letter-table-tasks">
          <Table aria-label="spanning table">
            <TableHead className="color-letter-inTable">
              <TableRow colSpan={4}>
                {isMobile && 
                  <TableCell className="letter-title-table not-padding-table-task c-text-center">
                    <b>Opc...</b>
                  </TableCell>
                }
                <TableCell className="letter-title-table not-padding-table-task c-text-center">
                  <b>Año</b>
                </TableCell>
                <TableCell className="letter-title-table c-text-center">
                  <b>Mes</b>
                </TableCell>
                <TableCell className="letter-title-table c-text-center">
                  <b>Cantidad</b>
                </TableCell>
                <TableCell className="letter-title-table c-text-center">
                  <b>Profesional</b>
                </TableCell>
                {isAdmin && (
                  <>
                    <TableCell className="letter-title-table c-text-center">
                      <b>Cantidad Obras Sociales</b>
                    </TableCell>
                    <TableCell className="letter-title-table c-text-center">
                      <b>Estado</b>
                    </TableCell>
                  </>
                )}
                <TableCell className="letter-title-table c-text-center">
                  <b>Fecha de Cierre</b>
                </TableCell>
                {!isMobile &&
                  <TableCell className="letter-title-table c-text-center">
                    <b>Opciones</b>
                  </TableCell>
                }
              </TableRow>
            </TableHead>
            {dataTable && (
              <TableBody>
                {dataTable.map((item, index) => (
                  <TableRowData item={item} key={index} />
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid.Row>
      <Grid.Row centered>
        {dataTable && (
          <Pagination
            className="pagination"
            count={
              totalCount / loadForPage > parseInt(totalCount / loadForPage)
                ? parseInt(totalCount / loadForPage) + 1
                : parseInt(totalCount / loadForPage)
            }
            page={page}
            onChange={handleChange}
          />
        )}
      </Grid.Row>
      {socialWorkByPeriod && !isLoading && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{ style: { minWidth: 500 } }}
        >
          <DialogTitle> Obras Sociales Presentadas </DialogTitle>
          <DialogContent>
            {socialWorkByPeriod.length > 1 ? (
              socialWorkByPeriod.map((item) => <ViewRowData item={item} />)
            ) : (
              <ViewPopUpRowData item={socialWorkByPeriod[0]} />
            )}
          </DialogContent>
          <DialogActions style={{display: "flex", justifyContent: "center"}}>
            <Button
              className="c-btn-success"
              onClick={() => {saveChecks(socialWorkByPeriod[0].periodId, socialWorkByPeriod[0].proffesional); }}>
              Guardar
            </Button>
            <Button onClick={() => setOpen(false)}>Atras</Button>
          </DialogActions>
        </Dialog>
      )}
      
      <DownloadPeriodModal
        openModal={openDownloadModal}
        setOpenDownloadModal={setOpenDownloadModal}
        dataToDownloadModal={dataToDownloadModal}
        setIsLoading={setIsLoading}
      />
    </>
  );
}
