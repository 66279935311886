import { useState } from 'react';
import { TableCell, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EmailIcon from '@material-ui/icons/Email';
import DownloadImage from "@material-ui/icons/GetApp";
import ListIcon from "@material-ui/icons/List";
import PopUpImagesMobile from '../../utils/popupImage/PopUpImagesMobile';
import ImageSearchOutlinedIcon from '@material-ui/icons/ImageSearchOutlined';

function SimpleMenuCell({ item, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableCell className='p-cell'>
        <IconButton onClick={handleClick}>
          <ListIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {props.isView && (
            <MenuItem
              className='btn-circle-blue'
              aria-label='search'
              onClick={() => { handleClose(); props.handleClickView(item); }}
            >
              <Tooltip title="Ver">
                <SearchIcon />
              </Tooltip>
            </MenuItem>
          )}
          {!item.multiple && item.periodoId === null && props.isProfessional && item.numeroFormulario === null && (
            <MenuItem
              className='btn-circle-green'
              aria-label='search'
              onClick={() => { handleClose(); props.editValidate(item); }}
            >
              <Tooltip title="Agregar Sesión">
                <AddIcon />
              </Tooltip>
            </MenuItem>
          )}
          {props.isEdit && !props.EndDateExceeded && item.periodoId === null && item.numeroFormulario === null && (
            <MenuItem
              className='btn-circle-yellow'
              aria-label='edit'
              onClick={() => { handleClose(); props.handleClickEdit(item.documentoId); }}
            >
              <Tooltip title="Editar">
                <EditIcon />
              </Tooltip>
            </MenuItem>
          )}
          {item.imagenes.length > 0 &&
            <MenuItem
              className='btn-circle-blue'
              aria-label='viewImage'
              onClick={() => { setOpen(true); }}
            >
              <Tooltip title="Ver Imagen" placement='top' arrow>
                <ImageSearchOutlinedIcon />
              </Tooltip>
            </MenuItem>
          }
          {props.isExport && !props.isAdmin && !props.isSuperAdmin && !props.EndDateExceeded && item.periodoId === null && (
            <MenuItem
              className='btn-circle-green'
              aria-label='addImage'
              onClick={() => { handleClose(); props.handleClickAddImage(item.documentoId); }}
            >
              <Tooltip title="Nueva Imagen">
                <ImageIcon />
              </Tooltip>
            </MenuItem>
          )}
          {props.isExport && item.imagenes.length > 0 && (
            <MenuItem
              className='btn-circle-yellow'
              onClick={() => { handleClose(); props.DownloadImages(item); }}
            >
              <Tooltip title="Descargar">
                <DownloadImage />
              </Tooltip>
            </MenuItem>
          )}
          {!props.isAdmin && !props.devolutionSection && props.isDelete && item.periodoId === null && (
            <MenuItem
              className='btn-circle-red'
              aria-label='delete'
              onClick={() => {
                handleClose(); props.setAlertDelete(true);
                props.setDataSweetAlert({ id: item.documentoId, title: 'Borrar registro', content: `¿Está seguro de eliminar el registro de ${item.nombrePaciente} ${item.apellidoPaciente}?` });
              }}
            >
              <Tooltip title="Eliminar">
                <DeleteIcon />
              </Tooltip>
            </MenuItem>
          )}
          <MenuItem
            className='btn-circle-blue'
            aria-label='print'
            onClick={() => { handleClose(); props.printDocument(item.documentoId); }}
          >
            <Tooltip title="Imprimir">
              <PrintIcon />
            </Tooltip>
          </MenuItem>
          {props.isProfessional && props.devolutionSection && (
            <MenuItem
              className='btn-circle-yellow'
              aria-label='print'
              onClick={() => { handleClose(); props.handleClickOpenModalPeriod(`${item.apellidoPaciente} ${item.nombrePaciente}`, item.documentoId); }}
            >
              <Tooltip title="Presentar">
                <AssignmentTurnedInIcon />
              </Tooltip>
            </MenuItem>
          )}
          {item.comentario != null && props.devolutionSection && (
            <MenuItem
              className='btn-circle-blue contModalComment'
              aria-label='comment'
              onClick={() => { handleClose(); props.setOpenModalComment({ showModal: true, patient: `${item.nombrePaciente} ${item.apellidoPaciente}`, comment: item.comentario }); }}
            >
              <Tooltip title="Ver Comentario Devolución">
                <EmailIcon />
              </Tooltip>
            </MenuItem>
          )}
        </Menu>
      </TableCell>
      {open &&
        <PopUpImagesMobile
          title={`Imágenes de: ${item.nombrePaciente} ${item.apellidoPaciente}`}
          data={item.imagenes}
          dataToast={props.dataToast}
          document={item.documentoId}
          dontDelete={props.isDelete && item.periodoId === null}
          MessageNotification={props.MessageNotificacion}
          reloadData={props.reloadData}
          setLoadingActive={props.setLoadingActive}
          handleClose={handleClose}
          isMobile={props.isMobile}
          setOpen={setOpen}
        />
      }
    </>
  );
}

export default SimpleMenuCell;
