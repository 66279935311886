function FormatNumber({locale = 'es-AR', style, currency, value}){
    const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: 2
    });
    let formattedValue = formatter.format(value);

    if (style === 'currency') {
        formattedValue = `${currency} ${formattedValue}`;
    }

    return formattedValue;
}

function UniqueId(length = 16){
  return parseInt(
    Math.ceil(Math.random() * Date.now())
      .toPrecision(length)
      .toString()
      .replace('.', '')
  );
};

function FormatToString(date){
    let param = date;
    if(typeof date === 'string'){
       param = new Date(date)
    }
    let {day,month,year} = {
        day: param.getDate(),
        month: param.getMonth()+1,
        year: param.getFullYear()
    }
    return `${day}/${month}/${year}`;
}

export {FormatNumber, UniqueId, FormatToString};