import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { IBillUserByIdResponse } from '../../../../api/Interfaces/managment/ICrudBill';
import { StatusCode } from '../../../../enums';
import { IViewProfesionalList } from '../../../../services/interfaces/Managment/IViewProfesionalList';
import { GetBillById } from '../../../../services/services/CrudBill';
import { FormatNumber } from '../../../utils/FormatInputs';
import GeneralModalShow from '../../../utils/GeneralModalShow';

const ViewProfesionalList: React.FC<IViewProfesionalList> = ({
    title,
    openModal,
    aditionalData,
    HandleCloseModal,
    MessageNotification,
}) =>{
    const [profesionalList, setProfesionalList] = React.useState<IBillUserByIdResponse[]>([]);
    const [loaderActive, setLoaderActive] = React.useState(false);

    React.useEffect(()=>{
        LoadDataForTable();
    },[]);

    async function LoadDataForTable(){
        try{
            setLoaderActive(true);
            const {data, status} = await GetBillById(aditionalData.id);
            if (status === StatusCode.Ok){
                setProfesionalList(data.results.usuarioFactura);
            }
        }catch(err: any){
            MessageNotification(err.message, "error");
        }finally{
            setLoaderActive(false);
        }
    }
    return(
        <>
            {loaderActive ?
                <Dimmer className="loader-fixed" active inverted>
                    <Loader size="big">Cargando...</Loader>
                </Dimmer>
            :
            <GeneralModalShow
            title={title}
            HandleClose={HandleCloseModal}
            openModal={openModal}
            >
                <section>
                    <Table aria-label='spanning table'>
                        <TableHead>
                            <TableRow>
                                <TableCell className='letter-title-table c-text-right'>
                                    <b>Matrícula</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-left'>
                                    <b>Profesional</b>
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center'>
                                    <b>Monto</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {profesionalList.map((item: any) => (
                                <TableRow key={item.id}>
                                    <TableCell className='letter-title-table c-text-right'>
                                        {item.matricula}
                                    </TableCell>
                                    <TableCell className='letter-title-table c-text-left'>
                                        {item.apellido + ' ' + item.nombre}
                                    </TableCell>
                                    <TableCell className='letter-title-table c-text-right'>
                                        {FormatNumber({style: 'currency', currency: '$', value: item.monto})}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </section>
            </GeneralModalShow>
            }
        </>
    );
}

export default ViewProfesionalList;