import { makeStyles } from "@material-ui/core";
import React from 'react'
import './animations.css';
const useStyles = makeStyles(()=>({
    loader: {
        width: 10,
        height: 10,
        borderRadius: "50%",
        display: "inline-block",
        margin: "15px 40px",
        position: "relative",
        background: "transparent",
        boxShadow: "-24px 0 #FFF, 24px 0 #FFF",
        boxSizing: "border-box",
        animation: "shadowPulse 2.2s linear infinite"
    }
}))
  
export default function NewLoader(){
    const styleClass = useStyles();
    return <span className={styleClass.loader}></span>
}