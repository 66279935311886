import React from "react";
import api from "../../api";
import { Grid } from "semantic-ui-react";
import "./DatePickerForm.css";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { es } from "date-fns/locale";
import FormControl from "@material-ui/core/FormControl";
import GridMaterial from "@material-ui/core/Grid";

export default function DatePickerForm({
  dateValue,
  label,
  handleDateChange,
  name,
  minDate,
  minDateMessage,
  disabled,
  isMobile = false,
}) {
  const gridStyles = isMobile
  ? { width: "54%", marginTop: "-5px", marginBottom: "-5px" }
  : { width: "auto", marginTop: "0px", marginBottom: "0px" };

  return (
    <Grid.Row style={{ padding: isMobile ? 0 : 2 }}>
      <FormControl variant="outlined" size="small">
        <GridMaterial container spacing={1} alignItems="center">
          <GridMaterial item style={{ width: 140 }}>
            <span>{label}</span>
          </GridMaterial>
          <GridMaterial item className={isMobile ? "" : "filter-input"} style={gridStyles}>
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
              <KeyboardDatePicker
                disabled={disabled}
                minDateMessage={minDateMessage}
                //minDate={minDate}
                autoOk
                className={`size-date-picker-form ${isMobile ? "" : " filter-input"}`}
                inputVariant="outlined"
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                value={dateValue}
                onChange={(value) => handleDateChange(value, name)}
              />
            </MuiPickersUtilsProvider>
          </GridMaterial>
        </GridMaterial>
      </FormControl>
    </Grid.Row>
  );
}
