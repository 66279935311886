import { Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import React from 'react';
import { IConfigParamRegional } from "../../../services/interfaces/Managment/IConfigParamRegional";

const ConfigParamRegional: React.FC<IConfigParamRegional> = ({
    titleOfParam, 
    name, 
    isDisabled, 
    icon, 
    type,
    values, 
    objErrorNotification,
    setValues
    })=>{
    return (
        <Grid className='container-inputsParams'>
            <div>
                <Typography>{titleOfParam} : </Typography>
            </div>
            <div>
                <TextField
                className="container-inputsParams__item"
                disabled={isDisabled}
                variant='outlined'
                name={name}
                value={values[name]}
                size='small'
                style={{margin: "10px 0px 10px 10px" }}
                type={type}
                error={objErrorNotification[name] ?? false}
                InputLabelProps={{shrink: true}}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        {icon}
                    </InputAdornment>
                    ),
                    style: {
                        textAlignLast: "right",
                    },
                }}
                onChange={setValues}
                />
            </div>
        </Grid>
    )
}

export default ConfigParamRegional;