import { Controller } from "react-hook-form";
import Select from "react-select";

const SelectFilter = ({
  label,
  name,
  menuItemList,
  control,
  filtersLoading,
  isMobile = false,
}) => (
  <Controller
    className="align-select width-input-filter-dash filter-input"
    styles={{
      // Fixes the overlapping problem of the component
      menu: (provided) => ({ ...provided, zIndex: 9999 }),
      container: (provided) => ({ ...provided, minWidth: isMobile && name === "socialWork" ? '95%' : isMobile ? '0' : '220px', width: isMobile ? '45%' : 'unset' }),
    }}
    as={Select}
    Label={label}
    placeholder={label}
    noOptionsMessage={() => "No hay registros"}
    getOptionLabel={(option) => option.name}
    getOptionValue={(option) => option.id}
    isLoading={filtersLoading}
    isClearable={true}
    isSearchable={true}
    options={menuItemList}
    theme={(theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: "#6a5ef3",
      },
    })}
    control={control}
    name={name}
    id={name}
  />
);
export default SelectFilter;