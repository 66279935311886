import DateFnsUtils from "@date-io/date-fns";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from "@material-ui/core/styles";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { es } from 'date-fns/locale';
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactSelect from "react-select";
import { Button, Card, Dimmer, Grid, Icon, Loader } from "semantic-ui-react";
import api from "../../api";
import { Options, StatusCode, TypeRole } from "../../enums";
import { setFiltroData, setFiltroEstadoPeriodo, setFiltroFecha, setFiltroObraSocialPeriodo, setFiltroProfesionalPeriodo } from '../../redux/socialWork';
import InputFormLabel from "../common/InputFormLabel";
import GeneralModalCrud from "../utils/GeneralModalCrud";
import "./Period.css";
import PeriodList from "./PeriodList";

const useStyles = makeStyles({
	paperFullWidth: {
		overflowY: 'visible',
		maxWidth: "max-content",
	},
	dialogContentRoot: {
		overflowY: 'visible',
		maxWidth: 300
	},
});
const _DEFAULT_VALUES = {
	_TYPE_STATE: [
		{value: 2, label: "No Verificado"},
		{value: 1, label: "Verificado"},
	],
	modals: {
		makeBills: false
	}
}
export default function Period({ setDataToast }) {
	const [quantityData, setQuantityData] = useState(0);
	const [isView, setView] = useState(false);
	const [isCreate, setCreate] = useState(false);
	const [isEdit, setEdit] = useState(false);
	const [isDelete, setDelete] = useState(false);
	const [isExport, setExport] = useState(false);
	const [isProfessional, setProfessional] = useState(false);
	const [isAdmin, setAdmin] = useState(false);
	const [typeOfState, setTypeOfState] = useState(null);
	const [OpenExport, setOpenExport] = useState(false);
	const [loadingSelect, setLoadingSelect] = useState({
		socialWork: false,
	});
	const [socialWorks, setSocialWorks] = useState([]);
	const [nameSocialWorkPlans, setSocialWorksPlans] = useState([]);
	const [idSocialWork, setIdSocialWork] = useState();
	const classes = useStyles();
	const [dataTable, setDataTable] = useState();

	//redux con useForm
	let filtroFecha = useSelector((state) => state.socialWork.filtroPeriodo.fecha);
	let filtroProfesional = useSelector((state) => state.socialWork.filtroPeriodo.profesional);
	let filtroEstado = useSelector((state) => state.socialWork.filtroPeriodo.estado);
	let filtroObraSocial = useSelector((state) => state.socialWork.filtroPeriodo.obraSocial);
	const {
		register,
		control,
		getValues,
		errors,
		watch,
		setValue,
	} = useForm();
	const professionalValue = watch("professional");
	//------------------
	const [dateFilter, setDateFilter] = useState(null);
	const [dateFilterNew, setDateFilterNew] = useState({socialWorkId: null, date: null});
	const [page, setPage] = useState(1);
	const history = useHistory();
	const [loadingActive, setLoadingActive] = useState();
	const loadForPage = 20;
	const [state, setState] = React.useState({
		checkedAllfields: false,
	});
	const [filterObj, setFilterObj] = useState({obraSocial: null});
	const hasManagment = useSelector(({managment}) => managment.hasManagment);
	const [statusDocumentForSelect, setStatusDocumentForSelect] = useState(_DEFAULT_VALUES._TYPE_STATE);
	const [modals, setModals] = useState(_DEFAULT_VALUES.modals);
	const [nameSocialWork, setNameSocialWork] = useState();
	const [idPlanSelect, setIdPlanSelected] = useState();
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 680);
	const disparador = useDispatch();

	useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 680);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

	//en caso de que tenga gestion, añado "factorado" en filtro de Estado
	useEffect(() => {
		if (hasManagment) {
			setStatusDocumentForSelect((prevState) => [
				...prevState,
				{ value: 3, label: "Facturado" }
			]);
		} else {
			setStatusDocumentForSelect(_DEFAULT_VALUES._TYPE_STATE);
		}
	}, [hasManagment]);

	useEffect(() => {
		let idUser = JSON.parse(localStorage.getItem('TypeRole'));
		if (idUser == TypeRole.Professional) {
			setProfessional(true);
		}
		if (idUser == TypeRole.Admin) {
			setAdmin(true);
			GetSocialWorkList();
		}
		let options = JSON.parse(localStorage.getItem('optionsPeriods'));
		options.forEach((element) => {
			if (element == Options.isView) {
				setView(true);
			} else if (element == Options.isCreate) {
				setCreate(true);
			} else if (element == Options.isEdit) {
				setEdit(true);
			} else if (element == Options.isDelete) {
				setDelete(true);
			} else if (element == Options.isExport) {
				setExport(true);
			}
		});
		setLoadingSelect({
			...loadingSelect,
			socialWork: true,
		});
		let fecha = filtroFecha ? filtroFecha : null;
		let profesional = filtroProfesional ? filtroProfesional : null;
		let estado = filtroEstado ? filtroEstado : null;
		let obraSocial = filtroObraSocial ? filtroObraSocial : null;
		setDateFilter(fecha);
		setTypeOfState(estado?.value);
		if(profesional !== null) setValue('professional', profesional);
		if(obraSocial !== null) setFilterObj({...filterObj, obraSocial: obraSocial});
		if (fecha !== null){
			loadData();
		}
	}, []);

	// Guarda el valor en Redux cada vez que cambia el filtro de profesional
	useEffect(() => {
    if (professionalValue !== undefined) {
      disparador(setFiltroProfesionalPeriodo(professionalValue));
			setValue('professional', professionalValue);
    }
  }, [professionalValue, disparador, setValue]);

	async function GetSocialWorkList(){
		try{
			setLoadingSelect({
				...loadingSelect,
				socialWork: false,
			});
			const {status, data} = await api.GetSocialWorkForSelect();
			if (status === StatusCode.Ok){
				const socialWorkForList = data.map(item => {
					return {id: item.id, name: item["nombre"]}
				});
				setSocialWorks(socialWorkForList);
			}
		}catch(err){
			setDataToast({
				active: true,
				message: err.response.data.errores,
				type: 'error',
			});
		}finally{
			setLoadingSelect({
				...loadingSelect,
				socialWork: false,
			});
		}
	}
	async function HandleEnvoiced(){
		try{
			if (dateFilterNew.date === null){
				setDataToast({
					active: true,
					message: 'Debe ingresar Mes y Año',
					type: 'error',
				});
				return
			}
			setLoadingActive(true);
			const {status} = await api.GenerateEnvoices({periodo: dateFilterNew.date, obraSocialId: dateFilterNew.socialWorkId});
			if (status === StatusCode.Ok){
				HandleCloseMakeBill();
				setDataToast({
					active: true,
					message: 'Facturas creadas con éxito.',
					type: 'success',
				});
				loadData();
			}
		}catch(err){
			setDataToast({
				active: true,
				message: err.response.data.errores ?? 'Acaba de ocurrir un error',
				type: 'error',
			});
		}finally{
			setLoadingActive(false);
		}
	}
	const handleClickOpenExport = () => {
		setOpenExport(true);
		setState({ ...state, checkedAllfields: false });
	};
	const handleChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};
	const handleClickNew = () => {
		history.push('/periodos/presentar');
	};
	const handleClickSearch = () => {
		if (dateFilter == null) {
			setDataToast({
				active: true,
				message: 'Debe ingresar Mes y Año',
				type: 'error',
			});
			return
		}
		loadData();
	};
	const loadData = (pageSelected = 1) => {
		setLoadingActive(true);
		let values = getValues();
		let date = filtroFecha;
		let month = null;
		let year = null;
		
		month = date.getMonth() + 1;
		year = date.getFullYear();
		let professional = values.professional;
		let body = {
			page: pageSelected,
			quantity: loadForPage,
			month: month,
			year: year,
			professional: professional,
			stateId: filtroEstado,
			socialWorkId: filtroObraSocial?.id ?? null
		};

		api
			.getPeriods(body)
			.then((resp) => {
				let dataApiPaginate = resp.data.periodos;
				setQuantityData(resp.data.cantidadRegistros);
				setDataTable(dataApiPaginate);
				disparador(setFiltroData(dataApiPaginate));
				setLoadingActive(false);
			})
			.catch((err) => {
				setLoadingActive(false);
			});
		
	};
	const OnClickExport = (printMaster = false) => {
		if (!idSocialWork) {
			setDataToast({
				active: true,
				message: 'Debe seleccionar Obra Social',
				type: 'error',
			});
			return;
		}
		if (!dateFilterNew.date) {
			setDataToast({
				active: true,
				message: 'Debe ingresar Mes y Año',
				type: 'error',
			});
			return;
		}

		if (!idPlanSelect){
			setDataToast({
				active: true,
				message: 'Debe seleccionar un Plan',
				type: 'error',
			});
			return;
		}

		if (idSocialWork && dateFilterNew.date && !printMaster) {
			let body = {
				mes: parseInt(moment(dateFilterNew.date).format('MM')),
				anio: parseInt(moment(dateFilterNew.date).format('YYYY')),
				obraSocialId: idSocialWork,
				socialWorkPlanId: idPlanSelect,
				mostrarTodo: state.checkedAllfields,
			};
			setLoadingActive(true);
			api
				.postNewExport(body)
				.then((res) => {
					let extension = 'xlsx';
					let tempFileName =
						'Períodos ' +
						body.mes +
						'-' +
						body.anio +
						' de ' +
						nameSocialWork;
					let fileName = `${tempFileName}.${extension}`;

					let blob = new Blob([res.data], {
						type: 'application/vnd.ms-excel',
					});
					saveAs(blob, fileName);

					setDataToast({
						active: true,
						message: 'Exportado correctamente',
						type: 'success',
					});
				})
				.catch((err) => {
					setDataToast({
						active: true,
						message: err.response.data.errores ?? 'Acaba de ocurrir un error',
						type: 'error',
					});
				})
				.finally(() => setLoadingActive(false));
				setOpenExport(false);
				setIdSocialWork(null);
				setDateFilterNew({socialWorkId: null, date: null});
				setSocialWorksPlans([]);
		}else{
			setLoadingActive(true);
			setOpenExport(false);
			const params = {
				mes: parseInt(moment(dateFilterNew.date).format('MM')),
				anio: parseInt(moment(dateFilterNew.date).format('YYYY')),
				obraSocialId: idSocialWork,
				planId: idPlanSelect,
			}
			api
			.printPdfMaster(params)
			.then((resp) => {
				let extension = "pdf";
				let tempFileName = "Planilla de resumen mensual";
				let fileName = `${tempFileName}.${extension}`;
				let blob = new Blob([resp.data], {
				type: "application/pdf",
				});
				let a = document.createElement("a");
				document.body.appendChild(a);
				let url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = fileName;
				a.click();
				window.URL.revokeObjectURL(url);
			})
			.catch((err) => {
				setDataToast({
				active: true,
				message: err.response.data.errores || "Acaba de ocurrir un error",
				type: "error",
				});
			})
			.finally(()=>{
				setIdSocialWork(null);
				setDateFilterNew({socialWorkId: null, date: null});
				setSocialWorksPlans([]);
				setLoadingActive(false);
			})
		}
		
	};
	const getSocialWorksPlan = (event) => {
		let idSocialWorkSelected = event.id;
		api.getSocialWorksPlan(idSocialWorkSelected).then((resp) => {
			let plans = resp.data;
			setSocialWorksPlans(plans);
		});

		setNameSocialWork(event.name);
		setIdSocialWork(idSocialWorkSelected);
		setLoadingSelect({
			...loadingSelect,
			socialWork: true,
		});
	};
	const setSocialWorkPlanId = (event) => {
		let idPlanSelected = event.id;
		setIdPlanSelected(idPlanSelected);
	};
	function HandleCloseMakeBill(){
		setModals(_DEFAULT_VALUES.modals);
		setDateFilterNew({socialWorkId: null, date: null});
	}

	return (
		<>
			{loadingActive && (
				<Dimmer className='loader-fixed' active inverted>
					<Loader size='big'>Cargando..</Loader>
				</Dimmer>
			)}
			<Grid>
				<Grid.Row>
					<Card className='color-border-dash'>
						<CardContent className='center-responsive-filters'>
							<Grid.Row className="header-section">
								<div className="titleAndVideo">
									{/* {isProfessional && 
										<Tooltip title='Presentación de Documentos'>
											<IconButton
												style={{ marginLeft: 10 }}
												size='medium'
												href='https://www.youtube.com/watch?v=D6Kb6z3UmJI&ab_channel=OctusSoftware'
												target='_blank'
											>
												<Icon name='youtube' color='red' style={{ width: 20 }} />
											</IconButton>
										</Tooltip>
									} */}
									<span style={{ fontSize: 27, textAlign: "left"}}>Períodos</span>
								</div>
								<div className="contBtnHeaderSection">
									{isCreate == true && 
										<Button
											className='btn-new-filter'
											primary
											onClick={() => handleClickNew()}
											icon
											labelPosition='left'
										>
											<Icon name='tasks' />
											Añadir
										</Button>
									}
									{isExport == true && isAdmin && 
										<Button
											className='btn-add-filter'
											primary
											onClick={handleClickOpenExport}
											icon
											labelPosition='left'
										>
											<Icon name='share square outline' />
											Exportar
										</Button>
									}
									{isAdmin && hasManagment &&
										<Button
											className='btn-new-filter'
											primary
											onClick={()=> setModals({...modals, makeBills: true})}
											icon
											labelPosition='left'
										>
											<Icon name='paste' />
											Facturar
										</Button>
									}
								</div>
							</Grid.Row>
							<Grid.Row className='content-filter-list' style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Grid.Column width={14} style={{ display: 'flex' }}>
									<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
										<>
											<KeyboardDatePicker
												autoOk
												className='size-date-picke-filter filter-input'
												inputVariant='outlined'
												name='month'
												openTo='month'
												clearable
												views={['month', 'year']}
												placeholder='Mes - Año'
												value={dateFilter}
												onChange={(date) => {
													setDateFilter(date);
													disparador(setFiltroFecha(date));
												}}
											/>
										</>
									</MuiPickersUtilsProvider>
									{isProfessional == false && (
										<InputFormLabel
											classInput='width-input-filter-dash filter-input'
											register={register({ name: "professional" })}
											isRequired={false}
											type={'text'}
											label={'Profesional'}
											name={'professional'}
											control={control}
											defaultValue={filtroProfesional}
											error={errors.professional}
										/>
									)}
									{isAdmin && 
									<ReactSelect
										className="filter-input"
										placeholder="Estado..."
										isClearable={true}
										options={statusDocumentForSelect}
										value={statusDocumentForSelect.find(option => option.value === filtroEstado) || null}
										onChange={(e) => {
											setTypeOfState(e !== null ? e.value : e);
											disparador(setFiltroEstadoPeriodo(e !== null ? e.value : e));
										}}
									/>}
									{isAdmin && 
										<ReactSelect
										className="filter-input"
										placeholder='Obra Social...'
										loading={loadingSelect.socialWork}
										options={socialWorks}
										isClearable={true}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										value={socialWorks.find(option => option.id === filtroObraSocial?.id) || null}
										onChange={(e) => {
											setFilterObj({...filterObj, obraSocial: e});
											disparador(setFiltroObraSocialPeriodo(e));
										}}
									/>}
								</Grid.Column>
								<Grid.Column width={2} textAlign="right">
									{ isMobile
									? <Button primary onClick={() => handleClickSearch()}>
											<Icon name='search' style={{margin: 0}} />
										</Button>
									: <Button
											// className='btn-search-filter'
											primary
											onClick={handleClickSearch}
											icon
											labelPosition='left'
										>
										<Icon name='search' />
											Buscar
										</Button>
									}
								</Grid.Column>
							</Grid.Row>
						</CardContent>
					</Card>
				</Grid.Row>
				{dataTable?.length > 0 && (
					<PeriodList
						loadData={loadData}
						dataTable={dataTable}
						totalCount={quantityData}
						loadForPage={loadForPage}
						setDataToast={setDataToast}
						isEdit={isEdit}
						isAdmin={isAdmin}
						isProfessional={isProfessional}
						setEdit={setEdit}
						setPage={setPage}
						page={page}
						handleClickSearch={handleClickSearch}
					/>
				)}
			</Grid>

			<Dialog
			open={OpenExport}
			onClose={() => {
				setOpenExport(false);
				setSocialWorksPlans([]);
			}}
			aria-labelledby='form-dialog-title'
			fullWidth={true}
			classes={{
				paperFullWidth: classes.paperFullWidth,
			}}
		>
			<DialogTitle id='form-dialog-title'>
				Exportar Registro
			</DialogTitle>
			<DialogContent classes={{root: classes.dialogContentRoot}}>
				<ReactSelect
					placeholder='Obra Social...'
					loading={loadingSelect.socialWork}
					options={socialWorks}
					getOptionLabel={(option) => option.name}
					getOptionValue={(option) => option.id}
					onChange={getSocialWorksPlan}
					styles={{
						control: (s)=>({...s, maxWidth: 300})
					}}
				/>
				<ReactSelect
					placeholder='Plan...'
					loading={loadingSelect.socialWork}
					options={nameSocialWorkPlans}
					getOptionLabel={(option) => option.nombre}
					getOptionValue={(option) => option.id}
					onChange={setSocialWorkPlanId}
					styles={{
						control: (s)=>({...s, maxWidth: 300})
					}}
				/>
				<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
						<KeyboardDatePicker
							style={{ marginRight: 5, marginTop: 5 }}
							autoOk
							className='size-date-picke-filter'
							inputVariant='outlined'
							InputAdornmentProps={{ position: 'end' }}
							variant='inline'
							name='month'
							openTo='month'
							views={['month', 'year']}
							placeholder='Mes - Año'
							value={dateFilterNew.date}
							onChange={(date) => {
								setDateFilterNew({...dateFilterNew, date: date});
								disparador(setFiltroFecha(date));
							}}
						/>
				</MuiPickersUtilsProvider>
				<FormControlLabel
					control={
						<Checkbox
							checked={state.checkedAllfields}
							onChange={handleChange}
							name='checkedAllfields'
							color='primary'
							size='medium'
						/>
					}
					label='Mostrar todos los campos'
				/>
			</DialogContent>
			<DialogActions style={{ justifyContent: 'center' }}>
				<Button
					onClick={() => {
						setOpenExport(false);
						setSocialWorksPlans([]);
					}}
				>
					Cancelar
				</Button>
				<Button onClick={() => OnClickExport()} color='blue'>
					Excel
				</Button>
				<Button onClick={() => OnClickExport(true)} color='blue'>
					Reporte
				</Button>
			</DialogActions>
		</Dialog>

		{modals.makeBills &&
		<GeneralModalCrud
		titleOfModal="Facturar"
		openModal={true}
		HandleSubmit={HandleEnvoiced}
		HandleCloseModal={HandleCloseMakeBill}
		styles="cont-default-modal"
		>
			<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
				<div style={{marginLeft: 5 , display: 'flex', alignItems: 'center'}}>
					<div style={{minWidth: 258, marginRight: 6}}>
						<ReactSelect
							placeholder='Obra Social...'
							loading={loadingSelect.socialWork}
							options={socialWorks}
							isClearable={true}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
							onChange={(e) => setDateFilterNew({...dateFilterNew, socialWorkId: e?.id || null})}
						/>
					</div>
					<Tooltip title='Factura según la Obra Social Seleccionada (opcional)'>
						<HelpIcon name='help' color="action" fontSize="large"/>
					</Tooltip>
				</div>
				<KeyboardDatePicker
					style={{margin: 5}}
					autoOk
					className='size-date-picke-filter'
					inputVariant='outlined'
					InputAdornmentProps={{ position: 'end' }}
					variant='inline'
					name='month'
					openTo='month'
					views={['month', 'year']}
					placeholder='Mes - Año'
					value={dateFilterNew.date}
					onChange={(date) => setDateFilterNew({...dateFilterNew, date: date})}
				/>
			</MuiPickersUtilsProvider>
		</GeneralModalCrud>}
		</>
	);
}

