import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Indicator from './indicator/Indicator';
import ListMenu from './listMenu/ListMenu';
import { es } from 'date-fns/locale';
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { TypeRole } from '../../enums';
import PopUpNotification from '../utils/popUpNotification/PopUpNotification';

const useStyles = makeStyles(() => ({
    dashboardList:{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        gap: 15
    },
    titleGralContent: {
      marginLeft: 15,
      fontSize: "27px", 
      fontWeight: "bold"
    },
    sectionIndicators: {
      display:"flex", flexDirection: "column", borderLeft: "4px solid #ef4f5b", borderRadius: 6, padding: 20,
    }
  }));

export default function OptionList({setDataToast}){

  const defaultValues = {
      dataToPass: {
        mes: new Date().getMonth() + 1,
        anio: new Date().getFullYear()
      },
      selected: new Date()
    }

    const classes = useStyles();
    const [roleLogged, setRoleLogged] = useState(()=>JSON.parse(localStorage.getItem('TypeRole')));
    const [periodSelected, setPeriodSelected] = useState(defaultValues);
    const [data, setData] = useState(0);

    return(<>
      {/* ------lo vamos a usar para los Administrativos a futuro------
      <Grid style={{position: "relative", marginBottom: 20}}>
        <PopUpNotification title={"Tiene prestaciones de Pacientes pendientes."} redirect={"/integraciones/lista"} show={data ? true : false} quantity={data}/>
      </Grid> */}

      <ListMenu/>

      <section className={classes.sectionIndicators}>
          <h3 className={classes.titleGralContent}>Indicadores</h3>

         {roleLogged === TypeRole.Admin && <Grid.Row style={{
            display: "inline-flex",
            marginBottom: 20
          }}>
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
              <>
                <KeyboardDatePicker
                  style={{ marginRight: 5, marginTop: 5 }}
                  autoOk
                  className='size-date-picke-filter'
                  inputVariant='outlined'
                  InputAdornmentProps={{ position: 'end' }}
                  variant='inline'
                  name='month'
                  openTo='month'
                  views={['month', 'year']}
                  placeholder='Mes - Año'
                  value={periodSelected.selected}
                  onChange={(date) => {
                    setPeriodSelected({
                      ...periodSelected,
                      selected: date
                    });
                  }}
                />
                {periodSelected && (
                  <Icon
                    className='icon-clear-date-filter'
                    style={{ marginTop: 10 }}
                    name='close'
                    size='large'
                    onClick={() => {
                      setPeriodSelected(defaultValues);
                    }}
                  />
                )}
              </>
            </MuiPickersUtilsProvider>
            <Button
              className='btn-search-filter'
              primary
              icon
              labelPosition='left'
              onClick={() =>  setPeriodSelected({
                ...periodSelected,
                dataToPass: {
                  mes: periodSelected.selected.getMonth() + 1,
                  anio: periodSelected.selected.getFullYear()
                }
              })}
              type='submit'
            >
              <Icon name='search' />
              Buscar
            </Button>
          </Grid.Row>}
          
          <section className={classes.dashboardList}>
             {
              roleLogged === TypeRole.Admin && periodSelected ?
              <Indicator
              setDataToast={setDataToast}
              periodSelected={periodSelected.dataToPass}
              /> 
              :
              <Indicator
              setDataToast={setDataToast}
              data={data}
              setData={setData}
              />
             }
          </section>
      </section>
      
    </>)
}