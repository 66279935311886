import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { Button } from 'semantic-ui-react'

const ModalInsurance = ({
  titleOfModal,
  openModalInsurance,
  valuesInsurance,
  setValuesInsurance,
  HandleActionInsurance,
  HandleCloseModal
}) => {

  return (
    <Dialog
      open={openModalInsurance.isOpen}
      onClose={HandleCloseModal}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>{titleOfModal} Seguro</DialogTitle>
      <DialogContent className='cont-default-modal contModalAmount' style={{ marginBottom: 15 }}>
        <TextField
          size='small'
          label='Nombre'
          value={valuesInsurance.nombre ?? ""}
          onChange={(e) => {
            const newValue = e.target.value || null;
            setValuesInsurance((prevState) => ({
              ...prevState,
              nombre: newValue
            }));
          }}
        />
        <TextField
          size='small'
          label='Importe'
          style={{ position: "relative" }}
          type='number'
          value={valuesInsurance.importe ?? ""}
          onChange={(e) => {
            const newValue = e.target.value === "" ? null : Number(e.target.value);
            setValuesInsurance((prevState) => ({
              ...prevState,
              importe: newValue
            }));
          }}
        />
      </DialogContent>

      <DialogActions style={{ textAlign: 'center', justifyContent: 'center' }}>
        <Button onClick={HandleCloseModal}>
          Cancelar
        </Button>
        <Button onClick={HandleActionInsurance} color='green'>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalInsurance;