import DateFnsUtils from "@date-io/date-fns"
import { IconButton, TextField } from '@material-ui/core'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { es } from 'date-fns/locale'
import React, { useEffect } from 'react'
import ReactSelect from 'react-select'
import { TextArea } from 'semantic-ui-react'
import { ICrudModalAccreditation } from "../../../../services/interfaces/Managment/ICrudAccreditation"
import { GetProfesionals } from "../../../../services/services/Users"
import GeneralModalCrud from '../../../utils/GeneralModalCrud'
import { selectTypeFormat } from "../../../../services/interfaces/utils/IUtilDeclaration"

const NewModalAccreditation: React.FC<ICrudModalAccreditation> = ({
    titleOfModal,
    modal,
    openModal, 
    values, 
    setValues,
    errorNotification,
    HandleChangeInput, 
    MessageNotification,
    OpenModalSelected,
    SendValues})=>{

        const [profesionalList, setProfesionalList] = React.useState<selectTypeFormat[]>([]);
        const [loaderSelect, setLoaderSelect] = React.useState({profesional: false});

        useEffect(()=>{
            GetProfesionalList();
        },[])

    async function GetProfesionalList(){
        try{
            setLoaderSelect({profesional: true});
            const {status, data} = await GetProfesionals({});
            if (status === 200){
                const RSUserList = data?.results?.map(item=>{return {value: item.id, label: `${item.apellido}, ${item.nombre}`}}) ?? [];
                setProfesionalList(RSUserList);
            }
        }catch(err: any){
            MessageNotification(err.message, "error");
        }finally{
             setLoaderSelect({profesional: false});
        }
    }
        
    function HandleCloseModal(){
       OpenModalSelected("close", `${modal === "create" ? "create" : "edit"}`);
    }

    function HandleSubmit(){
        SendValues();
    }

    return(
        <GeneralModalCrud 
            titleOfModal={titleOfModal}
            openModal={openModal} 
            HandleCloseModal={HandleCloseModal} 
            HandleSubmit={HandleSubmit}
            styles="cont-default-modal">
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <section style={{display: "flex"}}>
                    <KeyboardDatePicker
                        autoOk
                        className="size-date-picke-filter"
                        clearable
                        variant='inline'
                        format="dd/MM/yyyy"
                        margin="normal"
                        inputVariant="outlined"
                        placeholder='Fecha'
                        value={values.fecha}
                        error={errorNotification.fecha}
                        onChange={(date)=>HandleChangeInput(date,"fecha")}
                    />
                    {values.fecha !== null && (
                        <IconButton
                            style={{ marginTop: 10 }}
                            onClick={()=>setValues({...values, fecha: null})}
                        >
                            <CloseOutlinedIcon/>
                        </IconButton>
                    )}
                </section>
            </MuiPickersUtilsProvider>
            <ReactSelect
                placeholder={`${values.profesionalNombre ?? "Profesionales..."}`}
                options={profesionalList}
                isLoading={loaderSelect.profesional}
                isClearable={true}
                className={errorNotification.profesionalId ? "errorNotificationInput" : ""}
                onChange={(e)=>setValues({...values, profesionalId: e === null ? null : e.value, profesionalNombre: e === null ? null : e.label})}
                styles={{
                    control: (s)=>({...s, maxWidth: 300})
                }}
            />
            <TextField
                value={values.monto}
                label='Monto'
                type='number'
                error={errorNotification.monto}
                InputLabelProps={{shrink: true}}
                onChange={(e)=>HandleChangeInput(e,"monto")}
            />
            <TextArea
                value={values.descripcion} 
                placeholder="Descripción..."
                className={`commentReturn ${errorNotification.descripcion ? "errorNotificationInput" : ""}`}
                onChange={(e)=>HandleChangeInput(e,"descripcion")}
                style={{resize: "vertical", height: 120, maxHeight: 250}}
            />
        </GeneralModalCrud>
    )
}

export default NewModalAccreditation;