import { Tooltip } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ReactSelect from 'react-select';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Pagination from "@material-ui/lab/Pagination";
import React from "react";
import { useForm } from "react-hook-form";
import {
    Button,
    Card,
    Dimmer,
    Grid,
    Icon,
    Loader,
    Table,
} from "semantic-ui-react";
import api from "../../../../api";
import EditorText from "../../../QuillEditoText/EditorText";
import SelectForm from '../../../common/SelectForm';
import { toolbarConfig } from "../../../QuillEditoText/configs/toolbarConfig";
import { TypeRole } from "../../../../enums";
import "./InstructiveList.css"

export default function InstructiveList({ setDataToast }) {
	const { control } = useForm({});
	const loadForPage = 20;

	//#region VARIABLES DE ESTADO
    const [rolType, setRolType] = React.useState(null);
	const [text, setText] = React.useState('');
	const [page, setPage] = React.useState(1);
	const [loadingActive, setLoadingActive] = React.useState();
	const [totalCount, setTotalCount] = React.useState();
	const [listaOs, setListaOs] = React.useState();
	const [dataTable, setDataTable] = React.useState();
	const [filtrosBusqueda, setFiltrosBusqueda] = React.useState({
		obraSocial: null,
		dadasDeBaja: false,
	});
	const [errors, setErrors] = React.useState({
		socialwork: '',
	});
	const [modalInstructive, setModalInstructive] = React.useState({
		showModal: false,
		aditionalInfo: {
			socialWorkId: null,
			socialWorkName: null,
			socialWorkAlias: null
		}
	});
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 680);
	//#endregion

	React.useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 680);
        };
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

	React.useEffect(() => {
        setRolType(JSON.parse(localStorage.getItem("TypeRole")));
		onClickViewList(page);
	}, []);

	//#region Asigar Obra social / editar / dar de baja
	function FiltroListaOs(isDeleted = false){
		let params = {
			dadasDeBaja: isDeleted,
		}
		api
		.GetSocialWorkForSelect(params)
		.then(res=>{
			let listOS = res.data.map(item=>{
				return {
						value: item.id,
						label: item['nombre']
					}
			});
			setListaOs(null);
			setListaOs(listOS);
		})
		.catch(err=>{
			MessageNotification(err.response.data.errores,"error")
		})
	}
	function onClickViewList(nroPage = 1) {
		setLoadingActive(true);
		let params = {
			obraSocialId: filtrosBusqueda.obraSocial?.value,
			pagina: nroPage,
			cantidad: loadForPage,
		}
		api
			.getSocialWorks(params)
			.then((res) => {
				setTotalCount(res.data.cantidadPaginas);
				let list = res.data.resultados.map(item=>{
					return {
						id: item.id,
						nombre: item['nombre'],
						alias: item.alias,
                        instructivo: CheckHasInstructive(item.instructivo)
					}
				});
				setDataTable(null);
				setDataTable(list);
				setLoadingActive(false);
			})

			.catch((err) => {
				MessageNotification(err.response.data.errores, "error");
				setLoadingActive(false);
			})
			.finally(()=>{
				FiltroListaOs();
			});
	}
    function CheckHasInstructive(instructive){
        let instructiveContent = null;
        //El instructivo puede venir NULL en caso de que nunca se asigno/edito
        //El instructivo si se edito y se guardo vacio, no es un "" sino es un "<p><br/></p>" por ende no se considera ni "" ni null, asique hay que validar el contenido de esa etiqueta
        //para mostrar o no el boton de instructivo. En este bloque se parsea lo que viene de la API para convertir a una etiqueta <p> y contemplar su contenido.
        if (instructive !== null){
        const DOMparser = new DOMParser().parseFromString(instructive, "text/html");
        const tag= DOMparser.body.firstChild;
    
        if (tag.innerText !== "")  instructiveContent = instructive;
        }
        return instructiveContent;
    }
	function GetInstructiveById(socialWorkId, socialWorkName, socialWorkAlias){
		setLoadingActive(true);
		api.getInstructiveById(socialWorkId)
		.then(resp=>{
			setText(resp.data);
			setModalInstructive({
			showModal: true,
			aditionalInfo: {
				socialWorkId,
				socialWorkName,
				socialWorkAlias
				}
			})
		})
		.catch(err =>{
			MessageNotification(err.response.data.errores, "error");
		})
		.finally(()=> setLoadingActive(false))
	}
	//#endregion

	//#region metodos UTILIDAD
	function MessageNotification(message, type){
		setDataToast({
				active: true,
				message: message || "acaba de ocurrir un error",
				type: type,
			});
	}
	
	function handleChange(event, value){
		setPage(value);
		onClickViewList(value);
	}
	function handleCloseDeleteModalInstructive(){
		setModalInstructive({
			showModal: false,
			aditionalInfo: {
				socialWorkId: null,
				socialWorkName: null,
				socialWorkPlan: null
			}
		});
	}
    function HandleOnChangeText(text){
        setText(text);
    }
	//#endregion

	return (
        <>
            <Grid>
                <Card className='color-border-dash'>
                    <CardContent className='center-responsive-filters'>
                        <Grid.Row className='header-section'>
                            <div className="titleAndVideo">
                                <span style={{ fontSize: 27, margin: 8}}>Instructivos</span>
                            </div>
                        </Grid.Row>
                        <Grid.Row className="content-filter-list-nuevo">
                            <ReactSelect
                                className="filter-input"
                                options={listaOs}
                                placeholder="Obras sociales..."
                                isClearable={true}
                                value={filtrosBusqueda.obraSocial}
                                onChange={(e) => setFiltrosBusqueda({
                                    obraSocial: e
                                })}
                                styles={{
                                    container: (provided) => ({ ...provided, minWidth: isMobile ? '251px' : '220px' }),
                                }}
                            />
                            {isMobile
                                ? <Button primary onClick={() => onClickViewList()}>
                                    <Icon name='search' style={{margin: 0}} />
                                </Button>
                                : <Button
                                    // className='btn-search-filter'
                                    primary
                                    icon
                                    labelPosition='left'
                                    onClick={() => onClickViewList()}
                                >
                                    <Icon name='search' />
                                    Buscar
                                </Button>
                            }
                        </Grid.Row>
                    </CardContent>
                </Card>
               
                {loadingActive ? (
                    <Dimmer className='loader-fixed' active inverted>
                        <Loader size='big'>Cargando..</Loader>
                    </Dimmer>
                ) : (
                    dataTable &&
                    dataTable.length > 0 && (
                        <Grid.Row>
                            <div className="table-container">
                                <table className="table">
                                    <thead className="table-header">
                                        <tr>
                                            {isMobile && 
                                                <th className={`table-cell-head ${isMobile ? "mobile-ops" : ""}`}>
                                                    <b>Opc...</b>
                                                </th>
                                            }
                                            <th className={`table-cell-head ${isMobile ? "mobile-social" : ""}`}>
                                                <b>Obra Social</b>
                                            </th>
                                            <th className={`table-cell-head ${isMobile ? "mobile-alias" : ""}`}>
                                                <b>Alias</b>
                                            </th>
                                            {!isMobile && 
                                                <th className="table-cell-head"><b>Opciones</b></th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataTable.map((socialWork) => (
                                            <tr key={socialWork.id} className="table-row">
                                                { isMobile &&
                                                    <td className="table-cell">
                                                        <IconButton
                                                            className={`${TypeRole.Professional ? 'btn-circle-blue' : 'btn-circle-green'} ${socialWork.instructivo === null && 'btn-circle-disabled'}`}
                                                            onClick={() => GetInstructiveById(socialWork.id, socialWork.nombre, socialWork.alias)}
                                                            disabled={socialWork.instructivo === null}
                                                        >
                                                            <Tooltip placement="top" title="Acción">
                                                                <MenuBookIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </td>
                                                }
                                                <td className="table-cell">{socialWork.nombre}</td>
                                                <td className="table-cell">{socialWork.alias ?? "-"}</td>
                                                { !isMobile &&
                                                    <td className="table-cell">
                                                        <IconButton
                                                            className={`${TypeRole.Professional ? 'btn-circle-blue' : 'btn-circle-green'} ${socialWork.instructivo === null && 'btn-circle-disabled'}`}
                                                            onClick={() => GetInstructiveById(socialWork.id, socialWork.nombre, socialWork.alias)}
                                                            disabled={socialWork.instructivo === null}
                                                        >
                                                            <Tooltip placement="top" title="Acción">
                                                                <MenuBookIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </td>
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Grid.Row>
                    )
                )}
                {dataTable && dataTable.length === 0 && (
                    <Grid.Row style={{ textAlign: '-webkit-center' }}>
                        <TableContainer component={Paper} id='color-letter-table-tasks'>
                            <TableCell>
                                <h5>No se encontraron resultados</h5>
                            </TableCell>
                        </TableContainer>
                    </Grid.Row>
                )}
                {dataTable && (
                    <Grid.Row centered>
                        <Pagination
                            className='pagination'
                            count={totalCount}
                            page={page}
                            onChange={handleChange}
                        />
                    </Grid.Row>
                )}
            </Grid>

            <Dialog open={modalInstructive.showModal} onClose={handleCloseDeleteModalInstructive}>
                        <DialogTitle id='form-dialog-title'>
                          Instructivo / {modalInstructive.aditionalInfo.socialWorkName} - {modalInstructive.aditionalInfo.socialWorkAlias}
                        </DialogTitle>
                        <DialogContent>
                            <EditorText 
                            handleOnChange={HandleOnChangeText} 
                            text={text} 
                            toolbarOptions={toolbarConfig} 
                            isReadOnly={true}
                            placeholder={text === null || text === "" ? "Instructivo no asignado" : text}
                            />
                        </DialogContent>
                        <DialogActions style={{display: "flex", justifyContent: "center"}} >
                            <Button color='gray' onClick={handleCloseDeleteModalInstructive}>
                                Cerrar
                            </Button>
                        </DialogActions>
            </Dialog>
        </>
	)
}
