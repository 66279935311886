import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import moment from 'moment';
import React from 'react';
import { StatusCode } from '../../../../enums';
import { UpdateInstallmentSelected } from '../../../../services/services/CrudInstallment';
import { FormatNumber } from '../../../utils/FormatInputs';
import GeneralModalShow from '../../../utils/GeneralModalShow';
import GeneralSimpleList from '../../../utils/GeneralSimpleList';

const customStyles = makeStyles(() => ({
    btnDisabled: {
        borderRadius: 6,
        margin: 4,
        backgroundColor: "#dbdada",
    },
    btnDebit: {
        borderRadius: 6,
        margin: 4,
        backgroundColor: "#5db25d",
        color: "#ffff",
        "&:hover": {
            backgroundColor: "#5db25d"
        }
    },
    containerBtn: {
        display: "flex",
        justifyContent: "end"
    }
}));

const PrevDebitModalInstallment: React.FC<any> = ({
    dataToRender,
    open,
    paginated,
    OpenModalSelected,
    ReloadData,
    MessageNotification
}) => {
    const classStyle = customStyles();
    const [isLoaderActive, setIsLoaderActive] = React.useState(false);
    const [state, setState] = React.useState<any>(() => {
        let obj = {}
        dataToRender.cuotas.forEach(item => {
            if (item.fechaPago === null) obj[item.id] = false
        });
        return { ...obj, all: false };
    });

    async function UpdateInstallment() {
        try {
            setIsLoaderActive(true);
            const listOfInstallment: number[] = Object.entries(state).filter(item => {
                if (item[1] === true) return +item[0]
            }).map(item => +item[0]);
            const params: any = { cuotas: listOfInstallment }
            const { status } = await UpdateInstallmentSelected(dataToRender.id, params);
            if (status === StatusCode.Ok) {
                MessageNotification("Cuotas saldadas correctamente.", "success");
                HandleCloseModalList();
                ReloadData();
            }
        } catch (err: any) {
            MessageNotification(err.message, "error");
        } finally {
            setIsLoaderActive(false);
        }
    }

    function HandleChangeState(e, item) {
        if (item.fechaPago !== null || item.fechaDeBaja !== null) {
            MessageNotification("No se puede seleccionar la cuota si se encuentra abonada o está dada de baja.", "error");
            return;
        }

        setState({ ...state, [item.id]: e.target.checked });
    }
    function HandleCloseModalList() {
        OpenModalSelected("close", "debitManually", dataToRender);
    }
    function HandleCheckAllItems() {
        const allItemChecked = {};
        if (dataToRender.cuotas.some(item => item.fechaPago !== null || item.fechaDeBaja !== null)) {
            MessageNotification("No se pueden seleccionar todas las cuotas si se encuentran abonadas o están dadas de baja.", "error");
            return;
        }
        const validateItem = Object.values(state).every(item => item === true);

        dataToRender.cuotas.forEach(item => {
            if (item.fechaPago === null) {
                allItemChecked[item.id] = !validateItem;
            }
        });
        setState({ ...state, ...allItemChecked, all: !validateItem });
    }
    return (
        <GeneralModalShow
            title={`Cuotas - ${dataToRender.profesionalNombre}`}
            openModal={open}
            HandleClose={HandleCloseModalList}
        >
            <div className={classStyle.containerBtn}>
                <Button
                    disabled={!Object.values(state).some(item => item === true)}
                    className={!Object.values(state).some(item => item === true) ? classStyle.btnDisabled : classStyle.btnDebit}
                    startIcon={<AttachMoneyOutlinedIcon />}
                    onClick={UpdateInstallment}>
                    Saldar
                </Button>
            </div>
            <GeneralSimpleList
                isLoaderActive={isLoaderActive}
                dataToRender={dataToRender}
                paginated={paginated}
            >
                <Table aria-label='spanning table'>
                    <TableHead>
                        <TableRow>
                            <TableCell className='letter-title-table c-text-center'>
                                <Checkbox
                                    disabled={dataToRender.cuotas.every(item => item.fechaPago !== null || item.fechaDeBaja !== null)}
                                    checked={state['all']}
                                    onChange={HandleCheckAllItems} />
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center'>
                                <b>Número</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center'>
                                <b>Fecha</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center'>
                                <b>Importe</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center'>
                                <b>Saldada</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataToRender.cuotas.map((item: any) => (
                            <TableRow key={item.id} className={`${item.fechaDeBaja ? "rowDeleted" : ""}`}>
                                <TableCell className='letter-title-table c-text-center'>
                                    <Checkbox
                                        disabled={(item.fechaPago !== null || item.fechaDeBaja !== null)}
                                        checked={state[item.id]}
                                        onChange={(e) => HandleChangeState(e, item)} />
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center'>
                                    {item.numero}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center'>
                                    {moment(item.fecha).format('DD/MM/YYYY')}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-right'>
                                    {FormatNumber({ style: 'currency', currency: '$', value: item.importe })}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center'>
                                    <Checkbox checked={Boolean(item.fechaPago) || item.liquidacionId} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </GeneralSimpleList>
        </GeneralModalShow>
    )
}

export default PrevDebitModalInstallment;