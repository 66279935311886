import { Tooltip } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReactSelect from 'react-select';
import TextField from '@material-ui/core/TextField';
import CachedIcon from '@material-ui/icons/Cached';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination from "@material-ui/lab/Pagination";
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	Button,
	Card,
	Dimmer,
	Grid,
	Icon,
	Loader,
	Table
} from 'semantic-ui-react';
import api from '../../../../api';
import { Options } from '../../../../enums';

export default function AssignPracticeForOs({ setDataToast }) {
	const { idOs } = useParams();
	const redirect = useHistory();
	//#region VARIABLES DE ESTADO
	const [loadingActive, setLoadingActive] = useState(false);
	const [assignPracticeList, setAssignPracticeList] = useState([{}]);
	const [notAssignPracticeList, setNotassignPracticeList] = useState([{}]);
	const [plansList, setPlansList] = useState([]);
	const [openNew, setOpenNew] = useState(false);
	const [isCreate, setCreate] = useState(false);
	const [isEdit, setEdit] = useState(false);
	const [isDelete, setDelete] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [quantityForPage, setQuantityForPage] = useState();
	const loadForPage = 20;
	const [page, setPage] = useState(1);
	const [reactivate, setReactivate] = useState({
		iconReactivate: false,
		modalReactivate: false
	});
	const [socialWork, setSocialWork] = useState({
		nombre: "",
		alias: "",
		esObraSocialColegio: false
	});
	const [objFilterData, setObjFilterData] = useState({
		dadasDeBaja: false,
		nombre: null,
		dadasDeBajaChecked: false,
	});
	const [openDelete, setOpenDelete] = useState({
		mostrar: false,
		id: null,
		nombre: null,
		alias: null,
		codigo: null,
		aliasReporte: null,
		planId: null
	});
	const [newValue, setNewValue] = useState({
		id: null,
		nombre: null,
		idPlan: null,
		nombrePlan: null,
		alias: null,
		aliasReporte: null,
		identificadorExterno: null
	});
	const [editValues, setEditValues] = useState({
		id: null,
		alias: null,
		nombre: null,
		aliasReporte: null,
		idPlan: null,
		nombrePlan: null,
		identificadorExterno: null,
	})
	const [selectedPlan, setSelectedPlan] = useState(null);
	//#endregion

	useEffect(() => {
		setLoadingActive(true);
		let optionsCrud = JSON.parse(localStorage.getItem('optionCruds'));
		optionsCrud.forEach((element) => {
			if (element == Options.isCreate) {
				setCreate(true);
			} else if (element == Options.isEdit) {
				setEdit(true);
			} else if (element == Options.isDelete) {
				setDelete(true);
			}
		});
		LoadData();
		GetSocialWorkById();
		GetPlansBySocialWorkPlansAccounts();
	}, []);

	useEffect(() => {
    // Si solo hay un plan, lo preseleccionamos
    if (plansList.length === 1) {
      setSelectedPlan(plansList[0]);
    }
  }, [plansList]);

	const LoadData = async (pageSelected = 1, assign = true) => {
		try {
			setLoadingActive(true);
			//si es la primera vez que se entra a la vista, entra en este if
			if (assign) {
				let { data } = await api.getPracticesBySocialWorkAccount(+idOs, null, objFilterData.dadasDeBaja, pageSelected, loadForPage);
				let list = data.resultados.map(item => {
					return {
						id: item.id,
						practicaid: item.practicaId,
						practicaCodigo: item.practicaCodigo,
						practicaNombre: item.practicaNombre,
						alias: item.alias,
						aliasReporte: item.aliasReporte,
						obraSocial: {
							id: item.obraSocial.id,
							nombre: item.obraSocial.nombre
						},
						planObraSocial: {
							id: item.planObraSocial?.id,
							nombre: item.planObraSocial?.nombre
						},
						identificadorExterno: item.identificadorExterno
					}
				});
				setQuantityForPage(data.cantidadPaginas);
				setAssignPracticeList(list);
				setReactivate({
					modalReactivate: false,
					iconReactivate: false
				});
				if (objFilterData.dadasDeBaja) {
					setReactivate({
						modalReactivate: false,
						iconReactivate: true
					});
				} else {
					setObjFilterData({ ...objFilterData, dadasDeBaja: false })
				}
			} else { //sino, es xq se apreto en el boton de Asignar y entra acá
				let params = {
					obraSocialId: +idOs,
					removed: false,
					page: 1,
					quantity: 20,
					withPaginate: false
				};
				let { data } = await api.GetAllPractices(params);
				let list = data.resultados.map(item => {
					return {
						value: item.id,
						label: item["nombre"],
					}
				});
				setNotassignPracticeList(list);
			}
		}
		catch (err) {
			HandleErrorOrSuccess('error', err.response.data.errores ?? "Ocurrió un error.");
		}
		finally {
			setLoadingActive(false);
		}
	}
	const OnClickAssign = async () => {
		setLoadingActive(true);
		try {
			let isValidated = checkInputs(newValue.id, selectedPlan?.value, newValue.alias, newValue.aliasReporte);

			if (isValidated) {
				let bodyParams = {
					practicaObraSocialAlias: newValue.alias,
					practicaReporteAlias: newValue.aliasReporte,
					identificadorExterno: newValue.identificadorExterno
				}

				let query = await api.AssignPracticeForSWAccount(+idOs, selectedPlan.value, newValue.id, bodyParams);

				if (query.status === 200 && query.data != null) {
					HandleErrorOrSuccess('success', "Práctica Asociada correctamente.");

					setOpenNew(false);
					setNewValue({	id: null,	nombre: null,	alias: null, aliasReporte: null, identificadorExterno: null	});
					setSelectedPlan(null);
					LoadData();
				}
			}
		} catch (err) {
			HandleErrorOrSuccess('error', err.response.data.errores ?? "Ocurrió un error.");
		}
		finally {
			setLoadingActive(false);
		}
	}
	const OnClickEdit = async () => {
		setOpenEdit(false);
		try {
			let isValidated = checkInputs(editValues.id, editValues.idPlan, editValues.alias, editValues.aliasReporte);
			if (isValidated) {
				setLoadingActive(true);
				let bodyParams = {
					practicaObraSocialAlias: editValues.alias,
					practicaReporteAlias: editValues.aliasReporte,
					planObraSocialId: editValues.idPlan,
					identificadorExterno: editValues.identificadorExterno
				};
				await api.UpdatePracticeSWAccount(editValues.id, bodyParams);
				
				HandleErrorOrSuccess('success', "Práctica editada correctamente.");
			}
		} catch (err) {
			HandleErrorOrSuccess('error', err.response.data.errores ?? "Ocurrió un error.");
		}
		finally {
			setLoadingActive(false);
			setEditValues({
				id: null,
				alias: null,
				nombre: null,
				aliasReporte: null,
				idPlan: null,
				nombrePlan: null,
				identificadorExterno: null
			});
			LoadData();
		}
	}
	const OnClickDelete = async () => {
		setLoadingActive(true);
		try {
			if (openDelete.planId === null || openDelete.planId === undefined) {
				HandleErrorOrSuccess('error', `No se puede borrar la practica si no tiene un Plan asignado.`);
				return;
			}
			await api.DeleteAssignedPractice(openDelete.id);
			
			HandleErrorOrSuccess('success', "Práctica dada de baja correctamente.");
		} catch (err) {
			HandleErrorOrSuccess('error', err.response.data.errores ?? "Ocurrió un error.");
		}
		finally {
			setOpenDelete({
				mostrar: false,
				id: null,
				nombreOAlias: null,
				codigo: null,
				planId: null,
			});
			setLoadingActive(false);
			LoadData();
		}
	}
	//#region UTILIDADES
	const GetSocialWorkById = async () => {
		try {
			setLoadingActive(true);
			let { data } = await api.getSocialWorkById(+idOs);
			setSocialWork({
				nombre: data.nombre,
				alias: data.alias,
				esObraSocialColegio: data.esObraSocialColegio
			});
		} catch (err) {
			HandleErrorOrSuccess("error", err.response.data.errores ?? "Ocurrió un error.");
		}
		finally {
			LoadData();
		}
	}
	const checkInputs = (practiceId, planId, alias, aliasReport, isDeleted = false) => {
		if (practiceId === null || planId === null || planId === undefined) {
			HandleErrorOrSuccess('error', `Los campos "Practica" y "Plan" no pueden estar vacíos.`);
			return false
		}
		return true;
	}
	const HandleErrorOrSuccess = (type, message) => {
		setDataToast({
			active: true,
			type: type,
			message: message
		});
	}
	const PreDelete = (idPractice, name, code, alias, aliasReporte, socialWorkPlanId) => {
		setOpenDelete({
			id: idPractice,
			nombre: name,
			alias: alias,
			aliasReporte: aliasReporte,
			mostrar: true,
			codigo: code,
			planId: socialWorkPlanId
		});
	}
	const HandleOpenEditOrReactivate = (practiceSOAccount, reactivatePractice = false) => {
		setEditValues({
			id: practiceSOAccount.id,
			alias: practiceSOAccount.alias,
			nombre: practiceSOAccount.name,
			aliasReporte: practiceSOAccount.aliasReporte,
			idPlan: practiceSOAccount.planObraSocial.id,
			nombrePlan: practiceSOAccount.planObraSocial.nombre,
			identificadorExterno: practiceSOAccount.identificadorExterno
		});

		if (reactivatePractice) {
			setReactivate({
				...reactivate,
				modalReactivate: true
			});
		} else {
			setOpenEdit(true);
		}
	}

	async function GetPlansBySocialWorkPlansAccounts() {
		try {
			setLoadingActive(true);
			const plansData = await api.GetPlansBySocialWorkPlansAccounts(+idOs, false);

			let planList = plansData.data.map(plan => {
				return { value: plan.id, label: plan["nombre"] }
			});

			setPlansList(planList);
		}
		catch (err) {
			HandleErrorOrSuccess('error', err?.response?.data?.errores ?? "Ocurrió un error.");
		}
		finally {
			setLoadingActive(false);
		}
	}

	async function HandleReactivatePractice() {
		try {
			setLoadingActive(true);
			await api.UpdatePracticeForSWAccount(+editValues.id);
			
			setReactivate({
				...reactivate,
				modalReactivate: false
			});
			HandleErrorOrSuccess("success", "Practica reactivada correctamente.");
			LoadData();
		} catch (err) {
			HandleErrorOrSuccess('error', err?.response?.data?.errores ?? "Ocurrió un error.");
		} finally {
			setLoadingActive(false);
		}
	}
	const handleChange = (event, value) => {
		setPage(value);
		LoadData(value);
	};
	const HandleCloseModal = () => {
		setOpenEdit(false);
		setReactivate({
			...reactivate,
			modalReactivate: false
		});
	}
	const HandleClickOpen = () => {
		LoadData(page, false)
			.finally(() => {
				setOpenNew(true)
			});
	}
	const HandleCloseNew = () => {
		setOpenNew(false);
		if(plansList.length > 1) {
			setSelectedPlan(null);
		}
	}
	const HandleCloseDelete = () => {
		setOpenDelete({ ...openDelete, mostrar: false });
	}
	//#endregion

	return (<>
		<Grid>
			<Grid.Row>
				<Card className='color-border-dash'>
					<CardContent className='center-responsive-filters'>
						<Grid.Row className="header-section">
							<div className="titleAndVideo">
								<span style={{ fontSize: 27 }}>{socialWork.alias !== null ? `${socialWork.alias} - ` : `${socialWork.nombre} - `} Prácticas</span>
							</div>
							<div className="contBtnHeaderSection">
								<Button
									className='btn-back-filter'
									onClick={() => redirect.push('/configuraciones/obras-sociales/asignar')}
								>
									Volver
								</Button>
								{isCreate &&
									<Button
										className='btn-new-filter'
										disabled={socialWork.esObraSocialColegio}
										onClick={() => HandleClickOpen()}
										icon
										labelPosition='left'
									>
										<Icon name='plus' />
										Asignar
									</Button>}
							</div>
						</Grid.Row>
						<Grid.Row className="content-filter-list">
							<FormControlLabel
								control={
									<Checkbox
										checked={objFilterData.dadasDeBajaChecked}
										onChange={(e) => {
											setObjFilterData({
												dadasDeBaja: e.target.checked,
												dadasDeBajaChecked: e.target.checked,
											});
										}}
									/>
								}
								label='Dadas de baja'
							/>
						</Grid.Row>
						<Button
							className='btn-search-filter'
							primary
							icon
							labelPosition='left'
							onClick={() => LoadData()}
							type='submit'
						>
							<Icon name='search' />
							Buscar
						</Button>
					</CardContent>
				</Card>
			</Grid.Row>
			{loadingActive ? (
				<Dimmer className='loader-fixed' active inverted>
					<Loader size='big'>Cargando..</Loader>
				</Dimmer>
			) : (
				assignPracticeList &&
				assignPracticeList.length > 0 && (
					<Grid.Row>
						<TableContainer component={Paper} id='color-letter-table-tasks'>
							<Table aria-label='spanning table'>
								<TableHead colSpan={6} className='color-letter-inTable'>
									<TableRow>
										<TableCell className='letter-title-table c-text-center' style={{ textAlign: "center" }}>
											<b>Nombre</b>
										</TableCell>
										<TableCell className='letter-title-table c-text-center' style={{ textAlign: "center" }}>
											<b>Alias</b>
										</TableCell>
										<TableCell className='letter-title-table c-text-center' style={{ textAlign: "center" }}>
											<b>Alias Reporte</b>
										</TableCell>
										<TableCell className='letter-title-table c-text-center' style={{ textAlign: "center" }}>
											<b>Codigo</b>
										</TableCell>
										<TableCell className='letter-title-table c-text-center' style={{ textAlign: "center" }}>
											<b>Obra Social</b>
										</TableCell>
										<TableCell className='letter-title-table c-text-center' style={{ textAlign: "center" }}>
											<b>Plan</b>
										</TableCell>
										<TableCell className='letter-title-table c-text-center' style={{ textAlign: "center" }}>
											<b>Opciones</b>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{assignPracticeList.map(practiceSOAccount => (
										<TableRow key={practiceSOAccount.id}>
											<TableCell className='c-text-center' style={{ textAlign: "center" }}>
												{practiceSOAccount.practicaNombre}
											</TableCell>
											<TableCell className='c-text-center' style={{ textAlign: "center" }}>
												{practiceSOAccount.alias ?? "--"}
											</TableCell>
											<TableCell className='c-text-center' style={{ textAlign: "center" }}>
												{practiceSOAccount.aliasReporte ?? "--"}
											</TableCell>
											<TableCell className='c-text-center' style={{ textAlign: "center" }}>
												<p style={{ padding: 10 }}>{practiceSOAccount.practicaCodigo ?? "No Asignado"}</p>
											</TableCell>
											<TableCell className='c-text-center' style={{ textAlign: "center" }}>
												<p style={{ padding: 10 }}>{practiceSOAccount.obraSocial?.nombre}</p>
											</TableCell>
											<TableCell className='c-text-center' style={{ textAlign: "center" }}>
												<p style={{ padding: 10 }}>{practiceSOAccount.planObraSocial?.nombre ?? "--"}</p>
											</TableCell>
											<TableCell className='c-text-center' style={{ textAlign: "center" }}>
												{reactivate.iconReactivate ?
													<>
														<IconButton
															className='btn-circle-green'
															onClick={() => HandleOpenEditOrReactivate(practiceSOAccount, true)}
														>
															<Tooltip placement='top' title="Reactivar">
																<CachedIcon />
															</Tooltip>
														</IconButton>
													</>
													:
													<>
														{isEdit && 
															<IconButton
																className='btn-circle-orange'
																onClick={() => HandleOpenEditOrReactivate(practiceSOAccount, false)}
															>
																<Tooltip placement='top' title="Editar">
																	<CreateIcon />
																</Tooltip>
															</IconButton>
														}
														{isDelete && (
															<IconButton
																className='btn-circle-red'
																onClick={() =>
																	PreDelete(practiceSOAccount.id, practiceSOAccount.practicaNombre, practiceSOAccount.practicaCodigo, practiceSOAccount.alias, practiceSOAccount.aliasReporte, practiceSOAccount.planObraSocial?.id)
																}
															>
																<Tooltip placement='top' title="Eliminar">
																	<DeleteIcon />
																</Tooltip>
															</IconButton>
														)}
													</>}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid.Row>
				)
			)}
			{assignPracticeList && assignPracticeList.length === 0 && (
				<Grid.Row style={{ textAlign: '-webkit-center' }}>
					<TableContainer component={Paper} id='color-letter-table-tasks'>
						<TableCell>
							<h5>No se encontraron resultados</h5>
						</TableCell>
					</TableContainer>
				</Grid.Row>
			)}
			{assignPracticeList && (
				<Grid.Row centered>
					<Pagination
						color="primary"
						className="pagination"
						count={quantityForPage}
						page={page}
						onChange={handleChange}
					/>
				</Grid.Row>
			)}
		</Grid>

		<Dialog
			open={openNew}
			onClose={HandleCloseNew}
			aria-labelledby='form-dialog-title'
		>
			<DialogTitle id='form-dialog-title'>Asignar</DialogTitle>
			<DialogContent className='cont-default-modal contModalAmount'>
				<div style={{ position: "relative" }}>
					<label htmlFor="my-select" className="customLabelSelect">Prácticas:</label>
					<ReactSelect
						placeholder="Prácticas..."
						options={notAssignPracticeList}
						onChange={(e) => setNewValue({
							...newValue,
							id: e !== null ? e.value : null,
							nombre: e !== null ? e.label : null,
						})}
						styles={{
							control: (s) => ({ ...s, maxWidth: 300 }),
							menu: (provided) => ({ ...provided, zIndex: 20 })
						}}
					/>
				</div>
				<div style={{ position: "relative", marginTop: 10 }}>
					<label htmlFor="my-select" className="customLabelSelect">Planes:</label>
					<ReactSelect
						placeholder="Planes..."
						options={plansList}
						onChange={(e) =>
							setSelectedPlan({
								value: e?.value || null,
								label: e?.label || "",
							})
						}
						styles={{
							control: (s) => ({ ...s, maxWidth: 300 })
						}}
						value={selectedPlan}
					/>
				</div>
				<TextField
					size='small'
					id='alias'
					label='Alias Reporte'
					style={{ position: "relative" }}
					value={newValue.aliasReporte}
					onChange={(e) => {
						setNewValue({
							...newValue,
							aliasReporte: e.target.value === "" ? null : e.target.value
						});
					}}
				/>
				<TextField
					size='small'
					id='alias'
					label='Alias'
					value={newValue.alias}
					onChange={(e) => {
						setNewValue({
							...newValue,
							alias: e.target.value === "" ? null : e.target.value
						});
					}}
				/>
				<TextField
					size='small'
					label='Identificador Externo'
					value={newValue.identificadorExterno}
					onChange={(e) => {
						setNewValue({
							...newValue,
							identificadorExterno: e.target.value === "" ? null : e.target.value
						});
					}}
				/>
			</DialogContent>

			<DialogActions style={{ textAlign: 'center', justifyContent: 'center' }}>
				<Button onClick={HandleCloseNew}>
					Cancelar
				</Button>
				<Button onClick={OnClickAssign} color='green'>
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>

		{openEdit && (
			<Dialog
				open={openEdit}
				onClose={HandleCloseModal}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Editar</DialogTitle>
				<DialogContent>
					<div style={{ position: "relative", marginTop: 10 }}>
						<label htmlFor="my-select" className="customLabelSelect">Planes:</label>
						<ReactSelect
							isDisabled={socialWork.esObraSocialColegio}
							placeholder="Planes..."
							options={plansList}
							value={plansList.find(x => x.value === editValues?.idPlan) ?? null}
							onChange={(e) => setEditValues(prevState => ({ ...prevState, idPlan: e?.value, nombrePlan: e?.label }))}
							styles={{
								control: (s) => ({ ...s, maxWidth: 300 })
							}}
						/>
					</div>
					<TextField
						style={{ margin: "10px 0" }}
						size='small'
						id='nombre'
						label='Alias'
						value={editValues.alias}
						onChange={(e) => {
							setEditValues({
								...editValues,
								alias: e.target.value === "" ? null : e.target.value,
							});
						}}
					/>
					<TextField
						style={{ margin: "10px 0" }}
						size='small'
						id='nombre'
						label='Alias Reporte'
						value={editValues.aliasReporte}
						onChange={(e) => {
							setEditValues({
								...editValues,
								aliasReporte: e.target.value === "" ? null : e.target.value,
							});
						}}
					/>
					<TextField
						size='small'
						label='Identificador Externo'
						value={editValues.identificadorExterno}
						onChange={(e) => {
							setEditValues({
								...editValues,
								identificadorExterno: e.target.value === "" ? null : e.target.value
							});
						}}
					/>
				</DialogContent>
				<DialogActions
					style={{
						textAlign: 'center',
						justifyContent: 'center',
					}}
				>
					<Button onClick={() => HandleCloseModal()}>
						Cancelar
					</Button>

					<Button onClick={OnClickEdit} color='green'>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		)}

		{openDelete.mostrar &&
			<Dialog open={openDelete.mostrar} onClose={HandleCloseDelete}>
				<DialogTitle id='form-dialog-title'>Borrar Registro</DialogTitle>
				<DialogContent>
					<Grid.Row>
						<DialogContentText className='c-text-center'>
							¿Está seguro de eliminar la siguiente Práctica: {openDelete.nombre}?
						</DialogContentText>
					</Grid.Row>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleCloseDelete()}>Cancelar</Button>
					<Button
						onClick={() => OnClickDelete()}
						color='red'
					>
						Eliminar
					</Button>
				</DialogActions>
			</Dialog>
		}

		{reactivate.modalReactivate &&
			<Dialog open={reactivate.modalReactivate} onClose={HandleCloseModal}>
				<DialogTitle id='form-dialog-title'>Reactivar Registro</DialogTitle>
				<DialogContent>
					<DialogContentText>
						¿Está seguro de reactivar la siguiente Práctica: {editValues.nombre}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => HandleCloseModal()}>Cancelar</Button>
					<Button
						onClick={HandleReactivatePractice}
						color='green'
					>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		}
	</>)
}