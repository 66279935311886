import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect } from 'react';
import ReactSelect from 'react-select';
import { Button, Icon } from "semantic-ui-react";
import { IInstallment, IInstallmentGetParams, IInstallmentRequest, typeOfError } from "../../api/Interfaces/managment/ICrudInstallment";
import { ICrudInstallment } from "../../services/interfaces/Managment/ICrudInstallment";
import { selectTypeFormat, typeOfActionForModal, typeOfModal } from "../../services/interfaces/utils/IUtilDeclaration";
import { CreateInstallment, DeleteInstallment, EditInstallment, GetInstallments } from "../../services/services/CrudInstallment";
import { GetProfesionals } from "../../services/services/Users";
import ButtonBox from '../utils/ButtonBox';
import GeneralList from '../utils/GeneralList';
import GeneralModalDelete from "../utils/GeneralModalDelete";
import EditModalInstallment from './modals/CrudInstallment/EditModalInstallment';
import NewModalInstallment from "./modals/CrudInstallment/NewModalInstallment";
import GeneralModalShow from '../utils/GeneralModalShow';
import PrevDebitModalInstallment from './modals/CrudInstallment/PrevDebitModalInstallment';
import { _DEFAULT_MODAL_VALUES } from '../configurations/SocialWork/defaultValues';
import { _DEFAULT_VALUES_INSTALLMENT } from './defaultValues';
import { useSelector } from 'react-redux';
import { FormatNumber } from '../utils/FormatInputs';

const CrudInstallment: React.FC<ICrudInstallment> = ({ setDataToast }) => {
    const hasManagment = useSelector<any>(({ managment }) => managment.hasManagment);
    const [isLoaderActive, setIsLoaderActive] = React.useState(false);
    const [loaderSelect, setLoaderSelect] = React.useState({ profesional: false });
    const [page, setPage] = React.useState(1);
    const [pagesToRender, setPagesToRender] = React.useState(1);
    const [dataToRender, setDataToRender] = React.useState<IInstallment[]>([]);
    const [values, setValues] = React.useState<IInstallmentRequest>(_DEFAULT_VALUES_INSTALLMENT.values);
    const [errorNotification, setErrorNotificacion] = React.useState<typeOfError>(_DEFAULT_VALUES_INSTALLMENT.errorNotification);
    const [filterObj, setFilterObj] = React.useState<IInstallmentGetParams>(_DEFAULT_VALUES_INSTALLMENT.filterObj);
    const [openModal, setOpenModal] = React.useState<any>(_DEFAULT_VALUES_INSTALLMENT.modals);
    const [profesionalList, setProfesionalList] = React.useState<selectTypeFormat[]>([]);

    useEffect(() => {
        (hasManagment && GetAllPromises());
    }, []);

    function GetAllPromises() {
        try {
            LoadInfoForTable();
            GetProfesionalList();
        }
        catch (err: any) {
            MessageNotification(err.message, "error");
        }
    }
    const GetProfesionalList = React.useCallback(async () => {
        try {
            setLoaderSelect({ profesional: true });
            const { status, data } = await GetProfesionals({ rolId: 3 });
            if (status === 200) {
                const RSUserList = data?.results?.map((item) => ({
                    value: item.id,
                    label: `${item.apellido}, ${item.nombre}`,
                })) ?? [];
                setProfesionalList(RSUserList);
            }
        } catch (err: any) {
            MessageNotification(err.message, "error");
        } finally { setLoaderSelect({ profesional: false }) }
    }, []);

    const FilterComponents = () => (
        <Grid container direction="row" className="content-filter-list">
            <div className="filter-input">
                <ReactSelect
                    className="filter-input"
                    placeholder="Profesionales..."
                    options={profesionalList}
                    isLoading={loaderSelect.profesional}
                    isClearable={true}
                    value={filterObj.profesional?.value === null ? null : filterObj.profesional}
                    onChange={(e) => setFilterObj({ ...filterObj, profesional: e === null ? _DEFAULT_VALUES_INSTALLMENT.filterObj.profesional : e })}
                />
            </div>
        </Grid>);
    const ButtonNew = () => (
        <Button
            className='btn-new-filter'
            onClick={() => OpenModalSelected("open", "create")}
            icon
            labelPosition='left'
        >
            <Icon name='plus' />
            Nuevo
        </Button>
    );

    //#region LISTAR - EDITAR - ELIMINAR - CREAR
    async function LoadInfoForTable(pageSelected: number = 1) {
        try {
            setOpenModal(_DEFAULT_VALUES_INSTALLMENT.modals);
            setIsLoaderActive(true);
            const params = { ...filterObj, pagina: pageSelected }
            const { status, data } = await GetInstallments(params);
            if (status === 200) {
                setDataToRender(data?.results ?? []);
            }
        } catch (err: any) {
            MessageNotification(err.message, "error");
        } finally {
            setIsLoaderActive(false);
        }
    }
    async function OnCreateInstallment() {
        try {
            const { response, objError } = ValidateInputs(values);
            if (response.isValidated) {
                setIsLoaderActive(true);
                OpenModalSelected("close", "create");
                const { status } = await CreateInstallment(values);
                if (status === 200) {
                    MessageNotification("El plan de cuotas ha sido creado correctamente.", "success");
                    LoadInfoForTable();
                }
            } else {
                MessageNotification(response.message, "error");
                setErrorNotificacion(objError);
            }
        } catch (err: any) {
            MessageNotification(err.message, "error");
        } finally {
            setIsLoaderActive(false);
        }
    }
    async function OnEditInstallment() {
        try {
            if (openModal.edit.data.profesionalId !== values.profesionalId && values.cuotas.some(item => item.fechaPago !== null)) {
                MessageNotification("Solo se permite editar un plan de cuotas si no existen cuotas saldadas.", "error");
                return;
            }
            const { response, objError } = ValidateInputs(values);
            if (response.isValidated) {
                setIsLoaderActive(true);
                OpenModalSelected("close", "edit");
                const { status } = await EditInstallment(values);
                if (status === 200) {
                    MessageNotification("El plan de cuotas ha sido editado correctamente.", "success");
                    LoadInfoForTable();
                }
            } else {
                MessageNotification(response.message, "error");
                setErrorNotificacion(objError);
            }
        } catch (err: any) {
            MessageNotification(err.message, "error");
        } finally {
            setIsLoaderActive(false);
        }
    }
    async function OnDeleteInstallment() {
        try {
            setIsLoaderActive(true);
            OpenModalSelected("close", "delete");
            const { id } = openModal.delete.data;
            const { status } = await DeleteInstallment(id);
            if (status === 200) {
                MessageNotification("El plan de cuotas ha sido eliminado correctamente.", "success");
            }
        } catch (err: any) {
            MessageNotification(err.message, "warning");
        } finally {
            setIsLoaderActive(false);
            LoadInfoForTable();
        }
    }
    //#endregion

    //#region UTILS
    function PrevToAction(e: any, item) {
        const data = {
            id: item.id,
            importe: item.importe,
            detalle: item.detalle,
            profesionalId: item.profesional.id,
            profesionalNombre: `${item.profesional.apellido}, ${item.profesional.nombre}`,
            cantidadCuotas: item.cuotas.length,
            cuotas: item.cuotas,
        }
        OpenModalSelected("open", e.currentTarget.name, data);
    }
    function HandleChangeInputModal(e: any, nameOfProperty: string) {
        setValues({ ...values, [nameOfProperty]: e instanceof Date ? e : (!isNaN(e.target.value.trim()) ? +e.target.value : e.target.value) });
    }
    function HandleSearch() {
        LoadInfoForTable();
    }
    function ValidateInputs(dataOfInputs: IInstallmentRequest) {
        let response = { isValidated: true, message: "" };
        let objError: typeOfError = { fecha: false, profesionalId: false, importe: false, detalle: false, cantidadCuotas: false };
        const valuesOfObj = Object.values(dataOfInputs);
        const keysOfObj = Object.keys(dataOfInputs);

        const notNullValues = valuesOfObj.findIndex(item => item === null || item === "");
        //validas si alguna propiedad esta vacia.
        if (notNullValues !== -1) {
            response = { isValidated: false, message: "Todos los campos son obligatorios." };
            valuesOfObj.forEach((item, i) => {
                if (item === null || item === "") {
                    objError = { ...objError, [keysOfObj[i]]: true };
                }
            })
        } else {
            const notNegativeNumbers = valuesOfObj.findIndex(item => typeof item === "number" && +item < 1);
            //validas que no haya numeros en negativo.
            if (notNegativeNumbers !== -1) {
                response = { isValidated: false, message: "Los campos numéricos deben ser mayores o iguales a uno." }
                valuesOfObj.forEach((item, i) => {
                    if (typeof item === "number" && +item < 1) {
                        objError = { ...objError, [keysOfObj[i]]: true };
                    }
                })
            }
        }
        return { response, objError };
    }
    function OpenModalSelected(action: typeOfActionForModal, modal: typeOfModal, data: any = null) {
        const keys = Object.keys(openModal);
        const indexOfKey = Object.keys(openModal).findIndex(item => item === modal);
        const keyOfModalToOpen = keys[indexOfKey];

        setOpenModal({ ...openModal, [keyOfModalToOpen]: { show: action === "open" ? true : false, data } });

        if (action === "close") {
            setErrorNotificacion(_DEFAULT_VALUES_INSTALLMENT.errorNotification);
            setValues(_DEFAULT_VALUES_INSTALLMENT.values);
        } else {
            if (modal !== "create" && data) setValues(data);
        }
    }
    function PrevToActionDebit(e, item) {
        const data = {
            id: item.id,
            importe: item.importe,
            detalle: item.detalle,
            profesionalId: item.profesional.id,
            profesionalNombre: `${item.profesional.apellido}, ${item.profesional.nombre}`,
            cantidadCuotas: item.cuotas.length,
            cuotas: item.cuotas,
            liquidacionId: item.liquidacionId
        }
        OpenModalSelected("open", e.currentTarget.name, data);
    }
    function HandleChangePage(event: any, pageSelected: number) {
        LoadInfoForTable(pageSelected);
    }
    function MessageNotification(message: string, type: string) {
        setDataToast({
            active: true,
            message,
            type
        });
    }
    //#endregion

    return (
        <>
            <GeneralList
                titleOfSection="Cuotas"
                isLoaderActive={isLoaderActive}
                dataToRender={dataToRender}
                initialPage={page}
                numPagesToRender={pagesToRender}
                FilterComponent={FilterComponents}
                ButtonNew={ButtonNew}
                onChangePage={HandleChangePage}
                HandleSearch={HandleSearch}
            >
                <Table aria-label='spanning table'>
                    <TableHead>
                        <TableRow>
                            <TableCell className='letter-title-table c-text-right'>
                                <b>Matr.</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-left'>
                                <b>Profesional</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-right'>
                                <b>Importe</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center'>
                                <b>Cant. Cuotas</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-left'>
                                <b>Detalle</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center'>
                                <b>Opciones</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataToRender.map((item: any) => (
                            <TableRow key={item.id}>
                                <TableCell className='letter-title-table c-text-right p-cell c-wp-150'>
                                    {item.profesional.matricula}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-left p-cell c-wp-150'>
                                    {`${item.profesional.apellido}, ${item.profesional.nombre}`}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-right p-cell c-wp-150'>
                                    {FormatNumber({ style: 'currency', currency: '$', value: item.importe })}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center p-cell c-wp-150'>
                                    {item.cuotas.filter(item => item.fechaPago !== null).length} de {item.cuotas.length}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-left p-cell c-wp-150'>
                                    {item.detalle.length > 20 ? item.detalle.slice(0, 15) + '...' : item.detalle}
                                    {item.detalle.length > 20 &&
                                        <ButtonBox buttonToShow='view' name='view' HandleOnClik={() => OpenModalSelected("open", "showComment", item)} />}
                                </TableCell>
                                <TableCell className='p-cell'>
                                    <Button.Group>
                                        <ButtonBox buttonToShow='edit' name="edit" HandleOnClik={(e) => PrevToAction(e, item)} />
                                        <ButtonBox buttonToShow='delete' name="delete" HandleOnClik={(e) => PrevToAction(e, item)} />
                                        <ButtonBox buttonToShow='debitManually' name="debitManually" HandleOnClik={(e) => PrevToActionDebit(e, item)} />
                                    </Button.Group>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </GeneralList>

            {/* MODALS */}
            {openModal.create.show &&
                <NewModalInstallment
                    titleOfModal="Nuevo"
                    modal="create"
                    openModal={openModal.create.show}
                    values={values}
                    errorNotification={errorNotification}
                    HandleChangeInput={HandleChangeInputModal}
                    setValues={setValues}
                    MessageNotification={MessageNotification}
                    OpenModalSelected={OpenModalSelected}
                    SendValues={OnCreateInstallment} />
            }
            {openModal.edit.show &&
                <EditModalInstallment
                    titleOfModal="Editar"
                    modal="edit"
                    openModal={openModal.edit.show}
                    values={values}
                    errorNotification={errorNotification}
                    HandleChangeInput={HandleChangeInputModal}
                    setValues={setValues}
                    MessageNotification={MessageNotification}
                    OpenModalSelected={OpenModalSelected}
                    SendValues={OnEditInstallment} />
            }
            {openModal.debitManually.show &&
                <PrevDebitModalInstallment
                    dataToRender={values}
                    open={openModal.debitManually.show}
                    OpenModalSelected={OpenModalSelected}
                    ReloadData={LoadInfoForTable}
                    paginated={false}
                    MessageNotification={MessageNotification}
                />
            }
            {openModal.delete.show &&
                <GeneralModalDelete
                    contentText={`¿Está seguro de eliminar el siguiente plan de cuotas para el profesional: ${openModal.delete.data.profesionalNombre}?`}
                    openModal={openModal.delete.show}
                    HandleClose={() => OpenModalSelected("close", "delete")}
                    HandleSubmit={OnDeleteInstallment}
                />
            }
            {openModal.showComment.show &&
                <GeneralModalShow
                    openModal={openModal.showComment.show}
                    title='Detalle del plan de cuotas'
                    content={openModal.showComment.data.detalle}
                    HandleClose={() => OpenModalSelected("close", "showComment")} />
            }
        </>
    )
}

export default CrudInstallment;