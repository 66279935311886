import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Dimmer, Grid, Header, Loader } from "semantic-ui-react";
import api from "../../api";
import { SocialWorkEnum } from "../../enums";
import { setDataOfPatient } from "../../redux/document";
import { FirePopUpError, FirePopUpSuccess, FirePopUpSuccessWithHTMLFull } from "../utils/sweetPopUp";
import "./Document.css";
import DocumentMedicalOrder from "./DocumentMedicalOrder";
import DocumentPatient from "./DocumentPatient";
import DocumentPractice from "./DocumentPractice";


export default function Document({ setDataToast }) {
	const { handleSubmit, register, control, getValues, setValue } = useForm({
		defaultValues: {
			namePatient: "",
			surnamePatient: "",
			affiliateNum: "",
			phone: "",
			address: "",
			nameMedicalOrder: "",
			surnameMedicalOrder: "",
			diagnosis: "",
			autorNum: null,
			coseguro: null,
			enrollment: "",
			dni: "",
			quantity: 0,
		},
	});

	const [socialWorksPlan, setSocialWorksPlan] = useState();
	const locationUrl = useLocation();
	const disparador = useDispatch();
	const [errors, setErrors] = useState({});
	const [idSocialWork, setIdSocialWork] = useState({ id: null, active: false });
	const [dateMedicalOrder, setDateMedicalOrder] = useState(() => {
		// Obtén la fecha local actual y establece la hora a 00:00:00
		return moment().startOf('day').toDate();
	});
	const [datesSessions, setDatesSessions] = useState({
		session1: moment().startOf('day').toDate(),
	});
	const [sessionQuantity, setSessionQuantity] = useState(0);
	const [patientId, setPatientId] = useState();
	const [text, setText] = useState(null);
	const [medicalOrderId, setMedicalOrderId] = useState();
	const [loadingActive, setLoadingActive] = useState(true);
	const [inputQuantity, setInputQuantity] = useState(0);
	const [practices, setPractices] = useState([]);
	const [loadingInput, setLoadingInput] = useState({
		dni: false,
		enrollment: false,
	});
	const { id } = useParams();
	const history = useHistory();
	const isView = history.location.pathname.includes("ver");
	const isEdit = history.location.pathname.includes("editar");
	const [state, setState] = useState({
		checked: false,
		amount: 0
	});
	const [numAutorization, setNumAutorization] = useState(true);
	const [numSiniester, setNumSiniester] = useState(true);
	const [coinsuranceAmout, setCoinsuranceAmount] = useState(true);
	const [listSessiones, setListSessiones] = useState([]);
	const [planSelected, setPlanSelected] = useState([]);
	const [infoSocialWork, setInfoSocialWork] = useState({
		tokenRequired: false,
		maximumSessions: null,
		numAutorization: false,
		coinsuranceAmout: false,
		hasIntegration: false
	});
	const [modalInstructive, setModalInstructive] = React.useState({
		showModal: false,
		aditionalInfo: {
			socialWorkId: null,
			socialWorkName: null,
			socialWorkAlias: null
		}
	});
	const [diagnosticModal, setDiagnosticModal] = useState({ show: false, data: { codigo: null, descripcion: null } });
	const [tokenModal, setTokenModal] = useState({ show: false, data: null });
	const [stateOfCheckbox, setStateOfCheckbox] = useState({ manualCharge: false, hasIntegration: false });
	const [numMinCharacters, setNumMinCharacters] = useState(null);
	const [numMaxCharacters, setNumMaxCharacters] = useState(null);
	const [tipoIntegracionId, setTipoIntegracionId] = useState(null);
	const [validAffiliateBtn, setValidAffiliateBtn] = useState(false);

	useEffect(() => {
		if (id) {
			LoadDataToEdit();
		}
		setLoadingActive(false);
	}, []);

	const LoadDataToEdit = (isReloadSessionData = false) => {
		setLoadingActive(true)
		api
			.getByIdDocument(+id)
			.then((resp) => {
				if (isReloadSessionData) {
					setListSessiones(resp.data.fechaSesiones);
				} else {
					setPatientId(resp.data.paciente.id);
					setNumMinCharacters(resp.data.paciente.obraSocial.afiliadoCantidadCaracteresMin);
					setNumMaxCharacters(resp.data.paciente.obraSocial.afiliadoCantidadCaracteresMax);
					setTipoIntegracionId(resp.data.paciente.obraSocial.tipoIntegracionId);

					if (resp.data.paciente.obraSocial.autorizacion) {
						setNumAutorization(true);
						if (resp.data.paciente.obraSocial.cantidadMaximaSesiones !== null) {
							setNumAutorization(false);
						}
					} else {
						setValue("autorNum", "");
						setNumAutorization(false);
						if (resp.data.paciente.obraSocial.configuradaIntegracion) {
							setStateOfCheckbox((prevState) => {
								return { ...prevState, hasIntegration: true }
							});
						}
					}
					setDiagnosticModal({ show: resp.data.paciente.obraSocial.id === SocialWorkEnum.apross, data: { codigo: resp.data.codigoDiagnostico, descripcion: resp.data.diagnostico } });

					if (resp.data.paciente.obraSocial.aplicaCoseguro) {
						setCoinsuranceAmount(true);
						if (resp.data.paciente.obraSocial.cantidadMaximaSesiones !== null) {
							setCoinsuranceAmount(false);
						}
					} else {
						setCoinsuranceAmount(false);
						setValue("coseguro", "");
						if (resp.data.paciente.obraSocial.configuradaIntegracion) {
							setStateOfCheckbox((prevState) => {
								return { ...prevState, hasIntegration: true }
							});
						}
					}

					if (resp.data.paciente.obraSocial.numeroSiniestro) {
						setNumSiniester(true);
					} else {
						setNumSiniester(false);
					}

					setSessionQuantity(resp.data.fechaSesiones.length);
					let cantidadMaximaSesiones = resp.data.paciente.obraSocial.cantidadMaximaSesiones;

					if (resp.data.paciente.obraSocial.configuradaIntegracion) {
						if (resp.data.paciente.documento !== "" && resp.data.paciente.documento !== null) {
							if (resp.data.paciente.numeroAfiliado != "" && resp.data.paciente.numeroAfiliado !== null) {
								if (resp.data.paciente.obraSocial.afiliadoCantidadCaracteresMax !== null && resp.data.paciente.obraSocial.afiliadoCantidadCaracteresMin !== null) {
									if (resp.data.paciente.numeroAfiliado.length < resp.data.paciente.obraSocial.afiliadoCantidadCaracteresMin || resp.data.paciente.numeroAfiliado.length > resp.data.paciente.obraSocial.afiliadoCantidadCaracteresMax) {
										setValidAffiliateBtn(true);
									}
								} else {
									setValidAffiliateBtn(true);
								}
							}
						}
					} else {
						setValidAffiliateBtn(false);
					}

					if (cantidadMaximaSesiones) {
						setInfoSocialWork({
							tokenRequired: resp.data.paciente.obraSocial.requiereToken,
							maximumSessions: resp.data.paciente.obraSocial.cantidadMaximaSesiones,
							numAutorization: resp.data.paciente.obraSocial.autorizacion,
							coinsuranceAmout: resp.data.paciente.obraSocial.aplicaCoseguro,
							hasIntegration: resp.data.paciente.obraSocial.configuradaIntegracion
						});
						setListSessiones(resp.data.fechaSesiones);
					} else {
						setDatesSessions({
							...datesSessions,
							['session1']: new Date(resp.data.fechaPrimeraSesion),
						});
					}
					setDataPatient(resp.data.paciente, resp.data.sesiones, resp.data.fechaSesiones);
					setPlanSelected([{ id: resp.data.paciente.planObraSocial.id, name: resp.data.paciente.planObraSocial["nombre"] }]);
					let externalDataOrder = {
						date: resp.data.fecha,
						diagnosis: resp.data.diagnostico,
						numberAuth: resp.data.fechaSesiones[0].numeroAutorizacion,
						numberSiniester: resp.data.numeroSiniestro,
						coinsurance: resp.data.coseguro
					};
					setDataMedicalOrder(resp.data.pedidoMedico, externalDataOrder);
					setMedicalOrderId(resp.data.pedidoMedico.id);
				}
			})
			.catch((err) => {
				setDataToast({
					active: true,
					message: err.response
						? err.response.data.errores
						: 'Acaba de ocurrir un error',
					type: 'error',
				});
			})
			.finally(() => setLoadingActive(false));
	}
	const handleOnChange = (e) => {
		setStateOfCheckbox({ ...stateOfCheckbox, [e.target.name]: e.target.checked });

		if (e.target.checked) {
			setNumAutorization(true);
			setCoinsuranceAmount(true);
		}
		else {
			setNumAutorization(false);
			setCoinsuranceAmount(false);
		}
	}
	const setDefaultValues = () => {
		setSessionQuantity(0);
		setNumAutorization(false);
		setState({});
	}
	const calculateDateSessions = (sessions = sessionQuantity, firstDate = datesSessions['session1']) => {
		let dateFirstSession = firstDate;
		let objSave = {
			session1: dateFirstSession,
		};
		let body = {
			quantity: sessions,
			dateFirstSession: moment(dateFirstSession).format('YYYY-MM-DD'),
		};
		api
			.getDatePractice(body)
			.then((resp) => {
				let responseDays = resp.data;
				for (let index = 1; index < responseDays.length; index++) {
					let numberSession = index + 1;
					objSave['session' + numberSession] = responseDays[index];
				}

				setDatesSessions(objSave);
			})
			.catch((err) => {
				setDataToast({
					active: true,
					message: err.response.data.errores || 'Acaba de ocurrir un error',
					type: 'error',
				});
			});
	}
	const errorControl = (errorsValues, condition, key) => {
		if (condition) {
			if (!errorsValues) {
				errorsValues = {};
			}
			errorsValues[key] = true;
		}

		return errorsValues;
	}
	const errorDatePractices = (
		dateSessionsArray,
		errorsValues,
		fechaPedidoMedico
	) => {

		for (let index = 1; index < dateSessionsArray.length; index++) {
			if (
				dateSessionsArray[index].toString() === 'Invalid Date' ||
				new Date(dateSessionsArray[index - 1].setHours(0, 0, 0, 0)) >
				new Date(dateSessionsArray[index].setHours(0, 0, 0, 0))
			) {
				errorsValues.message =
					'Las fechas de sesión deben de respetar el orden de mayor a menor.';
				return true;
			} else if (
				dateSessionsArray[index].toString() === 'Invalid Date' ||
				new Date(dateSessionsArray[index].setHours(0, 0, 0, 0)) <
				fechaPedidoMedico
			) {
				errorsValues.message =
					'La fecha de sesión no puede ser menor a la fecha del pedido médico.';
				return true;
			}
		}
		return false;
	}
	const errorChecksPractice = () => {
		let response = true;
		Object.keys(state).forEach((key) => {
			if (state[key].checked == true) {
				response = false;
			}
		});

		return response;
	}
	function ValidateInputs() {
		let isValidated = true;
		const fechaPedidoMedico = new Date(dateMedicalOrder);
		fechaPedidoMedico.setHours(0, 0, 0, 0);
		window.scrollTo(0, 0);
		const values = getValues();
		let errorsValues = {};
		const today = new Date();
		const quantitySessions = +sessionQuantity;
		const dateSessionsArray = [];
		const reg = /^\d+$/;
		const emptyInput = /\S/;
		let sessionsSelected = [];

		if (tokenModal.show && tokenModal.data === null) {
			errorsValues.token = true;
			errorsValues.message =
				'El Token es requerido para la operación que desea realizar.';
		}

		if (!idSocialWork.active) {
			errorsValues.socialWork = true;
			errorsValues.message = "La obra social seleccionada se encuentra dada de baja o no existe.";
		}

		if (numMaxCharacters != null && numMinCharacters != null) {
			if (values.affiliateNum.length < numMinCharacters || values.affiliateNum.length > numMaxCharacters) {
				errorsValues.affiliateNum = true;
				errorsValues.message = `El valor ingresado debe tener entre ${numMinCharacters} y ${numMaxCharacters} caracteres.`;
			}
		}
		if (numMaxCharacters != null) {
			if (values.affiliateNum.length > numMaxCharacters) {
				errorsValues.affiliateNum = true;
				errorsValues.message = `El valor ingresado NO debe superar los ${numMaxCharacters} caracteres.`;
			}
		}
		if (numMinCharacters != null) {
			if (values.affiliateNum.length < numMinCharacters) {
				errorsValues.affiliateNum = true;
				errorsValues.message = `El valor ingresado debe tener por lo menos ${numMinCharacters} caracteres.`;
			}
		}

		errorsValues = errorControl(
			errorsValues,
			!emptyInput.test(values['affiliateNum']),
			'affiliateNum'
		);

		errorsValues = errorControl(
			errorsValues,
			!reg.test(values['dni']),
			'dni'
		);

		if (Object.keys(errorsValues).length < 2) {
			errorsValues = {}
		}

		if (+values.dni.length > 8) {
			errorsValues.dni = true;
			errorsValues.message =
				'El número de documento debe ser menor a 8 caracteres.';
		}

		errorsValues = errorControl(errorsValues, !idSocialWork.id, 'socialWork');

		if (dateMedicalOrder > today) {
			errorsValues.datePractice = true;
			errorsValues.message =
				'La fecha del pedido médico no puede ser mayor a la fecha de hoy.';
		}
		if (dateMedicalOrder.toString() === 'Invalid Date') {
			errorsValues.dateMedicalOrder = true;
		}

		if (quantitySessions > 0) {
			for (let index = 0; index < quantitySessions; index++) {
				let numberSession = index + 1;
				dateSessionsArray.push(new Date(moment(datesSessions['session' + numberSession]).format()));
			}
		} else {
			errorsValues.quantity = true;
			errorsValues.message = "Por favor, ingrese al menos una sesión.";
		}

		if (errorDatePractices(dateSessionsArray, errorsValues, fechaPedidoMedico))
			errorsValues.datePractice = true;

		Object.keys(values).forEach((key) => {
			errorsValues = errorControl(
				errorsValues,
				!values[key] && key != 'socialWork' && key != 'siniester' && key != 'coseguro' && key != 'autorNum',
				key
			);
		});

		if (+values.enrollment.length < 0 || values.enrollment === "") {
			errorsValues.enrollment = true;
			errorsValues.message =
				"El campo 'Matrícula del Prescriptor' no puede estar vacío.";
		}

		if (practices.length > 0) {
			if (errorChecksPractice()) {
				errorsValues.checkPractice = true;
			}
		}

		errorsValues = errorControl(
			errorsValues,
			!reg.test(values['phone']),
			'phone'
		);

		if (numAutorization) {
			errorsValues = errorControl(
				errorsValues,
				!reg.test(values['autorNum']),
				'autorNum'
			);
		}

		if (numSiniester) {
			errorsValues = errorControl(
				errorsValues,
				!reg.test(values['siniester']),
				'siniester'
			);
		}

		if (coinsuranceAmout) {
			errorsValues = errorControl(
				errorsValues,
				!reg.test(values['coseguro']),
				'coseguro'
			);
		}

		errorsValues = errorControl(
			errorsValues,
			!reg.test(values['enrollment']),
			'enrollment'
		);

		for (var key in state) {
			if (state[key].checked) {
				sessionsSelected.push({ idPracticaObraSocialCuenta: +key, monto: state[key].amount, codigo: state[key].codigo });
			}
		}

		if (sessionsSelected.length === 0) {
			errorsValues.message = "Ingrese un tipo de sesión.";
		}

		if (Object.keys(errorsValues).length > 0) {
			setErrors(errorsValues);
			isValidated = false;
			setDataToast({
				active: true,
				message: errorsValues.message ?? 'Formulario inválido',
				type: 'error',
			});
			return { isValidated };
		}
		return { isValidated };
	}
	const handleClickSave = (newDocument = false) => {
		const { devolutionSection } = locationUrl.state ? locationUrl.state : false;
		setLoadingActive(true);
		window.scrollTo(0, 0);
		setTokenModal({ show: false, data: null });
		const values = getValues();
		const quantitySessions = +sessionQuantity;
		const dateSessionsArray = [];
		let sessionsSelected = [];

		for (let index = 0; index < quantitySessions; index++) {
			let numberSession = index + 1;
			dateSessionsArray.push(new Date(moment(datesSessions['session' + numberSession]).format()));
		}

		for (var key in state) {
			if (state[key].checked) {
				sessionsSelected.push({ idPracticaObraSocialCuenta: +key, monto: state[key].amount, codigo: state[key].codigo });
			}
		}
		let body;
		body = {
			paciente: {
				nombre: values.namePatient,
				apellido: values.surnamePatient,
				numeroDocumento: +values.dni,
				numeroAfiliado: values.affiliateNum,
				obraSocialId: idSocialWork.id,
				planObraSocialId: values.socialWorkPlan.id,
				telefono: +values.phone,
				domicilio: values.address,
			},
			pedidoMedico: {
				nombre: values.nameMedicalOrder,
				apellido: values.surnameMedicalOrder,
				matriculaPrescriptor: values.enrollment,
			},
			practica: {
				practicas: sessionsSelected,
				fechaAutorizacion: datesSessions['session1'],
				fechasSesiones: dateSessionsArray.map(date => date),
			},
			fecha: dateMedicalOrder,
			diagnostico: values.diagnosis,
			codigoDiagnostico: diagnosticModal.data.codigo,
			numeroSiniestro: values.siniester ? +values.siniester : null,
			numeroAutorizacion: values.autorNum ? +values.autorNum : null,
			coseguro: values.coseguro ? +values.coseguro : null,
			token: tokenModal.data,
			externalId: null,
			cantidadSesiones: +sessionQuantity
		};

		if (id) {
			body.id = parseInt(id);
		}
		if (patientId) body.paciente.id = patientId;
		if (medicalOrderId) body.pedidoMedico.id = medicalOrderId;

		(id ? api.updateDocument(id, body) : (stateOfCheckbox.manualCharge ? api.postManualDocument(body) : api.postDocument(body)))
			.then((resp) => {
				if (!newDocument) {
					if (id) {
						if (devolutionSection) {
							history.push('/devoluciones/lista');
						} else {
							history.goBack()
						}
					} else {
						history.push('/paciente/importar/' + resp.data.id);
					}
				} else {
					if (id) {
						history.push('/paciente/crear');
					} else {
						window.location.reload();
					}
					setDefaultValues();
				}
				setDataToast({
					active: true,
					message: id
						? 'Registro editado con éxito'
						: 'Registro creado con éxito',
					type: 'success',
				});
			})
			.catch((err) => {
				setDataToast({
					active: true,
					message:
						err?.response?.data?.errores || 'Acaba de ocurrir un error',
					type: 'error',
				});
			})
			.finally(() => {
				setTokenModal({ show: false, data: null });
				setLoadingActive(false);
			})
	}
	const setDataPatient = (data, sessions, dateOfSessions) => {

		if (id) {
			setValue('dni', data.documento)
		}

		if (data.id) {
			setPatientId(data.id);
		}

		setValue('namePatient', data.nombre);
		setValue('surnamePatient', data.apellido);
		if (data.numeroAfiliado != 0) {
			setValue('affiliateNum', data.numeroAfiliado);
		}

		setIdSocialWork({
			id: data.obraSocial.id,
			active: data.obraSocial.activo
		});

		setValue('socialWork', {
			id: data.obraSocial.id,
			name: data.obraSocial.nombre,
		});

		setModalInstructive({
			...modalInstructive,
			aditionalInfo: {
				socialWorkId: data.obraSocial.id,
				socialWorkName: data.obraSocial.nombre,
				socialWorkAlias: data.obraSocial.alias
			}
		});

		//El instructivo puede venir NULL en caso de que nunca se asigno/edito
		//El instructivo si se edito y se guardo vacio, no es un "" sino es un "<p><br/></p>" por ende no se considera ni "" ni null, asique hay que validar el contenido de esa etiqueta
		//para mostrar o no el boton de instructivo. En este bloque se parsea lo que viene de la API para convertir a una etiqueta <p> y contemplar su contenido.
		if (data.obraSocial.instructivo !== null) {
			const DOMparser = new DOMParser().parseFromString(data.obraSocial.instructivo, "text/html");
			const tag = DOMparser.body.firstChild;

			if (tag.innerText !== "") setText(data.obraSocial.instructivo);
		}

		//Se buscan las practicas que posean monto y las practicas que no posean monto, se hace una comparativa entre ambas listas y se buscan
		//aquellas practicas que sean iguales pero una tenga monto y la otra no. En caso de ser asi, se agrega el monto a la practica que cumplio
		//la condicion
		Promise.all([api.GetPracticesAndAmountByPlan(data.obraSocial.id, data.planObraSocial.id), api.getPracticesBySocialWorkAndPlan(data.obraSocial.id, data.planObraSocial.id)])
			.then(resp => {
				let practiceWithAmount = resp[0]?.data;
				//se crea un objeto modelo igual al objeto modelo de las practicas con monto, es para manejar de igual a todos los objetos.
				let practiceWithOutAmount = resp[1]?.data?.resultados.map(item => {
					return {
						monto: 0,
						id: item.id,
						practica: {
							alias: item.alias,
							nombre: item.nombre,
							practicaObraSocialCuentaId: item.practicaObraSocialCuentaId,
							codigo: item.codigo
						}
					}
				});

				let practices = evaluateAmountsForPractices(practiceWithAmount, practiceWithOutAmount);
				if (isView || isEdit) {
					if (sessions) {
						//devuelve un listado con las practicas que hayan sido marcadas a la hora de dar de alta el documento
						//modifico el objeto modelo para que coincida con los modelos que venimos trabajado
						let savedPractices = sessions?.map(item => {
							return {
								monto: 0,
								id: item.id,
								practica: {
									alias: item.practica.alias,
									nombre: item.practica.nombre,
									practicaObraSocialCuentaId: item.practicaObraSocialCuentaId,
									codigo: item.practica.codigo
								}
							}
						});

						let practicesForCheck = evaluateAmountsForPractices(practices, savedPractices);

						setDataPractice(practicesForCheck, dateOfSessions);
					}
				}
				setPractices(practices);
			})
			.catch(err => {
				setDataToast({
					active: true,
					message: err.response.data.errores,
					type: 'error',
				});
			})

		getSocialWorkPlan(data.obraSocial.id);

		setValue('socialWorkPlan', {
			id: data.planObraSocial.id,
			name: data.planObraSocial.nombre,
		});
		setValue('phone', data.telefono);
		setValue('address', data.domicilio);
	}
	const getSocialWorkPlan = (socialWorkId) => {
		let listSocialWorkPlan = [{}];
		api.GetPlansBySocialWorkPlansAccounts(socialWorkId, false)
			.then(resp => {
				listSocialWorkPlan = resp.data.map(item => {
					return {
						id: item.id,
						name: item["nombre"]
					}
				});
				setPlanSelected(listSocialWorkPlan);
			})
			.catch(err => {
				setDataToast({
					active: true,
					message: err.response.data.errores,
					type: 'error',
				});
			});
	}
	const setDataMedicalOrder = (data, externalData, isNew = false) => {
		if (data) {
			setValue('enrollment', data.matriculaPrescriptor);
			setValue('nameMedicalOrder', data.nombre);
			setValue('surnameMedicalOrder', data.apellido);
		}

		if (id && !isNew) {
			setValue('enrollment', data.matriculaPrescriptor);
			setValue('diagnosis', externalData.diagnosis);
			setValue('siniester', externalData.numberSiniester);
			setValue('autorNum', externalData.numberAuth);
			setValue('coseguro', externalData.coinsurance);

			const externalDate = moment(externalData.date).startOf('day').toDate();
			setDateMedicalOrder(externalDate);
		}

		if (data.id) {
			setMedicalOrderId(data.id);
		}
	}
	const setDataPractice = (typeOfSessions = [], dateOfSessions = []) => {
		let objStateChecks;
		if (typeOfSessions.length > 0) {
			typeOfSessions.forEach((sesion) => {
				objStateChecks = { ...objStateChecks, [sesion.practica.practicaObraSocialCuentaId]: { checked: true, amount: sesion.monto === 0 ? 0 : sesion.monto, codigo: sesion.codigo } }
			});

			setState(objStateChecks);
			setValue('quantity', dateOfSessions.length);
		} else {
			setState({});
		}

		if (dateOfSessions.length > 0) setDatesSessionsEdit(dateOfSessions);

	}
	const setDatesSessionsEdit = (dateSessions) => {
		let objSave = {};
		for (let index = 0; index < dateSessions.length; index++) {
			objSave['session' + (index + 1)] = new Date(dateSessions[index].fechaSesion);
		}
		setDatesSessions(objSave);
		setSessionQuantity(+dateSessions.length);
	}
	function HandleClickSubmit(newdocument = false) {
		const { isValidated } = ValidateInputs();
		if (isValidated) {
			if (tokenModal.data === null && !isEdit && !stateOfCheckbox.manualCharge && infoSocialWork.tokenRequired) {
				setTokenModal({ ...tokenModal, show: true });
			}
			else {
				handleClickSave(newdocument);
			}
		}
	}
	const onBlurInputSearch = (event) => {
		switch (event[0].target.name) {
			case 'dni':
				if (+event[0].target.value.length > 6 && +event[0].target.value.length < 10) {
					setLoadingInput({ ...loadingActive, dni: true });
					const { affiliateNum } = getValues();
					const params = { dni: +event[0].target.value, socialWorkId: idSocialWork.id }

					if (stateOfCheckbox.hasIntegration) {
						if (affiliateNum !== "" && affiliateNum !== null) {
							if (numMaxCharacters !== null && numMinCharacters !== null) {
								if (affiliateNum.length < numMinCharacters || affiliateNum.length > numMaxCharacters) {
									setValidAffiliateBtn(true);
								}
							} else {
								setValidAffiliateBtn(true);
							}
						}
					} else {
						setValidAffiliateBtn(false);
					}

					api
						.getByDniPatient(params)
						.then((resp) => {

							if (resp.data.id != 0) {

								setErrors({});
								setDiagnosticModal({ show: false, data: { codigo: null, descripcion: null } });

								setDataPatient(resp.data);

								setInfoSocialWork({
									maximumSessions: resp.data.obraSocial.cantidadMaximaSesiones,
									tokenRequired: resp.data.obraSocial.requiereToken,
									numAutorization: resp.data.obraSocial.autorizacion,
									coinsuranceAmout: resp.data.obraSocial.aplicaCoseguro,
									hasIntegration: resp.data.obraSocial.configuradaIntegracion
								});

								setNumMinCharacters(resp.data.obraSocial.afiliadoCantidadCaracteresMin);
								setNumMaxCharacters(resp.data.obraSocial.afiliadoCantidadCaracteresMax);
								setTipoIntegracionId(resp.data.obraSocial.tipoIntegracionId);

								setSessionQuantity(resp.data.obraSocial.cantidadMaximaSesiones ?? 0);
								if (resp.data.obraSocial.autorizacion) {
									setNumAutorization(true);
								} else {
									setNumAutorization(false);
									setValue("autorNum", "");
									if (resp.data.obraSocial.configuradaIntegracion || resp.data.obraSocial.requiereToken) {
										setStateOfCheckbox((prevState) => {
											return { ...prevState, hasIntegration: true }
										});
										setDiagnosticModal({ show: resp.data.obraSocial.id === SocialWorkEnum.apross, data: { codigo: null, descripcion: null } });
									}
								}

								if (resp.data.obraSocial.aplicaCoseguro) {
									setCoinsuranceAmount(true);
								} else {
									setCoinsuranceAmount(false);
									setValue("coseguro", "");
									if (resp.data.obraSocial.configuradaIntegracion || resp.data.obraSocial.requiereToken) {
										setStateOfCheckbox((prevState) => {
											return { ...prevState, hasIntegration: true }
										});
									}
								}

								setPlanSelected([{ id: resp.data.planObraSocial.id, name: resp.data.planObraSocial['nombre'] }]);
								setState({});
								if (resp.data.obraSocial.numeroSiniestro) {
									setNumSiniester(true);
								} else {
									setNumSiniester(false);
								}

								if (resp.data.obraSocial.configuradaIntegracion) {
									if (resp.data.numeroAfiliado !== "" && resp.data.numeroAfiliado !== null) {
										if (resp.data.obraSocial.afiliadoCantidadCaracteresMax !== null && resp.data.obraSocial.afiliadoCantidadCaracteresMin !== null) {
											if (resp.data.numeroAfiliado.length < resp.data.obraSocial.afiliadoCantidadCaracteresMin || resp.data.numeroAfiliado.length > resp.data.obraSocial.afiliadoCantidadCaracteresMax) {
												setValidAffiliateBtn(true);
											}
										} else {
											setValidAffiliateBtn(true);
										}
									}
								} else {
									setValidAffiliateBtn(false);
								}
							}
							setLoadingInput({ ...loadingActive, dni: false });
						})
						.catch((err) => {
							setLoadingInput({ ...loadingActive, dni: false });
							setDataToast({
								active: true,
								message: err?.response?.data?.errores || 'Acaba de ocurrir un error',
								type: 'error',
							});
						});
				} else {
					setValidAffiliateBtn(false);
				}
				break;
			case 'enrollment':
				if (+event[0].target.value.length > 3) {
					setLoadingInput({ ...loadingActive, enrollment: true });
					api
						.getByDocumentNumber(event[0].target.value)
						.then((resp) => {
							setDataMedicalOrder(resp.data, null, true);
							setLoadingInput({ ...loadingActive, enrollment: false });
						})
						.catch((err) => {
							setLoadingInput({ ...loadingActive, enrollment: false });
						});
				}
				break;
			case 'affiliateNum':
				if (stateOfCheckbox.hasIntegration) {
					const valuesForm = getValues();
					if (event[0].target.value === "" || event[0].target.value === null) {
						setValidAffiliateBtn(false);
						return
					}
					if (valuesForm.dni !== "" && valuesForm.dni !== null) {
						if (numMaxCharacters !== null && numMinCharacters !== null) {
							if (valuesForm.affiliateNum.length < numMinCharacters || valuesForm.affiliateNum.length > numMaxCharacters) {
								setValidAffiliateBtn(true);
							}
						} else {
							setValidAffiliateBtn(true);
						}
					}
				}
				break;
			default:
				break;
		}
	}
	const evaluateAmountsForPractices = (practiceWithAmount, practiceWithOutAmount) => {
		//Este metodo recorre las practicas que tienen un monto asignado y busca en el listado de practicas aquella que coincida el id y que no tenga monto.
		//si se da la condicion
		//se suplanta la practica sin monto con la que si tiene monto.
		if (practiceWithAmount.length > 0 && practiceWithOutAmount.length > 0) {
			//recorro las practicas que tienen monto y a su vez las que NO tienen monto
			//si se encuentra una coincidencia de ID en ambas listas, se devuelve un objeto que contenga todos los datos y agregando el campo monto a la practica
			//y para que no hayan practicas repetidas, se elimina la coincidencia que tenia y no tenia monto a su vez. (se evita que haya este tipo de cosas en la lista FTK (sin monto) y FTK - $5.000)
			practiceWithAmount.forEach(posc => {
				practiceWithOutAmount.forEach((item, i) => {
					if (item.practica.practicaObraSocialCuentaId === posc.practica.practicaObraSocialCuentaId) {
						practiceWithOutAmount = [...practiceWithOutAmount, { ...practiceWithOutAmount[i], monto: posc.monto, codigo: posc.practica.codigo }];
						practiceWithOutAmount.splice(i, 1);
					} else {
						return item;
					}
				})
			});
			return practiceWithOutAmount.reverse();
		}
		else
			if (practiceWithOutAmount.length > 0) {
				return practiceWithOutAmount
			} else return []
	}
	const hadleClickView = () => {
		history.goBack();
	}
	function HandleCloseTokenModal() {
		setTokenModal({ show: false, data: null });
	}
	function HandleValidateAffiliate(e) {
		e.preventDefault();
		setLoadingInput({ ...loadingActive, dni: true });
		const { affiliateNum, dni } = getValues();
		const params = { dni: dni, socialWorkId: idSocialWork.id, affiliateNum: affiliateNum }
		api
			.ValidateAffiliate(params)
			.then((resp) => {
				if (resp.data.nombre != "" || resp.data.apellido != "" || resp.data.nombrePlanObraSocial) {
					var fullName = resp.data.apellido + " " + resp.data.nombre;
					FirePopUpSuccessWithHTMLFull(fullName, resp.data.nombrePlanObraSocial);
				}
				else {
					FirePopUpSuccess("Afiliado validado correctamente.", "Datos del afiliado cargados.");
				}
			})
			.catch((err) => {
				FirePopUpError("Ocurrio un error.", err?.response?.data?.errores);
			})
			.finally(() => {
				setLoadingInput({ ...loadingActive, dni: false });
			})
	}
	function HandlePreFormAuth(e) {
		e.preventDefault();
		const allValuesForm = getValues();
		if (allValuesForm.dni === "" || allValuesForm.affiliateNum === "") {
			setDataToast({
				active: true,
				message: "Ingrese DNI, Nro. Afiliado y Plan de la obra social para continuar.",
				type: 'error',
			});
			return;
		}
		const dataOfPatient = {
			dni: allValuesForm.dni,
			nameOfPatient: allValuesForm.namePatient,
			surnameOfPatient: allValuesForm.surnamePatient,
			affiliateNumOfPatient: allValuesForm.affiliateNum,
			socialWork: allValuesForm.socialWork,
			planSocialWork: allValuesForm.socialWorkPlan,
			phoneOfPatient: allValuesForm.phone,
			addressOfPatient: allValuesForm.address,
			enrollment: allValuesForm.enrollment,
			nameMedicalOrder: allValuesForm.nameMedicalOrder,
			surnameMedicalOrder: allValuesForm.surnameMedicalOrder,
			dateOfMedicalOrder: new Date(dateMedicalOrder),
			diagnosisMedicalOrder: allValuesForm.diagnosis,
			autorNum: allValuesForm.autorNum,
			coseguro: allValuesForm.coseguro,
			firstSessionDate: new Date(datesSessions['session1']),
			quantitySessions: +sessionQuantity,
			sessionsSelected: state
		};
		disparador(setDataOfPatient(dataOfPatient));
		history.push("/paciente/preautorizar/crear");
	}
	return (
		<>
			{loadingActive && (
				<Dimmer className='loader-fixed' active inverted>
					<Loader size='big'>Cargando..</Loader>
				</Dimmer>
			)}
			<form>
				<Grid>
					<Grid className="contHeaderAndBtnDocument">
						<Header as='h2' icon>
							<Header.Content>Paciente</Header.Content>
						</Header>
						<section>
							{idSocialWork.id === SocialWorkEnum.sancorSalud && !isView && <Button className="preFormAuthBtn" onClick={(e) => HandlePreFormAuth(e)}>Preautorizar</Button>}
							{validAffiliateBtn && !isView && <Button className="validateAffiliateBtn" onClick={(e) => HandleValidateAffiliate(e)}>Validar Afiliado</Button>}
						</section>
					</Grid>
					<DocumentPatient
						isView={isView}
						isEdit={isEdit}
						loadingInput={loadingInput}
						setDataToast={setDataToast}
						onBlurInputSearch={onBlurInputSearch}
						setValue={setValue}
						idSocialWork={idSocialWork}
						setIdSocialWork={setIdSocialWork}
						register={register}
						setErrors={setErrors}
						errors={errors}
						control={control}
						setPractices={setPractices}
						setSessionQuantity={setSessionQuantity}
						setNumAutorization={setNumAutorization}
						setState={setState}
						setNumSiniester={setNumSiniester}
						setInfoSocialWork={setInfoSocialWork}
						planSelected={planSelected}
						setPlanSelected={setPlanSelected}
						setSocialWorksPlan={setSocialWorksPlan}
						socialWorksPlan={socialWorksPlan}
						setCoinsuranceAmount={setCoinsuranceAmount}
						evaluateAmountsForPractices={evaluateAmountsForPractices}
						setModalInstructive={setModalInstructive}
						modalInstructive={modalInstructive}
						text={text}
						setText={setText}
						setStateOfCheckbox={setStateOfCheckbox}
						stateOfCheckbox={stateOfCheckbox}
						setDiagnosticModal={setDiagnosticModal}
						setNumMinCharacters={setNumMinCharacters}
						setNumMaxCharacters={setNumMaxCharacters}
						setTipoIntegracionId={setTipoIntegracionId}
						setValidAffiliateBtn={setValidAffiliateBtn}
						getValueForm={getValues}
					/>

					<Grid style={{ position: "relative", margin: 0, padding: 0, display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
						<Header as='h2' icon>
							<Header.Content>Pedido Médico</Header.Content>
						</Header>
					</Grid>
					<DocumentMedicalOrder
						isView={isView}
						loadingInput={loadingInput}
						infoSocialWork={infoSocialWork}
						setDataToast={setDataToast}
						onBlurInputSearch={onBlurInputSearch}
						dateMedicalOrder={dateMedicalOrder}
						setDateMedicalOrder={setDateMedicalOrder}
						register={register}
						errors={errors}
						control={control}
						numAutorization={numAutorization}
						setNumAutorization={setNumAutorization}
						numSiniester={numSiniester}
						idSocialWork={idSocialWork}
						coinsuranceAmout={coinsuranceAmout}
						stateOfCheckbox={stateOfCheckbox}
						setStateOfCheckbox={handleOnChange}
						isEdit={isEdit}
						diagnosticModal={diagnosticModal}
						setDiagnosticModal={setDiagnosticModal}
					/>

					<Grid style={{ position: "relative", margin: 0, padding: 0, display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
						<Header as='h2' icon>
							<Header.Content>Práctica</Header.Content>
						</Header>
					</Grid>
					<DocumentPractice
						infoSocialWork={infoSocialWork}
						dateMedicalOrder={dateMedicalOrder}
						numAutorization={numAutorization}
						isView={isView}
						isEdit={isEdit}
						setDataToast={setDataToast}
						state={state}
						setState={setState}
						sessionQuantity={sessionQuantity}
						setSessionQuantity={setSessionQuantity}
						register={register}
						setDatesSessions={setDatesSessions}
						datesSessions={datesSessions}
						errors={errors}
						coinsuranceAmout={coinsuranceAmout}
						inputQuantity={inputQuantity}
						setInputQuantity={setInputQuantity}
						practices={practices}
						idSocialWork={idSocialWork}
						listSessiones={listSessiones}
						calculateDateSessions={calculateDateSessions}
						stateOfCheckbox={stateOfCheckbox}
						setStateOfCheckbox={setStateOfCheckbox}
						setLoadingActive={setLoadingActive}
						LoadDataToEdit={LoadDataToEdit}
					/>
				</Grid>
			</form>
			<div style={{ position: 'absolute', bottom: '1%', right: '2%', display: "flex", alignItems: "center" }}>
				{!isView && (
					<>
						{!id && (
							<Button
								className='color-btn-green'
								style={{
									marginTop: 10,
									color: 'white',
								}}
								name='saveAndNew'
								onClick={handleSubmit(() => HandleClickSubmit(true))}
							>
								Guardar y Nuevo
							</Button>
						)}
						<Button
							className='color-btn-green'
							style={{ marginTop: 10, color: 'white' }}
							name='continue'
							onClick={handleSubmit(() => HandleClickSubmit())}
						>
							Guardar
						</Button>
					</>
				)}
				<Button
					style={{ marginTop: 10 }}
					onClick={hadleClickView}
				>
					Volver
				</Button>
			</div>


			{/* Modal Token */}
			<Dialog
				open={tokenModal.show}
				onClose={HandleCloseTokenModal}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Token</DialogTitle>
				<DialogContent style={{ display: "grid", gap: 20 }}>
					<TextField
						style={{ marginRight: 5 }}
						size='small'
						label='Token'
						name="token"
						error={errors}
						id="token"
						value={tokenModal.data}
						onChange={(e) => { setTokenModal({ ...tokenModal, data: e.target.value }) }}
						variant='outlined'
					/>
				</DialogContent>

				<DialogActions style={{ justifyContent: 'center' }}>
					<Button onClick={HandleCloseTokenModal}>
						Cancelar
					</Button>
					<Button onClick={() => HandleClickSubmit()} color='green'>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
