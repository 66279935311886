import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusCode } from '../../../enums';
import { setDataParamsRegionalForLiquidation } from '../../../redux/generateLiquidation';
import { GetConfigParams } from '../../../services/services/ConfigurationRegional';
import { FormatNumber } from '../../utils/FormatInputs';

const ConfigParamsForLiquidation: React.FC<any> = ({
    MessageNotification
}) => {
    const {parametros} : any = useSelector<any>(({generateLiquidation}) => generateLiquidation);
    const disparador = useDispatch();

    React.useEffect(()=>{
        GetConfigParamList();
    },[]);

    async function GetConfigParamList(){
        try{
            const {data, status} = await GetConfigParams();
            if (status === StatusCode.Ok){
                disparador(setDataParamsRegionalForLiquidation(data.results));
            }
        }catch(err: any){
            MessageNotification(err.response.data.errores,"error");
        }
    }

    return (
        <section style={{
            display: "flex",
            alignItems: "center"
        }}>
            <article style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            margin: 10,
            border: "1px solid lightgray",
            padding: 10,
            overflowY: "auto",
            borderRadius: 10,
            maxHeight: "100px"
        }}>
            <p>Monto Cuota Societaria: <span>{FormatNumber({style: 'currency', currency: '$', value: parametros.montoCuotaSocietaria})}</span></p>
            <p>Monto Mantenimiento Matrícula: <span>{FormatNumber({style: 'currency', currency: '$', value: parametros.montoMantenimientoMatricula})}</span></p>
            <p>Monto Sistema Documental: <span>{FormatNumber({style: 'currency', currency: '$', value: parametros.montoSistemaDocumental})}</span></p>
            <p>Monto Gasto No Socios: <span>{FormatNumber({style: 'currency', currency: '$', value: parametros.montoGastoNoSocio})}</span></p>
            <p>Porcentaje Débito Colegio: <span>% {parametros.porcentajeDebitoColegio}</span></p>
            <p>Porcentaje Debito Regional: <span>% {parametros.porcentajeDebitoRegional}</span></p>
            </article>
        </section>
    );
}

export default ConfigParamsForLiquidation;