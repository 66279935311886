import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Dimmer,
  Grid,
  Header,
  Icon,
  Loader,
} from "semantic-ui-react";
import api from "../../../api";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import "./ImportFile.css";
import { Capacitor } from '@capacitor/core';

export default function ImportFile({ setDataToast, importForLiquidation = false }) {
  const [selectedFile, setSelectedFile] = useState();
  const [loadingActive, setLoadingActive] = useState();
  const [canUpload, setCanUpload] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 680);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 680);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onChangeHandler = (event) => {
    let arraySave = selectedFile ? [...selectedFile] : [];
    let files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      let index = arraySave.indexOf(file);
      var megas = file.size / 1024 / 1024;

      if (megas <= 5.1) {
        if (index === -1) {
          arraySave.push(file);
          setSelectedFile(arraySave);

          let reader = new FileReader();
          reader.onload = function (event) { };
          reader.readAsText(file);
        }
      } else {
        setDataToast({
          active: true,
          message: `El archivo ${file.name} no puede ser superior a 5MB`,
          type: "warning",
        });
      }
    }
    if (arraySave.length > 0) {
      setCanUpload(true);
    }
  };

  const removeFile = (file) => {
    let arraySave = selectedFile ? [...selectedFile] : [];
    let index = arraySave.indexOf(file);
    if (index > -1) {
      arraySave.splice(index, 1);
      setSelectedFile(arraySave);
    }
    if (index == 0) {
      setCanUpload(false);
    }
  };

  const handleClickCancel = () => {
    if (location.state !== undefined && location.state.fromPreAuthForm) {
      history.push("/pacientes/lista")
    } else {
      history.goBack();
    }
  };

  const CardsUploadFile = ({ item }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
        <Card className={isMobile ? "width-card-mobile" : "width-card-process-upload"}>
          <div style={{display: "flex", justifyContent: "space-between", padding: "1em 1em"}}>
            <Card.Description
              style={{
                width: isMobile ? "280px" : "auto", // Limita el ancho máximo
                whiteSpace: "nowrap", // Evita que el texto haga salto de línea
                overflow: "hidden", // Oculta el texto desbordado
                textOverflow: "ellipsis", // Agrega "..."
              }}
            >
              {item.name}
            </Card.Description>
            <Icon
              onClick={() => removeFile(item)}
              className="icon-button-trash"
              style={{ float: "right" }}
              name="trash alternate"
              size="large"
            />
          </div>
        </Card>
      </div>
    );
  };

  const uploadFile = () => {
    let formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      formData.append("images", selectedFile[index]);
    }
    setLoadingActive(true);
    api
      .uploadImageDocument(id, formData)
      .then((resp) => {
        setDataToast({
          active: true,
          message: 'Imagen subida correctamente',
          type: 'success',
        });
      })
      .catch((err) => {
        setDataToast({
          active: true,
          message: err.response.data.errores || "Acaba de ocurrir un error",
          type: "error",
        });
      })
      .finally(() => {
        setLoadingActive(false);
        if (location.state !== undefined && location.state.fromPreAuthForm) {
          history.push("/pacientes/lista");
        } else {
          history.goBack();
        }
      })
  }

  const PrincipalView = () => {
    const isNative = Capacitor.isNativePlatform();
    return (
      <>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div className="file-upload image-upload-wrap" >
            <input
              type="file"
              name="sampleFile"
              className="file-upload-input"
              onChange={(event) => onChangeHandler(event)}
              accept="image/x-png,image/gif,image/jpeg"
              multiple
            />
            <div className="drag-text">
              <Icon className="upload-icon" name="cloud upload" size="huge" />
              <h2>Subir Archivo</h2>
              <h4 style={{ marginTop: "-10px" }}>
                Arrastre aquí los archivos PNG o JPG
              </h4>
            </div>
          </div>
          {isNative &&
            <div className="file-upload image-upload-wrap" >
              <input
                className="file-upload-input"
                onClick={takePicture}
              />
              <div className="drag-text">
                <Icon name="camera" size="huge" />
                <h2>Tomar Foto</h2>
                <h4 style={{ marginTop: "-10px" }}>
                  Solo se puede sacar una foto a la vez
                </h4>
              </div>
            </div>
          }
        </div>
      </>
    );
  };
  const takePicture = async () => {
    try {
      const photo = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });

      // Convertir la foto en un objeto File simulando un archivo
      const response = await fetch(photo.webPath); // Cargar la imagen desde la URI
      const blob = await response.blob(); // Convertir a Blob
      const file = new File([blob], `photo_${Date.now()}.jpeg`, { type: blob.type }); // Crear un objeto File
      
      // Verificar tamaño y agregar a selectedFile
      const megas = file.size / 1024 / 1024;
      if (megas <= 5.1) {
        setSelectedFile((prevFiles) => {
          const updatedFiles = prevFiles ? [...prevFiles, file] : [file];
          setCanUpload(updatedFiles.length > 0); // Actualizar estado de habilitación del botón
          return updatedFiles;
        });
      } else {
        setDataToast({
          active: true,
          message: `La foto tomada no puede ser superior a 5MB`,
          type: "warning",
        });
      }
    } catch (error) { // Maneja la cancelación de la cámara o cualquier otro error
      if (error.message === 'User cancelled photos app') {
        setDataToast({
          active: true,
          message: `Canceló la operación de la cámara`,
          type: "info",
        });
      } else {
        setDataToast({
          active: true,
          message: `Error al tomar la foto: ${error}`,
          type: "error",
        });
      }
    }
  };

  return (
    <>
      {loadingActive && (
        <Dimmer className="loader-fixed" active inverted>
          <Loader size="big">Subiendo archivos...</Loader>
        </Dimmer>
      )}
      <div>
        <Grid.Row>
          <Header as="h2" icon textAlign="center">
            <Header.Content>Importar Imágenes</Header.Content>
          </Header>
        </Grid.Row>
        {selectedFile &&
          selectedFile.map((item) => (
            <CardsUploadFile key={item.name} item={item} />
          ))}
        <PrincipalView />
        <Grid.Row style={{ textAlign: "center", marginTop: "15px", zIndex: 800 }}>
          <div>
            <Button
              style={{ marginRight: 50 }}
              onClick={() => handleClickCancel()}
            >
              Cancelar
            </Button>
            <Button primary disabled={!canUpload} onClick={() => uploadFile()}>
              Continuar
            </Button>
          </div>
        </Grid.Row>
      </div>
    </>
  );
};
