import { Grid, IconButton, Tooltip } from '@material-ui/core'
import { StateOfLiquidation } from "../../enums";
import SearchIcon from '@material-ui/icons/Search';
import DownloadImage from "@material-ui/icons/GetApp";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";

const MobileGrid = ({
  dataTable,
  profesional,
  HandleViewProfessionalBillsDetails,
  DownloadProfitRetention,
  hasManagment,
  HandleSetNameLiquidation,
  HandleEditLiquidation,
  preDelete,
  isEdit,
  isDelete,
}) => {
  return (
    <>
      <Grid className='container-configParams'>
        <div className="table-container">
          <table className="table">
            <thead className="table-header">
              <tr>
                <th className="table-cell-head c-text-left">Nombre</th>
                <th className="table-cell-head c-text-center">Fecha</th>
                <th className="table-cell-head c-text-center">Opciones</th>
              </tr>
            </thead>
            <tbody>
              {dataTable.map((item) => (
                <tr key={item.liquidacion.id} className="table-row">
                  <td className="table-cell c-text-left">{item.liquidacion.nombre}</td>
                  <td className="table-cell c-text-center">{moment(item.liquidacion.fecha).format("DD/MM/YYYY")}</td>
                  {profesional &&
                    <td className="table-cell c-text-center">
                      <IconButton
                        className='btn-circle-blue'
                        aria-label='search'
                        onClick={() => HandleViewProfessionalBillsDetails(item.liquidacion)}
                      >
                        <Tooltip placement="top" title="Ver Resumen">
                          <SearchIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        className='btn-circle-yellow'
                        onClick={() => DownloadProfitRetention(item.liquidacion)}
                      >
                        <Tooltip placement="top" title="Descargar Retención de ganancias">
                          <DownloadImage />
                        </Tooltip>
                      </IconButton>
                    </td>
                  }
                  {!profesional &&
                    <td className="table-cell c-text-center">
                      {hasManagment &&
                        <IconButton
                          style={{ marginLeft: 5 }}
                          className='btn-circle-blue'
                          aria-label='search'
                          onClick={() => HandleSetNameLiquidation(item.liquidacion)}>
                          <Tooltip placement="top" title="Ver Resumenes">
                            <SearchIcon />
                          </Tooltip>
                        </IconButton>}
                      {isEdit == true && item.liquidacion.estado !== StateOfLiquidation.generada && (
                        <IconButton
                          className="btn-circle-orange"
                          onClick={() => HandleEditLiquidation(item.liquidacion)}>
                          <Tooltip placement="top" title="Editar">
                            <CreateIcon />
                          </Tooltip>
                        </IconButton>
                      )}
                      {isDelete == true && (
                        <IconButton
                          className="btn-circle-red"
                          onClick={() => preDelete(item.liquidacion.id)}
                        >
                          <Tooltip placement="top" title="Eliminar">
                            <DeleteIcon />
                          </Tooltip>
                        </IconButton>
                      )}
                    </td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Grid>
    </>
  )
}

export default MobileGrid
