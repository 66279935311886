import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Preferences } from '@capacitor/preferences';
import {
	Button,
	Dimmer,
	Form,
	Grid,
	Header,
	Icon,
	Label,
	Loader
} from "semantic-ui-react";
import api from "../../api";
import { Options, TypeRole } from "../../enums/index";
import iconLogo from "../../img/octus.png";
import Toast from "../common/Toast";
import "./Login.css";

export default function Login() {
  const history = useHistory();
  const [dataToast, setDataToast] = useState({
    active: false,
    message: "",
    type: "",
  });
  const { t } = useTranslation();
  const [errorLogin, setErrorLogin] = useState(false);
  const [loadingActive, setLoadingActive] = useState();
	const [rememberMe, setRememberMe] = useState(false);
  const { register, setValue, handleSubmit, errors, getValues } = useForm({
    defaultValues: {
      userName: "",
      userPass: "",
    },
  });

	useEffect(() => {
    // Cargar credenciales si están almacenadas
    const loadCredentials = async () => {
      const savedEmail = await Preferences.get({ key: 'email' });
      const savedPassword = await Preferences.get({ key: 'password' });

      if (savedEmail.value && savedPassword.value) {
        setValue('userName', savedEmail.value);
        setValue('userPass', savedPassword.value);
        setRememberMe(true);
      }
    };

    loadCredentials();
  }, [setValue]);

  function SetRoles(tokenRoles) {
		let presentation = false;
		let patient = false;
		let periods = false;
		let document = false;
		let holiday = false;
		let configurations = false;
		let configModule = false;
		let configRol = false;
		let configUser = false;
		let configAccount = false;
		let cruds = false;
		let returns = false;
		let liquidation = false;
		let instructiveList = false;
		let amountHistorial = false;

		let option = [];
		let optionLiquidation = [];
		let optionPeriods = [];
		let optionDocuments = [];
		let optionCruds = [];
		let optionConfig_Module = [];
		let optionConfig_Rol = [];
		let optionConfig_User = [];
		let optionConfig_Account = [];
		localStorage.setItem('configurations', configurations);

		//para administrador y superAdministrador (a este le va el config account pero no a admin)
		if (tokenRoles === TypeRole.Admin || tokenRoles === TypeRole.SuperAdmin) {
			presentation= true;
			document = true;
			periods = true;
			patient = true;
			holiday = true;
			configurations = true;
			configModule = true;
			configRol = true;
			configUser = true;
			cruds = true;
			returns = true;
			liquidation = true;

			option.push(Options.isView);
			option.push(Options.isExport);
			option.push(Options.isDelete);

			optionPeriods.push(Options.isView);
			optionPeriods.push(Options.isExport);
			optionPeriods.push(Options.isEdit);

			optionLiquidation.push(Options.isView);
			optionLiquidation.push(Options.isCreate);
			optionLiquidation.push(Options.isEdit);
			optionLiquidation.push(Options.isDelete);

			optionDocuments.push(Options.isView);
			optionDocuments.push(Options.isCreate);
			optionDocuments.push(Options.isEdit);
			optionDocuments.push(Options.isDelete);
			optionDocuments.push(Options.isExport);

			optionConfig_Module.push(Options.isView);
			optionConfig_Module.push(Options.isCreate);
			optionConfig_Module.push(Options.isDelete);
			optionConfig_Module.push(Options.isEdit);

			optionConfig_Rol.push(Options.isView);
			optionConfig_Rol.push(Options.isCreate);
			optionConfig_Rol.push(Options.isDelete);
			optionConfig_Rol.push(Options.isEdit);

			optionConfig_User.push(Options.isView);
			optionConfig_User.push(Options.isCreate);
			optionConfig_User.push(Options.isDelete);
			optionConfig_User.push(Options.isEdit);

			optionCruds.push(Options.isView);
			optionCruds.push(Options.isCreate);
			optionCruds.push(Options.isDelete);
			optionCruds.push(Options.isEdit);

			if (tokenRoles === TypeRole.SuperAdmin) {
				presentation = false;
				liquidation = false;
				holiday = false;
				returns = false;
				configAccount = true;
				patient = false;
				optionConfig_Account.push(Options.isView);
				optionConfig_Account.push(Options.isCreate);
				optionConfig_Account.push(Options.isEdit);
				optionConfig_Account.push(Options.isDelete);
			}
			localStorage.setItem('configurations', configurations);
			localStorage.setItem('cruds', JSON.stringify(cruds));
		}
		//periodos y pacientes (profesionales)
		if (tokenRoles === TypeRole.Professional) {
			presentation = true;
			liquidation = true;
			instructiveList = true;
			amountHistorial = true;

			optionLiquidation.push(Options.isView);
			optionLiquidation.push(Options.isExport);

			//pacientes
			patient = true;
			returns = true;
			option.push(Options.isView);
			option.push(Options.isCreate);
			option.push(Options.isEdit);
			option.push(Options.isDelete);
			option.push(Options.isExport);

			//periodos
			periods = true;
			optionPeriods.push(Options.isView);
			optionPeriods.push(Options.isCreate);
			optionPeriods.push(Options.isEdit);
			optionPeriods.push(Options.isDelete);
			optionPeriods.push(Options.isExport);
		}

		//documentos para colegio
		if (tokenRoles === TypeRole.Colegio || tokenRoles === TypeRole.ObraSocial) {
			presentation = false;
			liquidation = false;
			document = true;
			returns = false;
			optionDocuments.push(Options.isView);
			optionDocuments.push(Options.isExport);
		}
		
		if (option.length > 0) {
			localStorage.setItem('optionsPatient', JSON.stringify(option));
		} 
		if (optionPeriods.length > 0) {
			localStorage.setItem('optionsPeriods', JSON.stringify(optionPeriods));
		}
		if (optionDocuments.length > 0) {
			localStorage.setItem('optionDocuments', JSON.stringify(optionDocuments));
		}
		if (optionCruds.length > 0) {
			localStorage.setItem('optionCruds', JSON.stringify(optionCruds));
		}
		if(optionLiquidation.length > 0){
			localStorage.setItem('optionLiquidation', JSON.stringify(optionLiquidation));
		}

		localStorage.setItem('presentation', JSON.stringify(presentation));
		localStorage.setItem('patient', JSON.stringify(patient));
		localStorage.setItem('periods', JSON.stringify(periods));
		localStorage.setItem('document', JSON.stringify(document));
		localStorage.setItem('holiday', JSON.stringify(holiday));
		localStorage.setItem('returns', JSON.stringify(returns));
		localStorage.setItem('liquidation', JSON.stringify(liquidation));
		localStorage.setItem('instructive', JSON.stringify(instructiveList));
		localStorage.setItem('amountHistorial', JSON.stringify(amountHistorial));
		
		if (optionConfig_Module.length > 0) {
			localStorage.setItem(
				'optionConfig_Module',
				JSON.stringify(optionConfig_Module)
			);
		}
		if (optionConfig_Rol.length > 0) {
			localStorage.setItem(
				'optionConfig_Rol',
				JSON.stringify(optionConfig_Rol)
			);
		}
		if (optionConfig_User.length > 0) {
			localStorage.setItem(
				'optionConfig_User',
				JSON.stringify(optionConfig_User)
			);
		}
		if (optionConfig_Account.length > 0) {
			localStorage.setItem(
				'optionConfig_Account',
				JSON.stringify(optionConfig_Account)
			);
		}

		localStorage.setItem('configModule', JSON.stringify(configModule));
		localStorage.setItem('configRol', JSON.stringify(configRol));
		localStorage.setItem('configUser', JSON.stringify(configUser));
		localStorage.setItem('configAccount', JSON.stringify(configAccount));
	}

	
	function actionButtonSubmit() {
		let values = getValues();
		let body = {
			username: values.userName,
			password: values.userPass,
		};

		//logica de RememberMe
		handleRememberMe(values);

		setLoadingActive(true);
		api
			.loginAuth(body)
			.then((resp) => {
				setLoadingActive(false);
				let token = resp.data.token;
				api.setToken(token);
				api.getJwtData()
				.then(({data})=>{
					let tokenExp = data.tokenExp;
					let TypeRolUser = data.rol;
					let idSocialWork = data.obraSocialId;
					let colegioId = data.asociacionId;
					let professionalId = Number(data.userId);
					localStorage.setItem('colegioId', colegioId);
					localStorage.setItem('tokenExp', tokenExp);
					localStorage.setItem('tokenLog', token);
					localStorage.setItem('professionalId', professionalId);
	
					localStorage.setItem('TypeRole', JSON.stringify(TypeRolUser));
					localStorage.setItem('SocialWorkId', JSON.stringify(idSocialWork));
	
					SetRoles(TypeRolUser);
					let presentation = JSON.parse(localStorage.getItem('presentation'));
					let patient = JSON.parse(localStorage.getItem('patient'));
					let returns = JSON.parse(localStorage.getItem('returns'));
					let periods = JSON.parse(localStorage.getItem('periods'));
					let document = JSON.parse(localStorage.getItem('document'));
					let holiday = JSON.parse(localStorage.getItem('holiday'));
					let configuration = JSON.parse(localStorage.getItem('configurations'));
					let configModule = JSON.parse(localStorage.getItem('configModule'));
					let configRol = JSON.parse(localStorage.getItem('configRol'));
					let configUser = JSON.parse(localStorage.getItem('configUser'));
					let configAccount = JSON.parse(localStorage.getItem('configAccount'));
					let liquidation = JSON.parse(localStorage.getItem('liquidation'));
	
					if (TypeRolUser == TypeRole.SuperAdmin) {
						history.push('/periodos/lista');
					}else if(presentation){
						history.push('/Inicio');
					}else if (patient == true) {
						history.push('/pacientes');
					} else if (periods == true) {
						history.push('/periodos/lista');
					}else if(returns == true){
						history.push('/devoluciones/lista');
					} else if (document == true) {
						history.push('/documentos');
					} else if (holiday == true) {
						history.push('/feriados');
					}else if(liquidation == true){
						history.push('/liquidaciones/lista');
					} else if (configuration == true) {
						if (configModule == true) {
							history.push('/configuraciones/modulo');
						} else if (configRol == true) {
							history.push('/configuraciones/rol');
						} else if (configUser == true) {
							history.push('/configuraciones/user');
						} else if (configAccount == true) {
							history.push('/configuraciones/cuenta');
						}
					}
				})
			})
			.catch((err) => {
				setLoadingActive(false);
				setDataToast({
					active: true,
					message: err.response
						? err.response.data.errores
						: 'Acaba de ocurrir un error',
					type: 'error',
				});
			});
	}

	const handleRememberMe = async (data) => {
		const { userName, userPass } = data;
		if (rememberMe) {
      // Guardar credenciales
      await Preferences.set({ key: 'email', value: userName });
      await Preferences.set({ key: 'password', value: userPass });
    } else {
      // Limpiar credenciales si no se selecciona "Recordar cuenta"
      await Preferences.remove({ key: 'email' });
      await Preferences.remove({ key: 'password' });
    }
	}

	return (
		<>
			{dataToast.active && (
				<Toast dataToast={dataToast} setDataToast={setDataToast} />
			)}

			<Grid
				textAlign='center'
				style={{ height: '100vh' }}
				verticalAlign='middle'
			>
				{loadingActive ? (
					<Dimmer className='loader-fixed' active inverted>
						<Loader size='big'>Ingresando..</Loader>
					</Dimmer>
				) : (
					<>
						<Grid.Column style={{ maxWidth: 450 }}>
							<Card className='color-border-login'>
								<CardContent>
									<Header
										as='h2'
										style={{ color: '#f05c5c' }}
										textAlign='center'
									>
										<img style={{ width: '100%' }} src={iconLogo} alt='logo' />
									</Header>
									<Form
										style={{ textAlign: '-webkit-center' }}
										size='large'
										onSubmit={handleSubmit(actionButtonSubmit)}
									>
										{errorLogin && !errors.userPass && !errors.userName && (
											<Grid.Row centered>
												<b className='color-letter-error'>
													{t('error-email-pass')}
												</b>
											</Grid.Row>
										)}
										{errors.userName && (
											<Grid.Row>
												<b className='color-letter-error'>
													{t('error-email-login')}
												</b>
											</Grid.Row>
										)}
										<Form.Input
											style={{ width: window.innerWidth <= 680 ? '74%' : '72%' }}
											fluid
											labelPosition='left'
											type='text'
											placeholder={t('email-address')}
										>
											<Label basic style={{width: "47px"}}>
												<Icon disabled name='user' style={{margin: 0}}/>
											</Label>
											<input
												id='userName'
												name='userName'
												ref={register({ required: true })}
											/>
											{errors.singleErrorInput && 'Your input is required'}
										</Form.Input>

										{errors.userPass && (
											<Grid.Row>
												<b className='color-letter-error'>
													{t('error-password-login')}
												</b>
											</Grid.Row>
										)}
										<Form.Input
											style={{ width: window.innerWidth <= 680 ? '74%' : '72%' }}
											fluid
											labelPosition='left'
											type='password'
											placeholder={t('password')}
										>
											<Label basic style={{width: "47px"}}>
												<Icon disabled name='lock' style={{margin: 0}}/>
											</Label>
											<input
												id='userPass'
												name='userPass'
												ref={register({ required: true })}
											/>
										</Form.Input>
										<div style={{display: "flex", justifyContent:"center"}}>
											<FormControlLabel
												control={
													<Checkbox
														checked={rememberMe}
														onChange={(e) => setRememberMe(e.target.checked)}
													/>
												}
												label="Recordarme"
											/>

											<Button
												style={{ width: window.innerWidth <= 380 ? 120 : 150, color: 'white', margin: 0}}
												type='submit'
												className='btn-octus'
											>
												<Icon name='sign-in' />
												Ingresar
											</Button>
										</div>
									</Form>
								</CardContent>
							</Card>
							{dataToast.active && (
								<Toast dataToast={dataToast} setDataToast={setDataToast} />
							)}
						</Grid.Column>
					</>
				)}
			</Grid>
		</>
	);
}
