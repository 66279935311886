import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "semantic-ui-react";
import "./InputForm.css";
import { Controller } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import GridMaterial from "@material-ui/core/Grid";

export default function InputForm({
  label,
  name,
  control,
  error,
  type,
  isRequired,
  register,
  onChange,
  onBlur,
  loading,
  value,
  disabled,
  children,
  placeholder,
  isMobile = false,
}) {
  if (control) {
    return (
      <Grid.Row style={{ padding: isMobile ? 0 : 2, marginTop: isMobile ? 10 : 0}}>
        <FormControl variant="outlined" size="small">
          <GridMaterial container spacing={1} alignItems="center">
            <GridMaterial item style={{ width: 140 }}>
              <span>{label}</span>
            </GridMaterial>
            <GridMaterial item style={{ position: "relative", width: name === "autorNum" ? "47%" : "58%" }} className={isMobile ? "" : "filter-input"}>
              <Controller
                  className={`width-input-form
                    ${isMobile ? "" : " filter-input"}
                    ${error[name] ? " border-error-input " : ""}
                    ${disabled ? ' disabled-input' : ''}`
                  }
                  error={error[name]}
                  as={TextField}
                  required={isRequired}
                  type={type}
                  inputProps={{
                    autoComplete: "new_" + name + "_document",
                  }}
                  name={name}
                  id={name}
                  control={control}
                  onBlur={onBlur}
                  variant="outlined"
                  size="small"
                  disabled={disabled}
                  placeholder={placeholder}
                />
              {children}
            </GridMaterial>
            {loading && <CircularProgress className="instructiveIcon" />}
          </GridMaterial>
        </FormControl>
      </Grid.Row>
    );
  } else {
    return (
      <Grid.Row style={{ padding: isMobile ? 0 : 2, marginTop: isMobile ? 10 : 0}}>
        <FormControl variant="outlined" size="small">
          <GridMaterial container spacing={1} alignItems="center">
            <GridMaterial item style={{ width: 140 }}>
              <span>{label}</span>
            </GridMaterial>
            <GridMaterial item style={{ position: "relative" }} className={isMobile ? "" : "filter-input"}>
              <TextField
                className={`width-input-form
                  ${isMobile ? "" : " filter-input"}
                  ${error[name] ? " border-error-input " : ""}
                  ${disabled ? ' disabled-input' : ''}`
                }
                error={error[name]}
                required={isRequired}
                type={type}
                name={name}
                variant="outlined"
                size="small"
                onChange={onChange}
                value={value}
                disabled={disabled}
                onBlur={onBlur}
                placeholder={placeholder}
              />
              {children}
            </GridMaterial>
            {loading && <CircularProgress className="loader-input" />}
          </GridMaterial>
        </FormControl>
      </Grid.Row>
    );
  }
}
