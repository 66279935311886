import DateFnsUtils from "@date-io/date-fns";
import { TextareaAutosize, Tooltip } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from '@material-ui/core/TextField';
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from '@material-ui/icons/Search';
import Pagination from "@material-ui/lab/Pagination";
import DownloadImage from "@material-ui/icons/GetApp";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { es } from "date-fns/locale";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Dimmer,
  Grid,
  Icon,
  Loader,
  Table,
} from "semantic-ui-react";
import api from "../../api";
import { Options, StateOfLiquidation, StatusCode, TypeRole } from "../../enums";
import { setDataLiquidation, setRestoreStateLiquidation } from "../../redux/generateLiquidation";
import { ExportReportProfitRetention, GetLiquidationDetailsForProfessional } from "../../services/services/Liquidation";
import ViewProfesionalBillDetails from "../managment/modals/Liquidation/ViewProfesionalBillDetails";
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { encode } from 'base64-arraybuffer';
import { Share } from '@capacitor/share';
import MobileGrid from "./MobileGrid";

export default function Liquidation({ setDataToast }) {
  const professionalId = localStorage.getItem('professionalId');
  const hasManagment = useSelector(({ managment }) => managment.hasManagment);
  const disparador = useDispatch();
  const [loadingActive, setLoadingActive] = useState();
  const loadForPage = 10;
  const history = useHistory();
  const [profesional, setProfesional] = useState(false);
  const [viewDate, setViewDate] = useState();
  const [dataTable, setDataTable] = useState();
  const [error, setError] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openNew, setOpenNew] = React.useState(false);
  const [quantityData, setQuantityData] = useState(1);
  const [pageTable, setPageTable] = useState(1);

  //#region estados liquidaciones
  const [isCreate, setCreate] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setDelete] = useState(false);

  const [newValue, setNewValue] = useState({
    name: null,
    date: null,
    description: null
  });
  const [filter, setFilter] = useState({
    name: null,
    date: null
  });
  const [editLiquidation, setLiquidation] = useState({
    name: null,
    date: null,
    description: null,
    id: null
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    id: null,
  });
  const [descriptionSettlement, setDescriptionSettlement] = useState({
    modalDescription: false,
    description: null
  });
  const [showModalProfessionalBillDetails, setShowModalProfessionalBillDetails] = React.useState(false);
  const [dataToModalRender, setDataToModalRender] = React.useState();
  //#endregion
  const isNative = Capacitor.isNativePlatform();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 680);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 680);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let typeRole = JSON.parse(localStorage.getItem("TypeRole"));
    if (typeRole == TypeRole.Professional) {
      setProfesional(true)
    }
    let options = JSON.parse(localStorage.getItem("optionLiquidation"));
    options.forEach((element) => {
      if (element == Options.isCreate) {
        setCreate(true);
      } else if (element == Options.isEdit) {
        setEdit(true);
      } else if (element == Options.isDelete) {
        setDelete(true);
      }
    });
    GetSettlements();
  }, []);

  function shortError(message) {
    setDataToast({
      active: true,
      message: message,
      type: "error",
    });
  }

  //#region  METHOD (GET)
  function GetSettlements(nroPage = 1) {
    setLoadingActive(true);
    let filtrado = {
      nombre: filter.name,
      fecha: filter.date,
      pagina: nroPage,
      cantidad: loadForPage
    }
    api.getSettlements(filtrado)
      .then((res) => {
        let data = res.data.resultados
        let list = [];
        data.forEach((item) => {
          if (item.id !== 0) {
            list.push({
              liquidacion: {
                id: item.id,
                nombre: item.nombre,
                fecha: item.fecha,
                descripcion: item.descripcion,
                estado: item.estado
              }
            });
          }
        });
        setDataTable(null);
        setDataTable(list);
        setQuantityData(res.data.cantidadPaginas);
      })
      .catch((err) => {
        setDataToast({
          active: true,
          type: "error",
          message: "Ocurrió un error.",
        });
      })
      .finally(() => {
        setLoadingActive(false);
      })
  }
  //#endregion

  //#region METHOD (POST NEW)
  const onClickCreate = () => {
    if (newValue.name && newValue.date) {
      let body = {
        nombre: newValue.name,
        fecha: newValue.date,
        descripcion: newValue.description
      };
      setLoadingActive(true);
      api
        .postLiquidation(body)
        .then(() => {
          setDataToast({
            active: true,
            message: "Liquidación creada correctamente.",
            type: "success",
          });
          GetSettlements(1);
        })
        .catch((err) => {
          setDataToast({
            active: true,
            message: err.response.data.errores ?? "Ocurrió un error.",
            type: "error",
          });
        })
        .finally(() => setLoadingActive(false));
      setError(false);
      handleCloseNew();
    } else {
      setDataToast({
        active: true,
        message: "Los campos son obligatorios.",
        type: "error",
      })
      setError(true);
    }
    setNewValue({
      name: null,
      date: null,
      description: null
    })
  };
  const handleCloseNew = () => {
    setOpenNew(false);
    setError(false);
  };
  const handleClickOpen = () => {
    setOpenNew(true);
  };
  //#endregion

  //#region METHOD (POST EDIT)
  function Edit() {
    setLoadingActive(true);
    if (editLiquidation.name && editLiquidation.date) {
      let body = {
        nombre: editLiquidation.name,
        fecha: editLiquidation.date,
        descripcion: editLiquidation.description
      };

      api
        .putSettlement(editLiquidation.id, body)
        .then((res) => {
          setDataToast({
            active: true,
            message: "Liquidación editada correctamente.",
            type: "success",
          });
          GetSettlements(1);
          setLoadingActive(false);
        })

        .catch((err) => {
          setDataToast({
            active: true,
            message: err.response.data.errores ?? "Ocurrió un error.",
            type: "error",
          });
          setLoadingActive(false);
        });

      setError(false);
      handleCloseEdit();
    } else {
      setError(true)
      setLoadingActive(false);
      setDataToast({
        active: true,
        message: "Los campos son obligatorios.",
        type: "error",
      });
    }
    setLiquidation({
      name: null,
      date: null,
      description: null
    })
  }
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  //#endregion

  //#region METHOD (DELETE)
  const handleCloseDelete = () => {
    setDeleteDialog({
      ...deleteDialog, open: false
    });
  };

  function preDelete(id) {
    setDeleteDialog({ open: true, id: id });
  }

  function closePreDelete() {
    setDeleteDialog({ ...deleteDialog, open: false });
  }
  function HandleSetNameLiquidation(liquidation) {
    disparador(setDataLiquidation({
      nombre: liquidation.nombre,
      fecha: liquidation.fecha,
      estado: liquidation.estado
    }));
    history.push(`/liquidaciones/${liquidation.id}/resumenes`);
  }
  function deleteLiquidation(id) {
    closePreDelete();
    setLoadingActive(true);

    api
      .deleteSettlement(id)
      .then((res) => {
        let newDataTable = [];
        dataTable.forEach((settlement) => {
          if (settlement.id !== id) {
            newDataTable.push(settlement);
          }
        });

        setDataTable(null);
        setDataTable(newDataTable);
        setDataToast({
          active: true,
          message: "Liquidación eliminada correctamente.",
          type: "success",
        });
        setLoadingActive(false);
        GetSettlements(1);
        disparador(setRestoreStateLiquidation());
      })
      .catch((err) => {
        if (err.response.data.errores) {
          setDataToast({
            active: true,
            message: err.response.data.errores,
            type: "error",
          });
        } else {
          shortError("Ocurrió un error");
        }
        setLoadingActive(false);
      });
  }
  //#endregion

  function HandleNewLiquidation() {
    if (hasManagment) {
      history.push("/liquidaciones");
    } else {
      handleClickOpen();
    }
  }
  function HandleEditLiquidation(liquidacion) {
    if (hasManagment) {
      history.push(`/liquidaciones/${liquidacion.id}`, { fromEdit: true });
    } else {
      setLiquidation({
        id: liquidacion.id,
        name: liquidacion.nombre,
        date: liquidacion.fecha,
        description: liquidacion.descripcion
      })
      setOpenEdit(true);
      setViewDate(true);
      setLoadingActive(false);
    }
  }
  //#region Pagination
  const handleChange = (event, value) => {
    setPageTable(value);
    GetSettlements(value);
  };
  //#endregion

  //#region Ver Resumen y Descargar Retención de ganancias
  async function HandleViewProfessionalBillsDetails(item) {
    try {
      setLoadingActive(true);
      const { data, status } = await GetLiquidationDetailsForProfessional(item.id, professionalId);
      if (status === StatusCode.Ok) {
        toggleModal();
        setDataToModalRender(data.results);
      }
    } catch (err) {
      const errorMessage = err.message || 'No se pudo obtener los detalles de las facturas del profesional.';
      MessageNotification(errorMessage, "error");
    } finally {
      setLoadingActive(false);
    }
  }
  const toggleModal = () => {
    setShowModalProfessionalBillDetails(!showModalProfessionalBillDetails);
  };

  async function DownloadProfitRetention(liquidacion) {
    try {
      setLoadingActive(true);
      const { data, status } = await ExportReportProfitRetention(liquidacion.id, professionalId);
      if (status === StatusCode.Ok) {
        let fileName = `Retención_de_Ganancias.pdf`;
			  const blob = new Blob([data.results], { type: 'application/pdf' });

        if (isNative) {
          const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
          fileName = `Retención_de_Ganancias_${timestamp}.pdf`;
  
          //Descargo el PDF
          const arrayBuffer = await blob.arrayBuffer();
          const base64Data = encode(arrayBuffer);
          const savedFile = await Filesystem.writeFile({
            path: fileName,
            data: base64Data,
            directory: Directory.Documents,
          });
  
          setLoadingActive(false);
          MessageNotification('PDF descargado en Documents', 'success');
          
          //Abro el archivo con un visor de PDF nativo.
          const fileUri = savedFile.uri;
          await Share.share({
            title: 'Abrir PDF',
            text: 'Abrir el archivo PDF descargado.',
            url: fileUri,
            dialogTitle: 'Abrir con...',
          });
        } else {
          let a = document.createElement("a");
          document.body.appendChild(a);
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          MessageNotification('PDF descargado correctamente', 'success');
        }
      }
    } catch (err) {
      const errorMessage = err.message || 'No se pudo obtener el PDF de la Retención';
      MessageNotification(errorMessage, "error");
    } finally {
      setLoadingActive(false);
    }
  }
  //#endregion

  //#region UTILS
  function UtilityFNExport(extension, nameOfFile, responseApi) {
    let fileName = `${nameOfFile}.${extension}`;
    let blob = new Blob([responseApi], {
      type: "application/pdf",
    });
    let a = document.createElement("a");
    document.body.appendChild(a);
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  function MessageNotification(message, type) {
    setDataToast({
      active: true,
      message: message || "Acaba de ocurrir un error",
      type
    });
  }
  //#endregion

  return (
    <>
      <Grid>
        <Grid.Row>
          <Card className="color-border-dash">
            <CardContent className="center-responsive-filters">
              <Grid.Row className="header-section">
                <div className="titleAndVideo">
                  {/* {profesional &&
                    <Tooltip title='Liquidaciones'>
                      <IconButton
                        style={{ marginLeft: 10 }}
                        size='medium'
                        href='https://www.youtube.com/watch?v=FATUPvm6pS4&ab_channel=OctusSoftware'
                        target='_blank'
                      >
                        <Icon name='youtube' color='red' style={{ width: 20 }} />
                      </IconButton>
                    </Tooltip>} */}
                  <span style={{ fontSize: 27 }}>Liquidaciones</span>
                </div>
                {!profesional && isCreate == true && (
                  <Button
                    className="btn-new-filter"
                    onClick={HandleNewLiquidation}
                    icon
                    labelPosition="left"
                  >
                    <Icon name="plus" />
                    Nuevo
                  </Button>
                )}
              </Grid.Row>
              {!profesional &&
                <Grid.Row className="content-filter-list">
                  <TextField
                    className="filter-input"
                    size='small'
                    id='nombre'
                    label='Nombre'
                    value={filter.name}
                    onChange={(e) => {
                      setFilter({ ...filter, name: e.target.value });
                    }}
                    variant='outlined'
                  />
                  <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      className="size-date-picke-filter filter-input"
                      clearable
                      value={filter.date}
                      placeholder="Fecha"
                      onChange={(date) =>
                        setFilter({ ...filter, date: date })
                      }
                      format="dd/MM/yyyy"
                      margin="normal"
                      inputVariant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                </Grid.Row>}
              {!profesional &&
                <Button
                  className="btn-search-filter"
                  primary
                  icon
                  labelPosition="left"
                  onClick={() => GetSettlements()}
                  type="submit"
                >
                  <Icon name="search" />
                  Buscar
                </Button>}
            </CardContent>
          </Card>
        </Grid.Row>
        {loadingActive &&
          <Dimmer className="loader-fixed" active inverted>
            <Loader size="big">Cargando..</Loader>
          </Dimmer>
        }
        { dataTable && dataTable.length > 0 && !isMobile && (
            <Grid.Row>
              <TableContainer component={Paper} id="color-letter-table-tasks">
                <Table aria-label="spanning table">
                  <TableHead colSpan={4} className="color-letter-inTable">
                    <TableRow>
                      <TableCell className="letter-title-table c-text-center">
                        <b>Nombre</b>
                      </TableCell>
                      <TableCell className="letter-title-table c-text-center">
                        <b>Fecha</b>
                      </TableCell>
                      <TableCell className="letter-title-table c-text-center">
                        <b>Descripcion</b>
                      </TableCell>
                      {hasManagment &&
                        <TableCell className="letter-title-table c-text-center">
                          <b>Estado</b>
                        </TableCell>}
                      <TableCell className="letter-title-table c-text-center">
                        <b>Opciones</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataTable.map((item) => (
                      <TableRow key={item.liquidacion.id}>
                        <TableCell className="c-text-center">
                          {item.liquidacion.nombre}
                        </TableCell>
                        <TableCell className="c-text-center">
                          {moment(item.liquidacion.fecha).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell className="c-text-center">
                          {item.liquidacion.descripcion !== null ?
                            <div>{item.liquidacion.descripcion.split('').length > 100 ?
                              <div>
                                {item.liquidacion.descripcion.slice(0, 20) + '...'}
                                <IconButton
                                  style={{ marginLeft: 5 }}
                                  className='btn-circle-blue'
                                  aria-label='search'
                                  onClick={() =>
                                    setDescriptionSettlement({
                                      modalDescription: true,
                                      description: item.liquidacion.descripcion
                                    })
                                  }
                                >
                                  <Tooltip placement="top" title="Ver Descripción">
                                    <SearchIcon />
                                  </Tooltip>
                                </IconButton>
                              </div> : item.liquidacion.descripcion}</div>
                            : "No tiene..."}
                        </TableCell>
                        {hasManagment &&
                          <TableCell className="c-text-center">
                            {item.liquidacion.estado === StateOfLiquidation.generada ? "Generada" : "Borrador"}
                          </TableCell>}
                        {!profesional &&
                          <TableCell className="c-text-center">
                            {hasManagment &&
                              <IconButton
                                style={{ marginLeft: 5 }}
                                className='btn-circle-blue'
                                aria-label='search'
                                onClick={() => HandleSetNameLiquidation(item.liquidacion)}>
                                <Tooltip placement="top" title="Ver Resumenes">
                                  <SearchIcon />
                                </Tooltip>
                              </IconButton>}
                            {!profesional && isEdit == true && item.liquidacion.estado !== StateOfLiquidation.generada && (
                              <IconButton
                                className="btn-circle-orange"
                                onClick={() => HandleEditLiquidation(item.liquidacion)}>
                                <Tooltip placement="top" title="Editar">
                                  <CreateIcon />
                                </Tooltip>
                              </IconButton>
                            )}
                            {!profesional && isDelete == true && (
                              <IconButton
                                className="btn-circle-red"
                                onClick={() => preDelete(item.liquidacion.id)}
                              >
                                <Tooltip placement="top" title="Eliminar">
                                  <DeleteIcon />
                                </Tooltip>
                              </IconButton>
                            )}
                          </TableCell>}
                        {profesional &&
                          <TableCell className="c-text-center">
                            <IconButton
                                className='btn-circle-blue'
                                aria-label='search'
                                onClick={() => HandleViewProfessionalBillsDetails(item.liquidacion)}
                              >
                                <Tooltip placement="top" title="Ver Resumen">
                                  <SearchIcon />
                                </Tooltip>
                              </IconButton>
                              <IconButton
                                className='btn-circle-yellow'
                                onClick={() => DownloadProfitRetention(item.liquidacion)}
                              >
                                <Tooltip placement="top" title="Descargar Retención de ganancias">
                                  <DownloadImage />
                                </Tooltip>
                              </IconButton>
                          </TableCell>
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid.Row>
          )
        }
        { dataTable && dataTable.length > 0 && isMobile && (
          <MobileGrid
            dataTable={dataTable}
            profesional={profesional}
            HandleViewProfessionalBillsDetails={HandleViewProfessionalBillsDetails}
            DownloadProfitRetention={DownloadProfitRetention}
            hasManagment={hasManagment}
            HandleSetNameLiquidation={HandleSetNameLiquidation}
            HandleEditLiquidation={HandleEditLiquidation}
            preDelete={preDelete}
            isEdit={isEdit}
            isDelete={isDelete}
          />
        )}
        {dataTable && dataTable.length === 0 && (
          <Grid.Row style={{ textAlign: "-webkit-center" }}>
            <TableContainer component={Paper} id="color-letter-table-tasks">
              <TableCell>
                <h5>No se encontraron resultados</h5>
              </TableCell>
            </TableContainer>
          </Grid.Row>
        )}
        {dataTable && (
          <Grid.Row centered>
            <Pagination
              className="pagination"
              count={quantityData}
              page={pageTable}
              onChange={handleChange}
            />
          </Grid.Row>
        )}
      </Grid>

      <Dialog
        open={openNew}
        onClose={handleCloseNew}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nuevo</DialogTitle>
        <DialogContent>

          <Grid.Row style={{ display: "flex" }}>
            <TextField
              size='small'
              id='nombre'
              label='Nombre'
              value={newValue.name}
              error={error}
              onChange={(e) => {
                setNewValue({ ...newValue, name: e.target.value });
              }}
              variant='outlined'
            />
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                className="size-date-picke-filter"
                clearable
                style={{ margin: "0 5px" }}
                placeholder="Fecha"
                onChange={(dateSelected) =>
                  setNewValue({ ...newValue, date: dateSelected })
                }
                format="dd/MM/yyyy"
                margin="normal"
                inputVariant="outlined"
                value={newValue.date}
                error={error}
              />
            </MuiPickersUtilsProvider>
          </Grid.Row>

          <Grid.Row>
            <TextareaAutosize
              className="commentReturn"
              style={{ width: "100%", marginTop: 10 }}
              aria-label="minimum height"
              minRows={6}
              placeholder="Agregue una descripción..."
              value={newValue.description}
              onChange={(e) => setNewValue({
                ...newValue,
                description: e.target.value
              })}
            />
          </Grid.Row>

        </DialogContent>

        <DialogActions
          style={{
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Button onClick={handleCloseNew} color='rgba(0,0,0,.6)' style={{ backgroundColor: "#e0e1e2" }}>
            Cancelar
          </Button>
          <Button onClick={onClickCreate} color="green">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>


      {viewDate && (
        <Dialog
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Editar</DialogTitle>
          <DialogContent>
            <Grid.Row style={{ display: "flex" }}>
              <TextField
                size='small'
                id='nombre'
                label='Nombre'
                value={editLiquidation.name}
                onChange={(e) => {
                  setLiquidation({ ...editLiquidation, name: e.target.value });
                }}
                variant='outlined'
                error={error}
              />
              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  className="size-date-picke-filter"
                  clearable
                  style={{ margin: "0 5px" }}
                  placeholder="Fecha"
                  onChange={(date) =>
                    setLiquidation({ ...editLiquidation, date: date })
                  }
                  format="dd/MM/yyyy"
                  margin="normal"
                  inputVariant="outlined"
                  value={editLiquidation.date}
                  error={error}
                />
              </MuiPickersUtilsProvider>
            </Grid.Row>
            <Grid.Row>
              <TextareaAutosize
                className="commentReturn"
                style={{ width: "100%", marginTop: 10 }}
                aria-label="minimum height"
                minRows={6}
                placeholder="Agregue una descripción..."
                value={editLiquidation.description}
                onChange={(e) => setLiquidation({
                  ...editLiquidation,
                  description: e.target.value
                })}
              />
            </Grid.Row>
          </DialogContent>

          <DialogActions
            style={{
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Button onClick={() => handleCloseEdit()} color='rgba(0,0,0,.6)' style={{ backgroundColor: "#e0e1e2" }}>
              Cancelar
            </Button>

            <Button onClick={Edit} color="green">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {deleteDialog != undefined && (
        <Dialog open={deleteDialog.open} onClose={handleCloseDelete}>
          <DialogTitle id="form-dialog-title">Borrar Registro</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Está seguro de eliminar esta Liquidación?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closePreDelete()}>Cancelar</Button>
            <Button onClick={() => deleteLiquidation(deleteDialog.id)} color="red">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog
        open={descriptionSettlement.modalDescription}
        onClose={() => setDescriptionSettlement({ ...descriptionSettlement, modalDescription: false })}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title' style={{ textAlign: "center" }}>Comentario de la Liquidación</DialogTitle>
        <DialogContent>
          <TextareaAutosize
            readOnly
            className="commentReturn"
            style={{ width: 350 }}
            aria-label="minimum height"
            minRows={6}
            value={descriptionSettlement.description}
          />
        </DialogContent>

        <DialogActions
          style={{
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Button onClick={() => setDescriptionSettlement({ ...descriptionSettlement, modalDescription: false })}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {showModalProfessionalBillDetails &&
        <ViewProfesionalBillDetails
          openModal={showModalProfessionalBillDetails}
          HandleClose={toggleModal}
          dataToRender={dataToModalRender}
        />
      }
    </>
  );
}
